import { DownloadForOffline, History } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import { formatDate } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { DataTableColumn, PaginatedProps, StandardListViewV2, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { InvoiceHistoryDto } from '../../dtos';
import { useCreateOrderReviewPdfMutation, useGetInvoiceQuery } from '../../store/generated/generatedApi';

export interface IInvoiceHistoryDialogProps {
    open: boolean;
    onClose: () => void;
    orderReviewId?: string;
}

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'INVOICE_DATE',
    sortAsc: false,
    page: 0,
    pageSize: 25,
};

export const InvoiceHistoryDialog: FC<IInvoiceHistoryDialogProps> = ({ open, onClose, orderReviewId }) => {
    const [getQuotePdf, { isError: isErrorPdf, error: pdfGenerationError, isLoading: pdfLoading, reset: resetPdf }] = useCreateOrderReviewPdfMutation();
    useFailedActionSnackbar('download', 'PDF', isErrorPdf, resetPdf, pdfGenerationError);
    const generatePdf = useCallback(
        (id?: string) => {
            if (!pdfLoading && id) {
                getQuotePdf({ id: id }).then((response: any) => {
                    if (response?.data) {
                        window.open(response?.data.url, '_blank');
                    }
                });
            }
        },
        [getQuotePdf, pdfLoading]
    );

    const tableColumns: DataTableColumn<InvoiceHistoryDto>[] = useMemo(() => {
        const fields: DataTableColumn<InvoiceHistoryDto>[] = [];
        if (!orderReviewId) {
            fields.push(
                { key: 'customerName', label: 'Customer', sortKey: 'CUSTOMER_NAME' },
                { key: 'orderNumber', label: 'Order #', sortKey: 'ORDER_NUMBER' },
                { key: 'orderDate', label: 'Order Date', sortKey: 'ORDER_DATE', fieldMapper: (value) => formatDate(value.orderDate, 'MM/dd/yyyy') }
            );
        }

        fields.push(
            { key: 'invoiceNumber', label: 'Invoice #', sortKey: 'INVOICE_NUMBER' },
            { key: 'invoiceDate', label: 'Invoice Date', sortKey: 'INVOICE_DATE', fieldMapper: (value) => formatDate(value.invoiceDate, 'MM/dd/yyyy') },
            { key: 'download', label: '', sortKey: '', unsorted: true, fieldMapper: (value) => <Tooltip title="Download Invoice PDF"><IconButton size='small' disabled={pdfLoading} onClick={() => generatePdf(value.orderReviewId)}><DownloadForOffline /></IconButton></Tooltip> },
        )

        return fields;
    }, [orderReviewId, generatePdf, pdfLoading]);

    return (
        <Dialog onClose={onClose} open={open} maxWidth='lg' fullWidth>
            <DialogTitle sx={{ backgroundColor: 'primary', color: '#ffffff' }}>
                <Typography variant='h2'>
                    <History /> Invoice History
                </Typography>
            </DialogTitle>
            <DialogContent>
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle={''}
                    breadCrumbs={[]}
                    defaultPaginationProps={defaultPaginationProps}
                    getDataQuery={useGetInvoiceQuery}
                    isShowActiveToggleVisible={false}
                    tableColumns={tableColumns}
                    entityNameSingular='Invoice'
                    permissionName='review'
                    additionalQueryParameters={{ orderReviewId: orderReviewId }}
                    disableGutters
                    disableSelection
                    hideMenu
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
