import { AttachMoney } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { MiscLineItemDto, OrderDto, QuoteDto } from '../../../../../dtos';
import { useArchiveMiscLineItemDeleteFromDatabaseByIdMutation } from '../../../../../store/generated/generatedApi';
import { SlimFormHeader } from '../../../../Core/SlimFormHeader';
import { FormInput, FormNumberInput, FormSection, IEntityAutocomplete, IFormProps, LoadingIndicator } from '../../../../CoreLib/library';
import { useMiscLineItemForm } from './useMiscLineItemForm';
import { LineItemStatus, emptyGuid } from '../../../../../util';

export interface IMiscLineItemFormProps extends IFormProps<MiscLineItemDto> {
    quote?: QuoteDto;
    order?: OrderDto;
}

export const MiscLineItemForm: FC<IMiscLineItemFormProps> = (props) => {
    const { isLoading, quote, order } = props;
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleRateChange,
        handleCostChange,
        handleDescriptionChange,
        handleDriverMemoChange,
        formQuote,
        formRate,
        formCost,
        formDescription,
        formDriverMemo,
        formIsActive,
        initValues
    } = useMiscLineItemForm(props);

    const isLocked = useMemo(() => (initValues?.id !== emptyGuid && !!quote?.acceptedOn && initValues?.status !== LineItemStatus.Amended), [initValues?.id, initValues?.status, quote?.acceptedOn]);

    useEffect(() => {
        if (quote && !formQuote) {
            handleQuoteChange(quote);
        }
    }, [formQuote, handleQuoteChange, quote]);

    const currentMiscLineItemId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName='Misc Line Item'
                permissionsTypeName={'lineItem'}
                icon={<AttachMoney />}
                title={formQuote ? `Misc. for Quote #${formQuote.quoteNumber}` : 'New Misc. Line Item'}
                breadcrumbs={order ?
                    [
                        { label: 'Home', navLink: '/' },
                        { label: 'Jobs', navLink: '/jobs' },
                        { label: `Job #${quote?.quoteNumber}`, navLink: `/job/${order?.jobId}` },
                        { label: `Order #${order?.orderNumber}`, navLink: `/job/${order?.jobId}/order/${order?.id}` }
                    ] :
                    [
                        { label: 'Home', navLink: '/' },
                        { label: 'Quotes', navLink: '/quotes' },
                        { label: `${quote?.customer?.name ?? ''} (Quote #${quote?.quoteNumber ?? 'Quote'})`, navLink: `/quote/${quote?.id}` },
                        { label: `Line Items`, navLink: `/quote/${quote?.id}/lineItems` },
                    ]}
                isActive={formIsActive}
                id={currentMiscLineItemId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'misc line item'}
                deleteMutation={useArchiveMiscLineItemDeleteFromDatabaseByIdMutation}
                canDelete={!isLocked}
                canSave={!isLocked}
            />
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl error={!!fieldErrors.get('quoteId')} fullWidth required>
                                <FormLabel>Quote</FormLabel>
                                <IEntityAutocomplete
                                    options={[quote]}
                                    onChange={(e, value) => {
                                        handleQuoteChange(value ?? null);
                                    }}
                                    value={formQuote}
                                    getOptionLabel={(option: QuoteDto) => `${option.quoteNumber}`}
                                    disabled
                                    error={!!fieldErrors.get('quoteId')}
                                />
                                <FormHelperText>{fieldErrors.get('quoteId')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={formCost}
                                onChange={handleCostChange}
                                label='Cost'
                                name='cost'
                                errorText={fieldErrors.get('cost')}
                                error={!!fieldErrors.get('cost')}
                                fullWidth
                                required
                                disabled={isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={formRate}
                                onChange={handleRateChange}
                                label='Rate'
                                name='Rate'
                                errorText={fieldErrors.get('rate')}
                                error={!!fieldErrors.get('rate')}
                                fullWidth
                                required
                                disabled={isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormInput
                                value={formDescription}
                                onChange={handleDescriptionChange}
                                label='Description'
                                name='description'
                                errorText={fieldErrors.get('description')}
                                fullWidth
                                required
                                disabled={isLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            value={formDriverMemo ?? ''}
                            onChange={handleDriverMemoChange}
                            label='Driver Memo'
                            name='driverMemo'
                            fullWidth
                        />
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
