import { FC, useCallback, useMemo, useState } from "react";
import { LineItemDto, QuoteDto } from "../../../dtos";
import { Grid, IconButton, Menu, MenuItem, TableCell } from "@mui/material";
import { AuthenticatedComponent, DataTableColumn, StandardListViewV2, useFailedUpdateSnackbar } from "../../../Components/CoreLib/library";
import { LineItemTypes, QuoteType, formatCurrency } from "../../../util";
import { useArchiveLineItemDeleteFromDatabaseByIdMutation, useGetLineItemQuoteSummaryLineItemsQuery } from "../../../store/generated/generatedApi";
import { MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export interface IQuoteLineItemsViewProps {
    quote: QuoteDto;
}

export const QuoteLineItemsView: FC<IQuoteLineItemsViewProps> = (props) => {
    const { quote } = props;
    const navigate = useNavigate();
    const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [addMenuOpen, setAddMenuOpen] = useState(false);
    const [editMenuAnchorEl, setEditMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [editMenuOpen, setEditMenuOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<LineItemDto>();
    const [deleteObject, { error, isError, reset }] = useArchiveLineItemDeleteFromDatabaseByIdMutation();

    useFailedUpdateSnackbar('line item', isError, reset, error);

    const isFobQuote = useCallback(() => {
        return quote?.type === QuoteType.FOB;
    }, [quote?.type]);

    const haulingRate = useCallback((row: LineItemDto) => {
        if (row.flatRateApplied) {
            return formatCurrency(row.flatRateApplied);
        } else {
            if (row.type !== LineItemTypes.SiteHauling) {
                if (row.associatedLineItem) {
                    return row.associatedLineItem.haulingRate ? formatCurrency(row.associatedLineItem.haulingRate) : '';
                } else {
                    return row.haulingRate ? formatCurrency(row.haulingRate) : '';
                }
            } else {
                return '';
            }
        }
    }, []);

    const additionalRate = useCallback((row: LineItemDto) => {
        return row.additionalRate ? formatCurrency(row.additionalRate) : '';
    }, []);

    const quoteRate = useCallback((row: LineItemDto) => {
        if (row.flatRateApplied) {
            return row.rate ? formatCurrency(row.rate) : '';
        } else {
            if (row.type === 'Flat Rate') {
                return row.haulingFlatRate !== null ? formatCurrency(row.haulingFlatRate) : '';
            } else if (row.type !== '') {
                return row.quoteRate !== null ? formatCurrency(row.quoteRate) : '';
            }
            return '';
        }
    }, []);

    const isHeader = useCallback((row: LineItemDto) => {
        if (isFobQuote()) {
            return false;
        }
        return (row.type === '' || row.type === LineItemTypes.SiteHauling);
    }, [isFobQuote]);

    const renderEditMenuButton = useCallback((row: LineItemDto) => {
        return (
            <AuthenticatedComponent
                requiredPermissions={['delete:lineItem']}
                children={
                    <TableCell padding='none' sx={{ border: 'none' }}>
                        <IconButton
                            size='small'
                            className="hover-content"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setEditMenuOpen(true);
                                setEditMenuAnchorEl(e.currentTarget);
                                setSelectedRow(row);
                            }}>
                            <MoreVert />
                        </IconButton>
                    </TableCell>
                }
            />
        );
    }, [setEditMenuOpen, setEditMenuAnchorEl, setSelectedRow]);

    const tableColumns: DataTableColumn<LineItemDto>[] = useMemo(() => {
        const getMaterialPriceValue = (row: LineItemDto) => {
            if (row.isBilledToCustomer) {
                return 'BTC';
            }
            return row.rate !== null ? formatCurrency(row.rate) : '';
        };

        let columns = [
            { key: 'description', label: 'Description', sortKey: 'DESCRIPTION', unsorted: true, fieldMapper: (row) => isHeader(row) ? <strong style={{ fontSize: '24px' }}>{row.description}</strong> : row.description },
            { key: 'site', label: 'Site', sortKey: 'SITE', unsorted: true, fieldMapper: (row) => row.site?.name },
            { key: 'type', label: 'Type', sortKey: 'TYPE', unsorted: true },
            { key: 'region', label: 'Region', sortKey: 'REGION', unsorted: true },
            { key: 'equipmentType', label: 'Equipment Type', sortKey: 'EQUIPMENT_TYPE', unsorted: true, fieldMapper: (row) => row.equipmentType?.type },
            { key: 'quoteRate', label: 'Quote Rate', sortKey: 'QUOTE_RATE', unsorted: true, align: 'right', fieldMapper: (row) => quoteRate(row) },
            { key: 'quantity', label: 'Quantity', sortKey: 'QUANTITY', align: 'right', unsorted: true, fieldMapper: (row) => row.quantity ? row.quantity?.toFixed(2) : '' },
            { key: 'unitOfMeasure', label: 'Units', sortKey: 'UNIT', align: 'right', unsorted: true },
            { key: 'salesPrice', label: 'Sales Price', sortKey: 'SALES_PRICE', unsorted: true, align: 'right', fieldMapper: (row) => row.salesPrice !== null ? formatCurrency(row.salesPrice) : '' },
            { key: 'status', label: 'Status', sortKey: 'STATUS', unsorted: true, align: 'right' },

            { key: '', label: '', sortKey: '', unsorted: true, fieldMapper: (row) => row.type !== '' ? renderEditMenuButton(row) : <div style={{ padding: '16px' }}></div> },
        ] as DataTableColumn<LineItemDto>[];

        if (!isFobQuote()) {
            columns.splice(4, 0,
                ...[
                    { key: 'materialPriceValue', label: 'Material Cost', sortKey: 'MATERIAL_PRICE_VALUE', unsorted: true, align: 'right', fieldMapper: (row) => getMaterialPriceValue(row) } as DataTableColumn<LineItemDto>,
                    { key: 'additionalRate', label: 'Additional Rate', sortKey: 'ADDITIONAL_RATE', unsorted: true, align: 'right', fieldMapper: (row) => additionalRate(row) } as DataTableColumn<LineItemDto>,
                    { key: 'haulingRate', label: 'Hauling Rate', sortKey: 'HAULING_RATE', unsorted: true, align: 'right', fieldMapper: (row) => haulingRate(row) } as DataTableColumn<LineItemDto>
                ]
            );
        }
        return columns;
    }, [additionalRate, haulingRate, isFobQuote, isHeader, quoteRate, renderEditMenuButton]);

    const handleAddMenuOpen = (event?: React.MouseEvent<HTMLButtonElement>) => {
        if (event) {
            setAddMenuAnchorEl(event.currentTarget);
            setAddMenuOpen(true);
        }
    };

    const buildEditUrl = useCallback((lineItem: LineItemDto) => {
        if (lineItem.type === LineItemTypes.Material) {
            return `/quote/${quote?.id}/lineItem/material/${lineItem.id}`;
        } else if (lineItem.type === LineItemTypes.SiteHauling) {
            return `/quote/${quote?.id}/lineItem/siteHauling/${lineItem.id}`;
        } else if (lineItem.type === 'Flat Rate') {
            return `/quote/${quote?.id}/lineItem/siteHauling/${lineItem.siteHaulingLineItemIdRelatedToFlatRate}`;
        } else if (lineItem.type === LineItemTypes.Hourly) {
            return `/quote/${quote?.id}/lineItem/hourly/${lineItem.id}`;
        } else if (lineItem.type === LineItemTypes.Dump) {
            return `/quote/${quote?.id}/lineItem/dump/${lineItem.id}`;
        } else if (lineItem.type === LineItemTypes.Misc) {
            return `/quote/${quote?.id}/lineItem/misc/${lineItem.id}`;
        }
        return '/';
    }, [quote?.id]);

    const handleCreate = useCallback((type: string) => {
        if (type === LineItemTypes.Material) {
            navigate(`/quote/${quote?.id}/lineItem/material/create`);
        } else if (type === LineItemTypes.SiteHauling) {
            navigate(`/quote/${quote?.id}/lineItem/siteHauling/create`);
        } else if (type === LineItemTypes.Hourly) {
            navigate(`/quote/${quote?.id}/lineItem/hourly/create`);
        } else if (type === LineItemTypes.Dump) {
            navigate(`/quote/${quote?.id}/lineItem/dump/create`);
        } else if (type === LineItemTypes.Misc) {
            navigate(`/quote/${quote?.id}/lineItem/misc/create`);
        }
    }, [navigate, quote?.id]);

    const handleEdit = useCallback(() => {
        if (selectedRow) {
            navigate(buildEditUrl(selectedRow));
        }
        setSelectedRow(undefined);
        setEditMenuOpen(false);
    }, [navigate, buildEditUrl, selectedRow]);

    const handleDelete = useCallback(() => {
        if (selectedRow) {
            deleteObject({ id: selectedRow.id });
        }
        setSelectedRow(undefined);
        setEditMenuOpen(false);
    }, [deleteObject, selectedRow]);

    const renderAddMenu = () => {
        return (<Menu
            anchorEl={addMenuAnchorEl}
            open={addMenuOpen}
            onClose={() => { setAddMenuOpen(false); }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <AuthenticatedComponent
                requiredPermissions={['create:lineItem']}
                children={
                    <>
                        <MenuItem onClick={() => handleCreate(LineItemTypes.Material)}>Material</MenuItem>
                        <MenuItem onClick={() => handleCreate(LineItemTypes.SiteHauling)}>Haul Rate</MenuItem>
                        <MenuItem onClick={() => handleCreate(LineItemTypes.Hourly)}>Hourly</MenuItem>
                        <MenuItem onClick={() => handleCreate(LineItemTypes.Dump)}>Dump</MenuItem>
                        <MenuItem onClick={() => handleCreate(LineItemTypes.Misc)}>Misc</MenuItem>
                    </>
                }
            />
        </Menu>);
    }

    const renderEditMenu = useCallback(() => {
        return (<Menu
            anchorEl={editMenuAnchorEl}
            open={editMenuOpen}
            onClose={() => {
                setEditMenuOpen(false);
                setSelectedRow(undefined);
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <AuthenticatedComponent
                requiredPermissions={['edit:lineItem']}
                children={
                    <>
                        <MenuItem onClick={() => handleEdit()}>Edit</MenuItem>
                        <MenuItem onClick={() => handleDelete()} disabled={!selectedRow?.canDelete}>Delete</MenuItem>
                    </>
                }
            />
        </Menu>);
    }, [editMenuAnchorEl, editMenuOpen, selectedRow, handleEdit, handleDelete])

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                {renderAddMenu()}
                {renderEditMenu()}
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle={''}
                    breadCrumbs={[]}
                    isPaginationVisible={false}
                    getDataQuery={useGetLineItemQuoteSummaryLineItemsQuery}
                    additionalQueryParameters={quote ? { quoteId: quote?.id } : undefined}
                    isShowActiveToggleVisible={false}
                    tableColumns={tableColumns}
                    entityNameSingular='Quote'
                    editIcon={<MoreVert />}
                    permissionName='quote'
                    handleAdd={handleAddMenuOpen}
                    handleRowClick={(id, record) => {
                        const lineItem = record as LineItemDto;
                        if (lineItem.type !== '') {
                            navigate(buildEditUrl(lineItem));
                        }
                    }}
                    hideSearch
                    disableSelection
                    hideMenu
                    hideAddButtonDivider
                />
            </Grid>
        </Grid>
    );
};