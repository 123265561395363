import { Cancel, Error, ListAlt, PlaylistAdd, Restore } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Link, Menu, MenuItem, Paper, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../../Theme';
import { usePrompt } from '../../../Views';
import { QuoteLineItemsOrderView } from '../../../Views/Orders';
import { OrderDto, OrderLineItemDto, QuoteDto } from '../../../dtos';
import {
    useArchiveOrderDeleteByIdMutation,
    usePatchOrderCancelMutation,
    usePatchOrderLineItemCancelMutation,
    usePatchOrderLineItemRestoreByIdMutation,
    usePatchOrderRestoreByIdMutation,
} from '../../../store/generated/generatedApi';
import { LineItemTypes, QuoteType } from '../../../util';
import { ContactSelect, DatePicker } from '../../CommonInputs';
import { ConfirmationDialogWithInput } from '../../Core/ConfirmDialog/ConfirmationDialogWithInput';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { AuthenticatedComponent, FormInput, IFormProps, LoadingIndicator, StandardCardWithHeader } from '../../CoreLib/library';
import { OrderLineItemTable } from './OrderLineItemTable';
import { QuickAddMaterialLineItemDialog } from './QuickAddMaterialLineItemDialog/QuickAddMaterialLineItemDialog';
import { useOrderForm } from './useOrderForm';

export interface IOrderFormProps extends IFormProps<OrderDto> {
    jobId: string;
}

export const OrderForm: FC<IOrderFormProps> = (props) => {
    const { isLoading, initValues, jobId } = props;
    const navigate = useNavigate();
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        handleOrderLineItemsChange,
        handleIsActiveChange,
        handleAddNewLineItem,
        handleAddNewLineItems,
        handleRemoveLineItem,
        handleContactChange,
        handleOrderedByContactChange,
        handleMemoChange,
        handleIsTaxableChange,
        setFormPoNumber,
        formContact,
        formOrderedByContact,
        formOrderNumber,
        formOrderDate,
        formOrderLineItems,
        formIsActive,
        formMemo,
        formPoNumber,
        fieldErrors,
        formQuote,
        formCustomer,
        formIsTaxable,
        job,
        isJobLoading,
        lineItemFieldErrors,
        isQuickAddMaterialLineItemDialogOpen,
        setIsQuickAddMaterialLineItemDialogOpen,
        selectedSiteHaulingLineItem,
    } = useOrderForm(props, jobId);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [addMenuOpen, setAddMenuOpen] = useState(false);
    const [isCancelMenuOpen, setIsCancelMenuOpen] = useState(false);
    const [isCancelOrderLineItemMenuOpen, setIsCancelOrderLineItemMenuOpen] = useState(false);
    const [orderLineItemIndexToModify, setOrderLineItemIndexToModify] = useState<number | null>(null);

    const [cancelOrder, { isLoading: isCancelingOrder }] = usePatchOrderCancelMutation();
    const [cancelOrderLineItem, { isLoading: isCancelingOrderLineItem }] = usePatchOrderLineItemCancelMutation();
    const [restoreOrder, { isLoading: isRestoringOrder }] = usePatchOrderRestoreByIdMutation();
    const [restoreOrderLineItem, { isLoading: isRestoringOrderLineItem }] = usePatchOrderLineItemRestoreByIdMutation();

    const currentOrderId = useMemo(() => {
        return initValues?.id;
    }, [initValues?.id]);

    const isExpired = useMemo(() => {
        return initValues?.isOrderExpired ?? false;
    }, [initValues?.isOrderExpired]);

    const handleAddMenuOpen = useCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
        if (event) {
            setAddMenuAnchorEl(event.currentTarget);
            setAddMenuOpen(true);
        }
    }, []);

    const handleCancelOrder = useCallback(
        (cancellationReason: string) => {
            setIsCancelMenuOpen(false);
            if (currentOrderId) {
                cancelOrder({ id: currentOrderId, reason: cancellationReason });
            }
        },
        [cancelOrder, currentOrderId]
    );

    const handleRestoreOrder = useCallback(() => {
        if (currentOrderId) {
            restoreOrder({ id: currentOrderId });
        }
    }, [currentOrderId, restoreOrder]);

    const handleCancelOrderLineItem = useCallback(
        (cancellationReason: string) => {
            setIsCancelOrderLineItemMenuOpen(false);
            if (orderLineItemIndexToModify !== null) {
                var updatedLineItems = _.cloneDeep(formOrderLineItems);
                var updatedLineItem = { ...formOrderLineItems[orderLineItemIndexToModify] };
                if (updatedLineItem) {
                    cancelOrderLineItem({ id: updatedLineItem.id, reason: cancellationReason }).then((response) => {
                        const data = (response as any).data as OrderLineItemDto;
                        if (data) {
                            setOrderLineItemIndexToModify(null);

                            updatedLineItem['canceledOn'] = data.canceledOn;
                            updatedLineItem['canceledByUserId'] = data.canceledByUserId;
                            updatedLineItem['cancellationReason'] = data.cancellationReason;
                            updatedLineItem['isCancelable'] = data.isCancelable;

                            updatedLineItems.splice(orderLineItemIndexToModify, 1, updatedLineItem);
                            handleOrderLineItemsChange(updatedLineItems);
                        }
                    });
                }
            }
        },
        [cancelOrderLineItem, formOrderLineItems, handleOrderLineItemsChange, orderLineItemIndexToModify]
    );

    const handleRestoreOrderLineItem = useCallback(() => {
        if (orderLineItemIndexToModify !== null) {
            var updatedLineItems = _.cloneDeep(formOrderLineItems);
            var updatedLineItem = { ...formOrderLineItems[orderLineItemIndexToModify] };
            if (updatedLineItem) {
                restoreOrderLineItem({ id: updatedLineItem.id }).then((response) => {
                    const data = (response as any).data as OrderLineItemDto;
                    if (data) {
                        setOrderLineItemIndexToModify(null);

                        updatedLineItem['canceledOn'] = data.canceledOn;
                        updatedLineItem['canceledByUserId'] = data.canceledByUserId;
                        updatedLineItem['cancellationReason'] = data.cancellationReason;
                        updatedLineItem['isCancelable'] = data.isCancelable;

                        updatedLineItems.splice(orderLineItemIndexToModify, 1, updatedLineItem);
                        handleOrderLineItemsChange(updatedLineItems);
                    }
                });
            }
        }
    }, [formOrderLineItems, handleOrderLineItemsChange, orderLineItemIndexToModify, restoreOrderLineItem]);

    const handleCreate = useCallback(
        (type: string) => {
            if (type === LineItemTypes.Material) {
                navigate(`/quote/${formQuote?.id}/lineItem/material/create/${job?.id}/${currentOrderId ?? ''}`);
            } else if (type === LineItemTypes.SiteHauling) {
                navigate(`/quote/${formQuote?.id}/lineItem/siteHauling/create/${job?.id}/${currentOrderId ?? ''}`);
            } else if (type === LineItemTypes.Hourly) {
                navigate(`/quote/${formQuote?.id}/lineItem/hourly/create/${job?.id}/${currentOrderId ?? ''}`);
            } else if (type === LineItemTypes.Dump) {
                navigate(`/quote/${formQuote?.id}/lineItem/dump/create/${job?.id}/${currentOrderId ?? ''}`);
            } else if (type === LineItemTypes.Misc) {
                navigate(`/quote/${formQuote?.id}/lineItem/misc/create/${job?.id}/${currentOrderId ?? ''}`);
            }
        },
        [navigate, formQuote?.id, job?.id, currentOrderId]
    );

    const renderAddMenu = useCallback(() => {
        return (
            <Menu
                anchorEl={addMenuAnchorEl}
                open={addMenuOpen}
                onClose={() => {
                    setAddMenuOpen(false);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <AuthenticatedComponent
                    requiredPermissions={['create:lineItem']}
                    children={
                        <>
                            <MenuItem onClick={() => handleCreate(LineItemTypes.Material)}>Material</MenuItem>
                            <MenuItem onClick={() => handleCreate(LineItemTypes.SiteHauling)}>Haul Rate</MenuItem>
                            <MenuItem onClick={() => handleCreate(LineItemTypes.Hourly)}>Hourly</MenuItem>
                            <MenuItem onClick={() => handleCreate(LineItemTypes.Dump)}>Dump</MenuItem>
                            <MenuItem onClick={() => handleCreate(LineItemTypes.Misc)}>Misc</MenuItem>
                        </>
                    }
                />
            </Menu>
        );
    }, [addMenuAnchorEl, addMenuOpen, handleCreate]);

    const isExpiredQuote = useCallback((quote: QuoteDto) => {
        const expirationDate = new Date(quote.expirationDate!);
        return expirationDate < new Date();
    }, []);

    const isNotModifiable = useMemo(
        () =>
            isExpired ||
            isCancelingOrder ||
            isCancelingOrderLineItem ||
            isRestoringOrder ||
            isRestoringOrderLineItem ||
            !!initValues?.orderReview ||
            !!initValues?.canceledOn,
        [initValues?.canceledOn, initValues?.orderReview, isCancelingOrder, isCancelingOrderLineItem, isExpired, isRestoringOrder, isRestoringOrderLineItem]
    );

    const additionalHeaderItems = useMemo(() => {
        if (initValues?.id) {
            return (
                <>
                    {!initValues?.canceledOn ? (
                        <AuthenticatedComponent
                            requiredPermissions={['cancel:orders']}
                            logic='and'
                            children={
                                <Button
                                    startIcon={<Cancel />}
                                    variant='contained'
                                    disabled={isNotModifiable || initValues?.isCancelable === false}
                                    onClick={() => {
                                        if (initValues?.id) {
                                            setIsCancelMenuOpen(true);
                                        }
                                    }}>
                                    Cancel Order
                                </Button>
                            }
                        />
                    ) : (
                        <AuthenticatedComponent
                            requiredPermissions={['cancel:orders']}
                            logic='and'
                            children={
                                <Button
                                    startIcon={<Restore />}
                                    variant='contained'
                                    disabled={
                                        isCancelingOrder ||
                                        isCancelingOrderLineItem ||
                                        isRestoringOrder ||
                                        isRestoringOrderLineItem ||
                                        !!initValues?.orderReview
                                    }
                                    onClick={() => {
                                        if (initValues?.id) {
                                            handleRestoreOrder();
                                        }
                                    }}>
                                    Restore Order
                                </Button>
                            }
                        />
                    )}
                </>
            );
        }
    }, [
        handleRestoreOrder,
        initValues?.canceledOn,
        initValues?.id,
        initValues?.isCancelable,
        initValues?.orderReview,
        isCancelingOrder,
        isCancelingOrderLineItem,
        isNotModifiable,
        isRestoringOrder,
        isRestoringOrderLineItem,
    ]);

    const getNotModifiableMessage = useCallback(() => {
        if (!!initValues?.canceledOn) {
            return <>canceled</>;
        }

        if (!!initValues?.orderReview) {
            return (
                <Link
                    variant='body2'
                    href={`/job/${jobId}/order/${currentOrderId}/orderReview/${initValues?.orderReview?.id}`}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(`/job/${jobId}/order/${currentOrderId}/orderReview/${initValues?.orderReview?.id}`);
                    }}>
                    in Review
                </Link>
            );
        }

        if (isExpired) {
            return <>expired</>;
        }

        return <></>;
    }, [currentOrderId, initValues?.canceledOn, initValues?.orderReview, isExpired, jobId, navigate]);

    if (isLoading || isJobLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box height='100%' sx={{ overflowY: 'hidden' }} display='flex' flexDirection='column' gap='16px'>
            {selectedSiteHaulingLineItem && (
                <QuickAddMaterialLineItemDialog
                    open={isQuickAddMaterialLineItemDialogOpen}
                    onClose={() => {
                        setIsQuickAddMaterialLineItemDialogOpen(false);
                    }}
                    onAdd={(lineItem) => {
                        handleAddNewLineItem(lineItem);
                        setIsQuickAddMaterialLineItemDialogOpen(false);
                    }}
                    siteHaulingLineItem={selectedSiteHaulingLineItem!}
                />
            )}
            <ConfirmationDialogWithInput
                open={isCancelMenuOpen}
                onClose={() => {
                    setIsCancelMenuOpen(false);
                }}
                formLabel={'Please enter a cancelation reason.'}
                title={`Cancel Order`}
                titleIcon={<Cancel />}
                cancelText={'Cancel'}
                confirmText={'Save'}
                save={handleCancelOrder}
                requireConfirmation={false}
            />
            <ConfirmationDialogWithInput
                open={isCancelOrderLineItemMenuOpen}
                onClose={() => {
                    setIsCancelOrderLineItemMenuOpen(false);
                    setOrderLineItemIndexToModify(null);
                }}
                formLabel={'Please enter a cancelation reason.'}
                title={`Cancel Order Line Item`}
                titleIcon={<Cancel />}
                cancelText={'Cancel'}
                confirmText={'Save'}
                save={handleCancelOrderLineItem}
                requireConfirmation={false}
            />
            {renderAddMenu()}
            <SlimFormHeader
                objectName='Order'
                permissionsTypeName='order'
                icon={<ListAlt />}
                title={initValues?.id ? `Order #${initValues?.orderNumber}` : 'New Order'}
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Jobs', navLink: '/jobs' },
                    { label: `Job #${job?.jobNumber}`, navLink: `/job/${jobId}` },
                ]}
                isActive={formIsActive}
                handleIsActiveChange={handleIsActiveChange}
                id={currentOrderId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'order'}
                deleteMutation={useArchiveOrderDeleteByIdMutation}
                canDelete={!!currentOrderId && !isNotModifiable}
                canSave={!!formQuote && !isNotModifiable}
                saveButtonTooltip={formQuote ? undefined : 'Set the Order Date and save to continue modifying this Order.'}
                additionalHeaderItems={additionalHeaderItems}
            />
            <Box flexGrow={1} display='flex' flexDirection='column' px={2} pb={1} gap={2} overflow='hidden'>
                {isNotModifiable && (
                    <AuthenticatedComponent
                        requiredPermissions={['edit:review', 'edit:billing']}
                        logic='or'
                        children={
                            <Paper square variant='outlined' sx={{ p: 1, textAlign: 'center' }}>
                                <Typography>This order is {getNotModifiableMessage()} and can't be edited.</Typography>
                            </Paper>
                        }
                    />
                )}
                {!currentOrderId && (
                    <Paper square variant='outlined' sx={{ p: 1, textAlign: 'center' }}>
                        <Typography>
                            <span style={{ color: '#A3271F' }}>Save this Order</span> with your Order Date to link it to the Current Quote on that date.
                        </Typography>
                    </Paper>
                )}
                {formQuote && (
                    <Box>
                        <StandardCardWithHeader headerTitle='Quote Price List' headerIcon={<ListAlt />}>
                            <Grid container direction='column' pb={2}>
                                <Grid item container direction='row' px={2} justifyContent='space-between' alignItems='center'>
                                    <Grid item>
                                        <Grid item container direction='column'>
                                            <AuthenticatedComponent
                                                requiredPermissions={['read:quote']}
                                                children={
                                                    <Grid item>
                                                        {formQuote ? (
                                                            <Link
                                                                variant='body2'
                                                                href={`/quote/${formQuote?.id}`}
                                                                component='button'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate(`/quote/${formQuote?.id}`);
                                                                }}>
                                                                {job?.jobName
                                                                    ? job?.jobName + ' - ' + job?.customerName + ' (Quote #' + formQuote?.quoteNumber + ')'
                                                                    : job?.customerName + ' (Quote #' + formQuote?.quoteNumber + ')'}
                                                            </Link>
                                                        ) : (
                                                            <Typography color='error'>Error: No valid quote for that order date!</Typography>
                                                        )}
                                                    </Grid>
                                                }
                                            />
                                            <Grid item>
                                                {formQuote && isExpiredQuote(formQuote) && (
                                                    <Grid item container spacing={1}>
                                                        <Grid item>
                                                            <Error color='error' />
                                                        </Grid>
                                                        <Grid item>
                                                            <strong>Quote Expired: {new Date(formQuote.expirationDate!).toLocaleDateString()}</strong>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='Quick Add All'>
                                            <IconButton
                                                onClick={(event) => {
                                                    if (event) {
                                                        handleAddNewLineItems(formQuote ? formQuote?.lineItems : []);
                                                    }
                                                }}
                                                aria-label='quick add all'
                                                sx={{
                                                    marginRight: 2,
                                                    color: theme.palette.primary.contrastText,
                                                    backgroundColor: theme.palette.primary.main,
                                                    '&:hover': { backgroundColor: theme.palette.primary.dark },
                                                }}
                                                disabled={!currentOrderId || isNotModifiable}>
                                                <PlaylistAdd />
                                            </IconButton>
                                        </Tooltip>
                                        <Button variant='contained' onClick={handleAddMenuOpen} disabled={!currentOrderId || isNotModifiable}>
                                            Add New Line item to Quote
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {formQuote ? (
                                        <QuoteLineItemsOrderView
                                            quote={formQuote}
                                            copyQuoteLineItemToOrder={(lineItem) => {
                                                handleAddNewLineItem(lineItem);
                                            }}
                                            disableCopy={!currentOrderId || isNotModifiable}
                                            maxHeight={'25vh'} // should be a string with 'px' or 'vh'
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                        </StandardCardWithHeader>
                    </Box>
                )}
                <Paper sx={{ display: 'flex', flexGrow: 1, overflow: 'auto', flexDirection: 'column', p: 2 }}>
                    <Grid container direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={12} sm={3} md={1}>
                            <FormInput value={formOrderNumber?.toString()} onChange={() => { }} label='Order #' fullWidth disabled required />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <DatePicker
                                label={'Order Date'}
                                value={formOrderDate}
                                onChange={() => { }}
                                disabled={isNotModifiable || !!currentOrderId}
                                error={!!fieldErrors.get('orderDate')}
                                errorText={fieldErrors.get('orderDate')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <ContactSelect
                                label='Ordered By'
                                customerId={formCustomer?.id}
                                selectedContactId={formOrderedByContact?.id ?? ''}
                                handleSelectedContactChange={handleOrderedByContactChange}
                                errorMessage={fieldErrors.get('orderedByContactId')}
                                disabled={isNotModifiable}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <ContactSelect
                                customerId={formCustomer?.id}
                                selectedContactId={formContact?.id ?? ''}
                                handleSelectedContactChange={handleContactChange}
                                errorMessage={fieldErrors.get('contactId')}
                                disabled={isNotModifiable}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <FormInput
                                value={formPoNumber}
                                onChange={(event) => setFormPoNumber(event.target.value)}
                                label='PO Number'
                                fullWidth
                                disabled={isNotModifiable}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={1}>
                            <FormControl fullWidth disabled={isNotModifiable}>
                                <FormControlLabel
                                    control={<Checkbox disabled={isNotModifiable} checked={formIsTaxable} onChange={handleIsTaxableChange} />}
                                    label='Is Taxable'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                            <FormInput value={formMemo} onChange={handleMemoChange} label='Memo' fullWidth maxLength={300} disabled={isNotModifiable} />
                        </Grid>
                    </Grid>
                    <Typography variant='h2' mt={1}>Order Line Items</Typography>
                    <Box flexGrow={1} overflow='hidden'>
                        <OrderLineItemTable
                            orderLineItems={formOrderLineItems}
                            removeLineItem={(i) => handleRemoveLineItem(i)}
                            setOrderLineItems={handleOrderLineItemsChange}
                            isReadOnly={isNotModifiable}
                            fieldErrors={lineItemFieldErrors}
                            handleCancelOrderLineItem={(index) => {
                                setOrderLineItemIndexToModify(index);
                                setIsCancelOrderLineItemMenuOpen(true);
                            }}
                            handleRestoreOrderLineItem={(index) => {
                                setOrderLineItemIndexToModify(index);
                                handleRestoreOrderLineItem();
                            }}
                            isFob={formQuote?.type === QuoteType.FOB}
                        />
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};
