import { ControlPointDuplicate, DownloadForOffline, History } from "@mui/icons-material";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, Button, CircularProgress } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSuccessfulActionSnackbar, useFailedActionSnackbar, DataTableColumn, StandardListViewV2, PaginatedProps } from "../../../Components/CoreLib/library";
import { JobDto, QuoteDto } from "../../../dtos";
import { useCreateQuotePdfMutation, useCreateQuoteCloneMutation, useGetQuoteQuery } from "../../../store/generated/generatedApi";
import { CloneQuoteDialog } from "../CloneQuoteDialog";

export interface IQuoteHistoryListViewDialog {
    onClose: () => void;
    open: boolean;
    job: JobDto;
}

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'EFFECTIVE_ON_DATE',
    sortAsc: false,
    page: 0,
    pageSize: 10000,
};

export const QuoteHistoryListViewDialog: FC<IQuoteHistoryListViewDialog> = (props) => {
    const { onClose, open, job } = props;
    const navigate = useNavigate();
    const [selectedQuote, setSelectedQuote] = useState<QuoteDto>();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [getQuotePdf, { isSuccess, isError: isErrorPdf, error: pdfGenerationError, isLoading: pdfLoading, reset: resetPdf }] = useCreateQuotePdfMutation();
    useSuccessfulActionSnackbar('created', 'quote PDF', isSuccess, resetPdf);
    useFailedActionSnackbar('create', 'quote PDF', isErrorPdf, resetPdf, pdfGenerationError);

    const [duplicateQuote, { data: duplicatedQuote, isSuccess: isQuoteDuplicateSuccess, isError: isQuoteDuplicateSuccessError, isLoading: isDuplicatingQuote, reset: resetDuplicateQuote }] = useCreateQuoteCloneMutation();
    useSuccessfulActionSnackbar('cloned', 'quote', isQuoteDuplicateSuccess, resetDuplicateQuote);
    useFailedActionSnackbar('clone', 'quote', isQuoteDuplicateSuccessError, resetDuplicateQuote);
    useEffect(() => {
        if (duplicatedQuote) {
            navigate(`/quote/${duplicatedQuote.id}`);
        }
    }, [duplicatedQuote, navigate]);

    const tableColumns: DataTableColumn<QuoteDto>[] = useMemo(() => {
        let columns = [
            { key: 'quoteNumber', label: 'Quote #', sortKey: 'QUOTE_NUMBER' },
            { key: 'clonedFromQuote.quoteNumber', label: 'Duplicated From Quote #', sortKey: 'CLONED_FROM_QUOTE_NUMBER', fieldMapper: (row) => (row.clonedFromQuote ? row.clonedFromQuote.quoteNumber : '') },
            { key: 'status', label: 'Status', sortKey: 'STATUS' },
            { key: 'quoteDate', label: 'Quote Date', sortKey: 'QUOTE_DATE', fieldMapper: (row) => (row.quoteDate ? new Date(row.quoteDate.toString()).toLocaleDateString() : '') },
            { key: 'effectiveOn', label: 'Effective On Date', sortKey: 'EFFECTIVE_ON_DATE', fieldMapper: (row) => (row.effectiveOn ? new Date(row.effectiveOn.toString()).toLocaleDateString() : '') },
            { key: 'expirationDate', label: 'Expiration Date', sortKey: 'EXPIRATION_DATE', fieldMapper: (row) => (row.expirationDate ? new Date(row.expirationDate.toString()).toLocaleDateString() : '') }
        ] as DataTableColumn<QuoteDto>[];

        return columns;
    }, []);

    const handleTableEdit = useCallback((id: string) => {
        navigate(`/quote/${id}`);
    }, [navigate]);

    const generatePdf = useCallback((id?: string) => {
        if (!pdfLoading && id) {
            getQuotePdf({ id: id }).then((response: any) => {
                if (response?.data) {
                    window.open(response?.data.url, '_blank');
                }
            });
        }
    }, [getQuotePdf, pdfLoading]);

    const openConfirmDialog = useCallback((quote: QuoteDto) => {
        setSelectedQuote(quote);
        setIsDialogOpen(true);
    }, []);

    const handleCloneQuote = useCallback((effectiveOn: Date, priceIncreasePercentage?: number) => {
        setIsDialogOpen(false);
        if (selectedQuote) {
            duplicateQuote({ id: selectedQuote.id, effectiveOn: effectiveOn, priceIncreasePercentage: priceIncreasePercentage });
        }
    }, [duplicateQuote, selectedQuote]);

    const getRowStyle = useCallback((row: any) => {
        if (row.id === job.currentQuote?.id) {
            return { backgroundColor: '#D7776E' };
        } else if (new Date(row.effectiveOn) < new Date()) {
            return { backgroundColor: '#cccccc' };
        } else {
            return {};
        }
    }, [job]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg' PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography variant='h2'>
                    {<History />} Quote History
                </Typography>
            </DialogTitle>
            <DialogContent>
                {selectedQuote && <CloneQuoteDialog open={isDialogOpen} onConfirm={handleCloneQuote} onClose={() => setIsDialogOpen(false)} quoteEffectiveOn={new Date(selectedQuote.effectiveOn!)} />}
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle={''}
                    breadCrumbs={[]}
                    isPaginationVisible={false}
                    getDataQuery={useGetQuoteQuery}
                    defaultPaginationProps={defaultPaginationProps}
                    additionalQueryParameters={{
                        jobId: job?.id,
                        showHistorical: true
                    }}
                    isShowActiveToggleVisible={false}
                    tableColumns={tableColumns}
                    entityNameSingular='Quote'
                    permissionName='quote'
                    hideAddButtonDivider
                    handleEdit={handleTableEdit}
                    customRowStyling={getRowStyle}
                    additionalActionItems={
                        [
                            {
                                text: 'CLONE',
                                onClick: (selected: QuoteDto[]) => openConfirmDialog(selected[0]),
                                enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !isDuplicatingQuote && selected[0].clonedQuoteId === null,
                                icon: pdfLoading ? <CircularProgress size={12} color='primary' /> : <ControlPointDuplicate />
                            },
                            {
                                text: 'DOWNLOAD PDF',
                                onClick: (selected: QuoteDto[]) => generatePdf(selected[0]?.id),
                                enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !pdfLoading,
                                icon: pdfLoading ? <CircularProgress size={12} color='primary' /> : <DownloadForOffline />
                            },
                        ]
                    }
                    additionalMenuItems={
                        [
                            {
                                text: 'Clone',
                                onClick: (selected: QuoteDto[]) => openConfirmDialog(selected[0]),
                                enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !isDuplicatingQuote && selected[0].clonedQuoteId === null,
                            },
                            {
                                text: 'Download PDF',
                                onClick: (selected: QuoteDto[]) => generatePdf(selected[0]?.id),
                                enabledCheck: (selected: QuoteDto[]) => selected.length === 1 && !pdfLoading
                            }
                        ]
                    }
                />
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent='end'>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={onClose}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}