import { LocalShipping } from '@mui/icons-material';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { EquipmentDto } from '../../dtos';
import {
    useArchiveEquipmentBulkDeleteMutation,
    useEquipmentBulkArchiveMutation,
    useEquipmentBulkUnarchiveMutation,
    useGetEquipmentQuery
} from '../../store/generated/generatedApi';
import { usePageTitleSetter } from '../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'NUMBER',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const EquipmentListView: FC = () => {
    const navigate = useNavigate();
    usePageTitleSetter('Equipment');

    const tableColumns: DataTableColumn<EquipmentDto>[] = useMemo(() => [
        { key: 'number', label: 'Number', sortKey: 'NUMBER' },
        { key: 'equipmentType', label: 'Equipment Type', sortKey: 'TYPE', fieldMapper: (row) => (row.equipmentType ? row.equipmentType.type : '') },
    ], []);

    const handleCreateNew = () => {
        navigate('/dataManagement/equipment/create');
    };

    const handleTableEdit = (id: string) => {
        navigate(`/dataManagement/equipment/${id}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<LocalShipping />}
            headerTitle='Equipment'
            breadCrumbs={[
                { label: 'Home', navLink: '/' },
                { label: 'Data Management', navLink: '/dataManagement' },
            ]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetEquipmentQuery}
            isShowActiveToggleVisible
            inactivateMutation={useEquipmentBulkArchiveMutation}
            activateMutation={useEquipmentBulkUnarchiveMutation}
            deleteMutation={useArchiveEquipmentBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='Equipment'
            entityNamePlural='Equipment'
            permissionName='equipment'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
        />
    );
};
