import { AttachMoney, Edit } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, MenuItem, Select, Switch, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialLineItemDto, MaterialPriceDto, OrderDto, QuoteDto, RateToggle } from '../../../../../dtos';
import {
    useArchiveMaterialLineItemDeleteFromDatabaseByIdMutation,
    useLazyGetMaterialPriceQuery,
    useLazyGetSiteHaulingLineItemQuery,
} from '../../../../../store/generated/generatedApi';
import { LineItemStatus, emptyGuid, formatCurrency, pricingTypes } from '../../../../../util';
import { EquipmentTypeSelect, MaterialSelect, SiteSelect, UnitOfMeasureSelect } from '../../../../CommonInputs';
import { SlimFormHeader } from '../../../../Core/SlimFormHeader';
import {
    FormNumberInput,
    FormSection,
    IEntityAutocomplete,
    IFormProps,
    LoadingIndicator,
    UserPermissionContext,
    userHasPermissions,
    FormInput
} from '../../../../CoreLib/library';
import { useMaterialLineItemForm } from './useMaterialLineItemForm';
import { RateInputForm } from '../RateInputForm';

export interface IMaterialLineItemFormProps extends IFormProps<MaterialLineItemDto> {
    quote?: QuoteDto;
    order?: OrderDto;
}

export const MaterialLineItemForm: FC<IMaterialLineItemFormProps> = (props) => {
    const { isLoading, quote, order } = props;
    const { permissions } = useContext(UserPermissionContext);
    const navigate = useNavigate();
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleEquipmentTypeChange,
        handleHaulingDriverRateChange,
        handleHaulingHaulingRateChange,
        handleHaulingBrokerRateChange,
        handleIsBilledToCustomerChange,
        handleMaterialChange,
        handleMaterialPriceChange,
        handlePriceCodeChange,
        handleQuantityChange,
        handleQuoteChange,
        handleRateChange,
        handleTotalChange,
        handleCostChange,
        handleSiteChange,
        handleUnitOfMeasureChange,
        handleAdditionalHaulingRateChange,
        handleDriverMemoChange,
        handleHaulingMinimumFlatRateChange,
        handleHaulingDriverMinimumFlatRateChange,
        handleHaulingBrokerMinimumFlatRateChange,
        handleHaulingZoneChange,
        setCurrentSiteHaulingLineItem,
        handleRateToggleChange,
        currentSiteHaulingLineItem,
        formQuote,
        formSite,
        formEquipmentType,
        formRate,
        formQuantity,
        formIsBilledToCustomer,
        formUnitOfMeasure,
        formIsActive,
        formMaterial,
        formMaterialPrice,
        formPriceCode,
        formCost,
        formTotal,
        formNewHaulingLineItemRate,
        formNewHaulingLineItemDriverRate,
        formNewHaulingLineItemBrokerRate,
        formNewHaulingLineItemMinimumFlatRate,
        formNewHaulingLineItemDriverMinimumFlatRate,
        formNewHaulingLineItemBrokerMinimumFlatRate,
        formNewHaulingLineItemZone,
        formAdditionalHaulingRate,
        formDriverMemo,
        formRateToggle,
        validateField,
        isFobQuote,
        initValues,
        getCurrentRate,
    } = useMaterialLineItemForm(props);

    const isLocked = useMemo(
        () => initValues?.id !== emptyGuid && !!quote?.acceptedOn && initValues?.status !== LineItemStatus.Amended,
        [initValues?.id, initValues?.status, quote?.acceptedOn]
    );

    const [getMaterialPrices, { data: materialPrices, isLoading: materialPricesLoading }] = useLazyGetMaterialPriceQuery();
    const [getSiteHaulingLineItems, { data: siteHaulingLineItems, isFetching: siteHaulingLineItemsLoading }] = useLazyGetSiteHaulingLineItemQuery();

    useEffect(() => {
        if (quote && !formQuote) {
            handleQuoteChange(quote);
        }
    }, [formQuote, handleQuoteChange, quote]);

    useEffect(() => {
        if (formMaterial) {
            const effectiveOn = quote?.effectiveOn ? new Date(quote.effectiveOn) : undefined;
            getMaterialPrices({
                effectiveOn: effectiveOn?.toISOString(),
                materialId: formMaterial?.id,
                searchText: '',
                sortKey: 'MATERIAL_CODE',
                page: 0,
                pageSize: 100000,
                sortAsc: true,
                includeInactive: false,
            });
        }
    }, [getMaterialPrices, formMaterial, quote?.effectiveOn]);

    useEffect(() => {
        if (formSite && formEquipmentType && formQuote) {
            if (formUnitOfMeasure && formSite?.id) {
                getSiteHaulingLineItems({
                    quoteId: formQuote?.id ?? '',
                    equipmentTypeId: formEquipmentType?.id ?? '',
                    siteId: formSite?.id ?? '',
                    unitOfMeasure: formUnitOfMeasure ?? '',
                    searchText: '',
                    sortKey: 'TYPE',
                    page: 0,
                    pageSize: 100000,
                    sortAsc: true,
                    includeInactive: false,
                });
            }
        }
    }, [formEquipmentType, formSite, formQuote, formSite?.id, formUnitOfMeasure, getSiteHaulingLineItems]);

    useEffect(() => {
        if (siteHaulingLineItems && siteHaulingLineItems.totalQueryResults && siteHaulingLineItems.totalQueryResults > 0) {
            setCurrentSiteHaulingLineItem(siteHaulingLineItems.pageResults[0]);
        } else {
            setCurrentSiteHaulingLineItem(undefined);
        }
    }, [setCurrentSiteHaulingLineItem, siteHaulingLineItems]);

    const currentMaterialLineItemId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    const canEditCost = useMemo(() => {
        const isUserAllowedToEditCost = userHasPermissions(['edit:setCustomCost'], permissions, 'and');
        if (initValues?.priceCode === 'Custom') {
            return isUserAllowedToEditCost;
        } else {
            return true;
        }
    }, [initValues?.priceCode, permissions]);

    const handleHaulingLineItemEdit = useCallback(() => {
        if (siteHaulingLineItems && siteHaulingLineItems.totalQueryResults && siteHaulingLineItems.totalQueryResults > 0) {
            const haulingItem = siteHaulingLineItems.pageResults[0];
            navigate(`/quote/${quote?.id}/lineItem/siteHauling/${haulingItem.id}`);
        }
    }, [navigate, quote?.id, siteHaulingLineItems]);

    const newHaulingFormInputs = useCallback(() => {
        return (
            <RateInputForm
                haulingRate={formNewHaulingLineItemRate}
                setHaulingRate={handleHaulingHaulingRateChange}
                haulingRateFieldName='newHaulingLineItemRate'
                minimumFlatRate={formNewHaulingLineItemMinimumFlatRate}
                setMinimumFlatRate={handleHaulingMinimumFlatRateChange}
                minimumFlatRateFieldName='newHaulingLineItemMinimumFlatRate'
                driverRate={formNewHaulingLineItemDriverRate}
                setDriverRate={handleHaulingDriverRateChange}
                driverRateFieldName='newHaulingLineItemDriverRate'
                driverFlatRate={formNewHaulingLineItemDriverMinimumFlatRate}
                setDriverFlatRate={handleHaulingDriverMinimumFlatRateChange}
                driverFlatRateFieldName='newHaulingLineItemDriverMinimumFlatRate'
                brokerRate={formNewHaulingLineItemBrokerRate}
                setBrokerRate={handleHaulingBrokerRateChange}
                brokerRateFieldName='newHaulingLineItemBrokerRate'
                brokerFlatRate={formNewHaulingLineItemBrokerMinimumFlatRate}
                setBrokerFlatRate={handleHaulingBrokerMinimumFlatRateChange}
                brokerFlatRateFieldName='newHaulingLineItemBrokerMinimumFlatRate'
                zone={formNewHaulingLineItemZone}
                setZone={handleHaulingZoneChange}
                rateToggle={formRateToggle}
                setRateToggle={handleRateToggleChange}
                fieldErrors={fieldErrors}
                disabled={isLocked}
                includeZone={true}
            />
        );
    }, [
        fieldErrors,
        formNewHaulingLineItemDriverMinimumFlatRate,
        formNewHaulingLineItemDriverRate,
        formNewHaulingLineItemMinimumFlatRate,
        formNewHaulingLineItemRate,
        formNewHaulingLineItemBrokerRate,
        formNewHaulingLineItemBrokerMinimumFlatRate,
        formNewHaulingLineItemZone,
        formRateToggle,
        handleHaulingDriverMinimumFlatRateChange,
        handleHaulingDriverRateChange,
        handleHaulingHaulingRateChange,
        handleHaulingMinimumFlatRateChange,
        handleHaulingBrokerRateChange,
        handleHaulingBrokerMinimumFlatRateChange,
        handleHaulingZoneChange,
        handleRateToggleChange,
        isLocked,
    ]);

    const haulingCardDetails = useCallback(() => {
        if (siteHaulingLineItemsLoading || !siteHaulingLineItems) {
            return <LoadingIndicator />;
        }
        if (siteHaulingLineItems && siteHaulingLineItems.totalQueryResults && siteHaulingLineItems.totalQueryResults > 0) {
            const haulingItem = siteHaulingLineItems.pageResults[0];
            return (
                <>
                    <Grid item>Site: {haulingItem.site?.code}</Grid>
                    <Grid item>Unit of Measure: {haulingItem.unitOfMeasure}</Grid>
                    <Grid item>Equipment Type: {haulingItem.equipmentType?.type}</Grid>
                    {haulingItem.rateToggle !== RateToggle.Flat && <Grid item>Hauling Rate: {formatCurrency(haulingItem.haulingRate)}</Grid>}
                    {haulingItem.rateToggle !== RateToggle.Standard && <Grid item>Minimum Flat Rate: {formatCurrency(haulingItem.haulingFlatRate)}</Grid>}
                    {haulingItem.rateToggle !== RateToggle.Flat && <Grid item>Driver Rate: {formatCurrency(haulingItem.driverRate)}</Grid>}
                    {haulingItem.rateToggle !== RateToggle.Standard && <Grid item>Driver Minimum Flat Rate: {formatCurrency(haulingItem.driverFlatRate)}</Grid>}
                    {haulingItem.rateToggle !== RateToggle.Flat && <Grid item>Broker Rate: {formatCurrency(haulingItem.brokerRate)}</Grid>}
                    {haulingItem.rateToggle !== RateToggle.Standard && <Grid item>Broker Minimum Flat Rate: {formatCurrency(haulingItem.brokerFlatRate)}</Grid>}
                    <Grid item>Zone: {haulingItem.zone}</Grid>
                </>
            );
        } else {
            return newHaulingFormInputs();
        }
    }, [newHaulingFormInputs, siteHaulingLineItems, siteHaulingLineItemsLoading]);

    const getHaulingCardTitle = useCallback(() => {
        if (currentSiteHaulingLineItem) {
            return `Hauling to Site ${currentSiteHaulingLineItem.site?.code}`;
        } else {
            return 'New Haul Rate Line Item';
        }
    }, [currentSiteHaulingLineItem]);

    const haulingCard = useCallback(() => {
        if (formSite && formEquipmentType && formUnitOfMeasure) {
            return (
                <FormSection>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>
                            <Typography variant='h2'>{getHaulingCardTitle()}</Typography>
                        </Grid>
                        <Grid item>
                            {currentSiteHaulingLineItem && !isLocked ? (
                                <IconButton onClick={handleHaulingLineItemEdit} sx={{ padding: '0px' }}>
                                    <Edit />
                                </IconButton>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>{haulingCardDetails()}</Grid>
                </FormSection>
            );
        }
    }, [
        currentSiteHaulingLineItem,
        formEquipmentType,
        formSite,
        formUnitOfMeasure,
        getHaulingCardTitle,
        handleHaulingLineItemEdit,
        haulingCardDetails,
        isLocked,
    ]);

    const filteredPricingTypes = useMemo(() => {
        var filteredList = [...pricingTypes];
        const shouldRemovePriceD = !formMaterialPrice?.currentPriceD;
        if (shouldRemovePriceD) {
            filteredList = filteredList.filter((pt) => pt !== 'D');
        }
        const shouldRemovePriceE = !formMaterialPrice?.currentPriceE;
        if (shouldRemovePriceE) {
            filteredList = filteredList.filter((pt) => pt !== 'E');
        }
        return filteredList;
    }, [formMaterialPrice]);

    const getMaterialNetCost = useCallback((materialPrice: MaterialPriceDto) => {
        return `(Net: ${formatCurrency((materialPrice.currentPriceC ?? 0) - (materialPrice.currentCost ?? 0))})`;
    }, []);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName='Haul Rate Line Item'
                permissionsTypeName={'lineItem'}
                icon={<AttachMoney />}
                title={formSite ? `${formMaterial?.code} from site ${formSite.code}` : 'New Material Line Item'}
                breadcrumbs={
                    order
                        ? [
                            { label: 'Home', navLink: '/' },
                            { label: 'Jobs', navLink: '/jobs' },
                            { label: `Job #${quote?.quoteNumber}`, navLink: `/job/${order?.jobId}` },
                            { label: `Order #${order?.orderNumber}`, navLink: `/job/${order?.jobId}/order/${order?.id}` },
                        ]
                        : [
                            { label: 'Home', navLink: '/' },
                            { label: 'Quotes', navLink: '/quotes' },
                            { label: `${quote?.customer?.name ?? ''} (Quote #${quote?.quoteNumber ?? 'Quote'})`, navLink: `/quote/${quote?.id}` },
                            { label: `Line Items`, navLink: `/quote/${quote?.id}/lineItems` },
                        ]
                }
                isActive={formIsActive}
                id={currentMaterialLineItemId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'haul rate line item'}
                canDelete={!isLocked}
                canSave={!isLocked}
                deleteMutation={useArchiveMaterialLineItemDeleteFromDatabaseByIdMutation}
            />
            <Grid item container direction='row' xs={12} spacing={2} px={2}>
                <Grid item xs={8}>
                    <FormSection>
                        <Grid item container direction='row' spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl error={!!fieldErrors.get('quoteId')} fullWidth required>
                                    <FormLabel>Quote</FormLabel>
                                    <IEntityAutocomplete
                                        options={[quote]}
                                        onChange={(e, value) => {
                                            handleQuoteChange(value ?? null);
                                        }}
                                        value={formQuote}
                                        getOptionLabel={(option: QuoteDto) => `${option.quoteNumber}`}
                                        disabled
                                        error={!!fieldErrors.get('quoteId')}
                                    />
                                    <FormHelperText>{fieldErrors.get('quoteId')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <EquipmentTypeSelect
                                    selectedEquipmentTypeId={formEquipmentType?.id ?? ''}
                                    handleSelectedEquipmentTypeChange={handleEquipmentTypeChange}
                                    required
                                    errorMessage={fieldErrors.get('equipmentTypeId')}
                                    disabled={isLocked}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SiteSelect
                                    selectedSiteId={formSite?.id ?? ''}
                                    handleSelectedSiteChange={handleSiteChange}
                                    required
                                    errorMessage={fieldErrors.get('siteId')}
                                    disabled={isLocked || !formIsBilledToCustomer}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={3}>
                            <Grid item>
                                <Typography variant='h2'>Material</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={3} alignItems='center'>
                            <Grid item xs={12} sm={6} md={4}>
                                <MaterialSelect
                                    selectedMaterialId={formMaterial?.id ?? ''}
                                    handleSelectedMaterialChange={handleMaterialChange}
                                    required
                                    errorMessage={fieldErrors.get('materialId')}
                                    disabled={isLocked}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl
                                    error={!!fieldErrors.get('materialPriceId')}
                                    fullWidth
                                    required={!formIsBilledToCustomer}
                                    disabled={!formMaterial || isLocked || formIsBilledToCustomer}>
                                    <FormLabel>Material Cost</FormLabel>
                                    <IEntityAutocomplete
                                        options={materialPrices?.pageResults}
                                        onChange={(e, value) => {
                                            handleMaterialPriceChange(value ?? null);
                                        }}
                                        value={formMaterialPrice}
                                        getOptionLabel={(option: MaterialPriceDto) =>
                                            `Site ${option.site?.code} - ${formatCurrency(option.currentCost)} ${getMaterialNetCost(option)}`
                                        }
                                        isLoading={materialPricesLoading}
                                        disabled={!formMaterial || isLocked || formIsBilledToCustomer}
                                        error={!!fieldErrors.get('materialPriceId')}
                                    />
                                    <FormHelperText>{fieldErrors.get('materialPriceId')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControlLabel
                                    control={<Switch checked={formIsBilledToCustomer} onChange={handleIsBilledToCustomerChange} />}
                                    label='Bill To Customer'
                                    labelPlacement='end'
                                    disabled={isLocked}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={3}>
                            <Grid item>
                                <Typography variant='h2'>Pricing</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction='row' spacing={3}>
                            <Grid item xs={12} sm={6} md={2}>
                                <FormControl
                                    fullWidth
                                    disabled={!formMaterialPrice || isLocked || !canEditCost}
                                    required
                                    error={!!fieldErrors.get('priceCode')}>
                                    <FormLabel>Pricing</FormLabel>
                                    <Select value={formPriceCode ?? ''} onChange={handlePriceCodeChange} error={!!fieldErrors.get('priceCode')}>
                                        <MenuItem key='pricing-0' value={''}>
                                            None
                                        </MenuItem>
                                        {filteredPricingTypes.map((value) => (
                                            <MenuItem
                                                key={value}
                                                value={value}
                                                disabled={value === 'Custom' && !userHasPermissions(['edit:setCustomCost'], permissions, 'and')}>
                                                {value}
                                                {value !== 'Custom' ? ` - ${formatCurrency(getCurrentRate(value))}` : ''}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldErrors.get('priceCode')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <FormNumberInput
                                    value={formCost}
                                    onChange={handleCostChange}
                                    label='Cost'
                                    name='cost'
                                    fullWidth
                                    disabled={formPriceCode !== 'Custom' || !canEditCost}
                                    inputProps={{ min: 0 }}
                                    required
                                    error={!!fieldErrors.get('cost')}
                                    errorText={fieldErrors.get('cost')}
                                    isCurrency
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <UnitOfMeasureSelect
                                    selectedUnit={formUnitOfMeasure ?? ''}
                                    handleSelectedUnitChange={handleUnitOfMeasureChange}
                                    required
                                    errorMessage={fieldErrors.get('unitOfMeasure')}
                                    onBlur={() => validateField('unitOfMeasure')}
                                    disabled={!formMaterialPrice || isLocked || formPriceCode === ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <FormNumberInput
                                    value={formQuantity ?? ''}
                                    onChange={handleQuantityChange}
                                    label='Quantity'
                                    name='quantity'
                                    errorText={fieldErrors.get('quantity')}
                                    error={!!fieldErrors.get('quantity')}
                                    fullWidth
                                    required
                                    disabled={!formMaterialPrice || isLocked || formPriceCode === ''}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <FormNumberInput
                                    value={formRate ?? ''}
                                    onChange={handleRateChange}
                                    label='Rate'
                                    name='rate'
                                    errorText={fieldErrors.get('rate')}
                                    error={!!fieldErrors.get('rate')}
                                    fullWidth
                                    required
                                    disabled={!formMaterialPrice || formPriceCode === '' || isLocked}
                                    isCurrency
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <FormNumberInput
                                    value={formTotal ?? ''}
                                    onChange={handleTotalChange}
                                    label='Total'
                                    name='total'
                                    fullWidth
                                    disabled
                                    errorText={fieldErrors.get('total')}
                                    error={!!fieldErrors.get('total')}
                                    inputProps={{ min: 0 }}
                                    isCurrency
                                />
                            </Grid>
                        </Grid>
                        {!isFobQuote && (
                            <>
                                <Grid item container direction='row' spacing={3}>
                                    <Grid item>
                                        <Typography variant='h2'>Hauling</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction='row' spacing={3}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormNumberInput
                                            value={formAdditionalHaulingRate ?? ''}
                                            onChange={handleAdditionalHaulingRateChange}
                                            label='Additional Hauling Rate'
                                            name='additionalRate'
                                            errorText={fieldErrors.get('additionalRate')}
                                            error={!!fieldErrors.get('additionalRate')}
                                            fullWidth
                                            disabled={!formMaterialPrice || isLocked}
                                            inputProps={{ min: 0 }}
                                            isCurrency
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormInput
                                value={formDriverMemo ?? ''}
                                onChange={handleDriverMemoChange}
                                label='Driver Memo'
                                name='driverMemo'
                                fullWidth
                            />
                        </Grid>
                    </FormSection>
                </Grid>
                <Grid item xs={4}>
                    <Grid item container direction='column' spacing={3}>
                        <Grid item>
                            <FormSection>
                                <Grid item>
                                    <Typography variant='h2'>{!formSite ? 'No Site Selected' : `${formSite?.code} - ${formSite?.name}`}</Typography>
                                </Grid>
                                {formSite && (
                                    <Grid item container direction='column'>
                                        <Grid item>{formSite?.addressLine1}</Grid>
                                        <Grid item>{formSite?.addressLine2}</Grid>
                                        <Grid item>
                                            {formSite?.city ?? '(City)'}, {formSite?.state ?? '(State)'}, {formSite?.zipCode ?? '(Zip Code)'}
                                        </Grid>
                                        <Grid item>{formSite?.country ?? '(Country)'}</Grid>
                                    </Grid>
                                )}
                            </FormSection>
                        </Grid>
                        {!isFobQuote && <Grid item>{haulingCard()}</Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
