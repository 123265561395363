import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { MiscLineItemDto, QuoteDto } from '../../../../../dtos';
import { emptyGuid } from '../../../../../util';
import { IFormProps, IFormFieldValidationConfig, isNotBlank, runFormValidation, runFieldValidation, isGreaterThanOrEqualTo } from '../../../../CoreLib/library';

export interface IMiscLineItemFormValues {
    quoteId: string;
    cost: number;
    rate: number;
    description: string;
    driverMemo?: string;
    isActive: boolean;
}

export const DEFAULT_MISC_ITEM: MiscLineItemDto = {
    id: emptyGuid,
    quoteId: '',
    cost: 0,
    rate: 0,
    description: '',
    driverMemo: '',
    isActive: true,
    createdOn: new Date(),
};

export function useMiscLineItemForm(props: IFormProps<MiscLineItemDto>) {
    const { save, cancel, initValues } = props;
    const [formQuote, setFormQuote] = useState<QuoteDto>();
    const [formRate, setFormRate] = useState<number>();
    const [formCost, setFormCost] = useState<number>();
    const [formDescription, setFormDescription] = useState('');
    const [formDriverMemo, setFormDriverMemo] = useState<string | undefined>(initValues?.driverMemo);
    const [formIsActive, setFormIsActive] = useState(true);

    useEffect(() => {
        setFormQuote(initValues?.quote);
        setFormRate(initValues?.rate ?? 0);
        setFormCost(initValues?.cost ?? 0);
        setFormDescription(initValues?.description ?? '');
        setFormIsActive(initValues?.isActive ?? true);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IMiscLineItemFormValues, string>>(
        new Map([
            ['quoteId', ''],
            ['cost', ''],
            ['rate', ''],
            ['description', ''],
            ['driverMemo', ''],
        ])
    );

    const formFieldValidators = new Map<keyof IMiscLineItemFormValues, IFormFieldValidationConfig>([
        [
            'quoteId',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Quote',
            },
        ],
        [
            'cost',
            {
                validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                errorMessageEntityName: 'Cost',
            },
        ],
        [
            'rate',
            {
                validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                errorMessageEntityName: 'Rate',
            },
        ],
        [
            'description',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Description',
            },
        ],
    ]);

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IMiscLineItemFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): IMiscLineItemFormValues => {
        return {
            quoteId: formQuote?.id ?? emptyGuid,
            cost: formCost ?? 0,
            rate: formRate ?? 0,
            description: formDescription ?? '',
            driverMemo: formDriverMemo ?? '',
            isActive: formIsActive,
        };
    };

    const validateField = (fieldName: keyof IMiscLineItemFormValues) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formQuote?.id !== initValues?.quote?.id;
        isDirty = isDirty || formRate !== initValues?.rate;
        isDirty = isDirty || formCost !== initValues?.cost;
        isDirty = isDirty || formDescription !== initValues?.description;
        isDirty = isDirty || formDriverMemo !== initValues?.driverMemo;

        return isDirty;
    };

    const handleQuoteChange = (quote: QuoteDto) => {
        setFormQuote(quote);
    };

    const handleRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleCostChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormCost(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDescription(event.target.value);
    };

    const handleDriverMemoChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDriverMemo(event.target.value ?? '');
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_MISC_ITEM),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleRateChange,
        handleCostChange,
        handleDescriptionChange,
        handleDriverMemoChange,
        handleIsActiveChange,
        formQuote,
        formRate,
        formCost,
        formDescription,
        formDriverMemo,
        formIsActive,
        validateField,
        initValues,
    };
}
