import { Article, Assignment, ControlPointDuplicate, History, ListAlt } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { JobDto, QuoteDto } from '../../../dtos';
import {
    useArchiveJobDeleteByIdMutation,
    useCreateJobUpdateCurrentQuoteMutation,
    useCreateOrderQuickCreateByIdMutation,
} from '../../../store/generated/generatedApi';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { IFormProps, LoadingIndicator, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../CoreLib/library';
import { useJobForm } from './useJobForm';
import { useNavigate } from 'react-router-dom';
import { CloneQuoteDialog, QuoteHistoryListViewDialog } from '../../../Views/Quote';
import { emptyGuid } from '../../../util';
import { usePrompt } from '../../../Views';

export interface IJobFormProps extends IFormProps<JobDto> {
    refetch: () => void;
}

export const JobForm: FC<IJobFormProps> = (props) => {
    const navigate = useNavigate();
    const { isLoading, initValues, refetch } = props;
    const { isFormDirty, handleSave, handleCancel, handleIsActiveChange, formIsActive } = useJobForm(props);
    const [isQuoteHistoryDialogOpen, setIsQuoteHistoryDialogOpen] = useState(false);
    const [isCloneQuoteDialogOpen, setIsCloneQuoteDialogOpen] = useState(false);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const [updateCurrentQuote, { data: clonedQuoteId, isSuccess, isError, isLoading: updateCurrentQuoteLoading, reset }] =
        useCreateJobUpdateCurrentQuoteMutation();
    const [
        quickCreateOrder,
        { data: order, isSuccess: quickCreateSuccess, isError: quickCreateError, isLoading: quickCreateOrderLoading, reset: resetQuickCreate },
    ] = useCreateOrderQuickCreateByIdMutation();

    useSuccessfulActionSnackbar('updated', 'current quote', isSuccess, reset);
    useFailedActionSnackbar('update', 'current quote', isError, reset);
    useSuccessfulActionSnackbar('created', 'order', quickCreateSuccess, resetQuickCreate);
    useFailedActionSnackbar('create', 'order', quickCreateError, resetQuickCreate);

    const currentJobId = useMemo(() => {
        return initValues?.id;
    }, [initValues?.id]);

    useEffect(() => {
        if (isSuccess && clonedQuoteId) {
            refetch();
            window.open(`/quote/${clonedQuoteId}`, '_blank');
        }
    }, [isSuccess, refetch, clonedQuoteId]);

    useEffect(() => {
        if (quickCreateSuccess && order) {
            window.open(`/job/${currentJobId}/order/${order.id}`, '_blank');
        }
    }, [isSuccess, refetch, clonedQuoteId, quickCreateSuccess, order, currentJobId]);

    const openConfirmDialog = useCallback(() => {
        setIsCloneQuoteDialogOpen(true);
    }, []);

    const handleCloneQuote = useCallback(
        (effectiveOn: Date, priceIncreasePercentage?: number) => {
            setIsCloneQuoteDialogOpen(false);
            if (currentJobId) {
                updateCurrentQuote({
                    params: { id: currentJobId },
                    payload: {
                        id: emptyGuid,
                        effectiveOn,
                        priceIncreasePercentage,
                    },
                });
            }
        },
        [currentJobId, updateCurrentQuote]
    );

    const handleQuickInputOrder = useCallback(() => {
        if (currentJobId) {
            quickCreateOrder({ jobId: currentJobId });
        }
    }, [currentJobId, quickCreateOrder]);

    const additionalHeaderItems = useMemo(() => {
        return (
            <>
                <Tooltip title={`View Quote History`}>
                    <span>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    if (currentJobId) {
                                        setIsQuoteHistoryDialogOpen(true);
                                    }
                                }}
                                disabled={isLoading || updateCurrentQuoteLoading}>
                                <History />
                            </IconButton>
                        </Box>
                    </span>
                </Tooltip>
                <Tooltip title={`Clone Newest Quote`}>
                    <span>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    if (currentJobId) {
                                        openConfirmDialog();
                                    }
                                }}
                                disabled={isLoading || updateCurrentQuoteLoading}>
                                <ControlPointDuplicate />
                            </IconButton>
                        </Box>
                    </span>
                </Tooltip>
                <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />
                <Tooltip title={`Quick Input Order`}>
                    <span>
                        <Box>
                            <IconButton onClick={handleQuickInputOrder} disabled={!initValues || quickCreateOrderLoading}>
                                <ListAlt />
                            </IconButton>
                        </Box>
                    </span>
                </Tooltip>
                <Tooltip title={`View Current Quote`}>
                    <span>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    navigate(`/quote/${initValues?.currentQuote?.id}`);
                                }}
                                disabled={!initValues}>
                                <Assignment />
                            </IconButton>
                        </Box>
                    </span>
                </Tooltip>
            </>
        );
    }, [isLoading, updateCurrentQuoteLoading, handleQuickInputOrder, initValues, quickCreateOrderLoading, currentJobId, openConfirmDialog, navigate]);

    const isExpiredQuote = useCallback((quote: QuoteDto) => {
        const expirationDate = new Date(quote.expirationDate!);
        return expirationDate < new Date();
    }, []);

    const getQuoteNotice = useCallback(() => {
        if (initValues?.currentQuote === null) {
            return <Typography>There is no Current Quote for this Job.</Typography>;
        } else if (initValues?.currentQuote?.acceptedOn === null) {
            return (
                <Typography>
                    This job is pending the approval of{' '}
                    <Link
                        variant='body2'
                        href={`/quote/${initValues?.currentQuote?.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/quote/${initValues?.currentQuote?.id}`);
                        }}>
                        {initValues.currentQuote.appraisalType === 0 ? 'Quote #' : 'Estimate #'}
                        {initValues?.currentQuote?.quoteNumber}
                    </Link>
                </Typography>
            );
        } else if (initValues && isExpiredQuote(initValues.currentQuote!)) {
            return (
                <Typography>
                    <Link
                        href={`/quote/${initValues?.currentQuote?.id}`}
                        variant='body2'
                        onClick={() => {
                            navigate(`/quote/${initValues?.currentQuote?.id}`);
                        }}>
                        Current Quote
                    </Link>{' '}
                    is expired.
                </Typography>
            );
        }
    }, [initValues, isExpiredQuote, navigate]);

    const selectedQuote = useMemo(() => {
        if (initValues?.quotes) {
            return initValues.quotes.find((x) => x.clonedQuoteId === null);
        }
        return undefined;
    }, [initValues?.quotes]);

    const displayName = useMemo(() => {
        const jobName = initValues?.currentQuote?.jobName ? `${initValues?.currentQuote?.jobName ?? '(Job Name)'} - ` : '';
        return (initValues?.id ? `${jobName}${initValues?.customerName} (Job #${initValues?.jobNumber})` : `New Job`).trim();
    }, [initValues?.currentQuote?.jobName, initValues?.customerName, initValues?.id, initValues?.jobNumber]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <>
            {initValues && <QuoteHistoryListViewDialog onClose={() => setIsQuoteHistoryDialogOpen(false)} open={isQuoteHistoryDialogOpen} job={initValues} />}
            {selectedQuote && (
                <CloneQuoteDialog
                    open={isCloneQuoteDialogOpen}
                    onConfirm={handleCloneQuote}
                    onClose={() => setIsCloneQuoteDialogOpen(false)}
                    quoteEffectiveOn={new Date(selectedQuote.effectiveOn!)}
                />
            )}
            <SlimFormHeader
                objectName='Job'
                permissionsTypeName='job'
                icon={<Article />}
                title={displayName}
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Jobs', navLink: '/jobs' },
                ]}
                isActive={formIsActive}
                handleIsActiveChange={handleIsActiveChange}
                id={currentJobId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'job'}
                deleteMutation={useArchiveJobDeleteByIdMutation}
                additionalHeaderItems={additionalHeaderItems}
            />
            {initValues && (initValues?.currentQuote === null || initValues?.currentQuote?.acceptedOn === null || isExpiredQuote(initValues.currentQuote!)) ? (
                <Grid item xs={12} mx={2}>
                    <Paper square variant='outlined'>
                        <Grid container direction='row' justifyContent='center' p={2}>
                            {getQuoteNotice()}
                        </Grid>
                    </Paper>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};
