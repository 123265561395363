import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { FormControl, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { MaterialDto, MaterialPriceCostDto } from "../../../dtos";
import { DatePicker, UnitOfMeasureSelect } from "../../CommonInputs";
import { FormNumberInput } from "../../CoreLib/library";
import { DEFAULT_MATERIAL_PRICE_COST, IMaterialPriceFormValues } from "./useMaterialPriceForm";
import _ from "lodash";

export interface IMaterialPriceCostFormProps {
    material?: MaterialDto;
    materialPriceCosts: MaterialPriceCostDto[];
    handleMaterialPriceCostsChanged: (value?: MaterialPriceCostDto[] | undefined) => void;
    fieldErrors: Map<keyof IMaterialPriceFormValues, string>;
};

export const MaterialPriceCostsForm: FC<IMaterialPriceCostFormProps> = (props) => {
    const {
        material,
        materialPriceCosts,
        handleMaterialPriceCostsChanged,
        fieldErrors,
    } = props;

    const onCostChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        let updated = [...materialPriceCosts];
        updated[idx] = { ...updated[idx], cost: e.target.valueAsNumber };
        handleMaterialPriceCostsChanged(updated);
    }, [handleMaterialPriceCostsChanged, materialPriceCosts]);

    const onPriceAChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        let updated = [...materialPriceCosts];
        updated[idx] = { ...updated[idx], priceA: e.target.valueAsNumber };
        handleMaterialPriceCostsChanged(updated);
    }, [handleMaterialPriceCostsChanged, materialPriceCosts]);

    const onPriceBChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        let updated = [...materialPriceCosts];
        updated[idx] = { ...updated[idx], priceB: e.target.valueAsNumber };
        handleMaterialPriceCostsChanged(updated);
    }, [handleMaterialPriceCostsChanged, materialPriceCosts]);

    const onPriceCChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        let updated = [...materialPriceCosts];
        updated[idx] = { ...updated[idx], priceC: e.target.valueAsNumber };
        handleMaterialPriceCostsChanged(updated);
    }, [handleMaterialPriceCostsChanged, materialPriceCosts]);

    const onPriceDChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        let updated = [...materialPriceCosts];
        updated[idx] = { ...updated[idx], priceD: e.target.valueAsNumber };
        handleMaterialPriceCostsChanged(updated);
    }, [handleMaterialPriceCostsChanged, materialPriceCosts]);

    const onPriceEChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        let updated = [...materialPriceCosts];
        updated[idx] = { ...updated[idx], priceE: e.target.valueAsNumber };
        handleMaterialPriceCostsChanged(updated);
    }, [handleMaterialPriceCostsChanged, materialPriceCosts]);

    const onUnitOfMeasureChanged = useCallback((value: string | undefined, idx: number) => {
        let updated = [...materialPriceCosts];
        updated[idx] = { ...updated[idx], unitOfMeasure: value ?? '' };
        handleMaterialPriceCostsChanged(updated);
    }, [handleMaterialPriceCostsChanged, materialPriceCosts]);

    const onEffectiveOnDateChanged = useCallback((date: Date | null | undefined, idx: number) => {
        let formattedDate = date ? new Date(date) : new Date();
        if (date) {
            formattedDate.setHours(0, 0, 0, 0);
        }
        let updated = [...materialPriceCosts];
        updated[idx] = { ...updated[idx], effectiveOn: formattedDate ?? undefined };
        handleMaterialPriceCostsChanged(updated);
    }, [handleMaterialPriceCostsChanged, materialPriceCosts]);

    const errors = useMemo(() => {
        var materialPriceCostErrors = fieldErrors.get('materialPriceCosts');
        if (materialPriceCostErrors) {
            return JSON.parse(materialPriceCostErrors);
        }

        return {};
    }, [fieldErrors]);

    const doesFieldHaveError = useCallback((idx: number, fieldName: string) => {
        const indexErrors = errors[idx];
        if (indexErrors) {
            const fieldErrors = indexErrors['fieldErrors'] as string[];
            return fieldErrors.some(x => x === fieldName);
        }
        return false;
    }, [errors]);

    const getErrorMessage = useCallback((idx: number) => {
        const indexErrors = errors[idx];
        if (indexErrors) {
            const errorMessage = indexErrors['errorMessage'];
            return errorMessage;
        }
        return null;
    }, [errors]);

    const currentPriceIndex = useCallback(() => {
        const sortedList = _.orderBy(materialPriceCosts.filter(x => (x.effectiveOn ? new Date(x.effectiveOn) : new Date()).getTime() <= new Date().getTime()), x => x.effectiveOn ? new Date(x.effectiveOn) : new Date(), 'desc');
        if (sortedList.length > 0) {
            return materialPriceCosts.findIndex(x => x.effectiveOn === sortedList[0].effectiveOn);
        }
    }, [materialPriceCosts]);

    const renderMaterialPriceCostRow = useCallback((cost: MaterialPriceCostDto, idx: number) => {
        return (
            <Grid key={`${cost.id}-${idx}`} item container direction='column'>
                <Grid key={`${cost.id}-${idx}-error`} item container direction='row' pb={1}>
                    <Typography color='error'>{getErrorMessage(idx)}</Typography>
                </Grid>
                <Grid key={`${cost.id}-${idx}-data`} item container direction='row' spacing={1} alignItems='center' pb={1} sx={{ backgroundColor: idx === currentPriceIndex() ? '#D7776E' : undefined, border: idx === currentPriceIndex() ? 1 : undefined }}>
                    {idx === currentPriceIndex() && <Grid key={`${cost.id}-${idx}-current`} item container direction='row'>
                        <Typography sx={{ fontWeight: 'bold' }}>Current Price</Typography>
                    </Grid>}
                    <Grid item container direction='row' spacing={1} md={7}>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={cost.cost}
                                onChange={e => onCostChanged(e, idx)}
                                label='Cost'
                                name='cost'
                                fullWidth
                                required
                                inputProps={{ min: 0 }}
                                error={doesFieldHaveError(idx, 'cost')}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={cost.priceA}
                                onChange={e => onPriceAChanged(e, idx)}
                                label='Price A'
                                name='priceA'
                                fullWidth
                                required
                                inputProps={{ min: 0 }}
                                error={doesFieldHaveError(idx, 'priceA')}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={cost.priceB}
                                onChange={e => onPriceBChanged(e, idx)}
                                label='Price B'
                                name='priceB'
                                fullWidth
                                required
                                inputProps={{ min: 0 }}
                                error={doesFieldHaveError(idx, 'priceB')}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={cost.priceC}
                                onChange={e => onPriceCChanged(e, idx)}
                                label='Price C'
                                name='priceC'
                                fullWidth
                                required
                                inputProps={{ min: 0 }}
                                error={doesFieldHaveError(idx, 'priceC')}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={cost.priceD}
                                onChange={e => onPriceDChanged(e, idx)}
                                label='Price D'
                                name='priceD'
                                fullWidth
                                inputProps={{ min: 0 }}
                                error={doesFieldHaveError(idx, 'priceD')}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={cost.priceE}
                                onChange={e => onPriceEChanged(e, idx)}
                                label='Price E'
                                name='priceE'
                                fullWidth
                                inputProps={{ min: 0 }}
                                error={doesFieldHaveError(idx, 'priceE')}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <UnitOfMeasureSelect
                            selectedUnit={cost.unitOfMeasure ?? ''}
                            handleSelectedUnitChange={updatedValue => onUnitOfMeasureChanged(updatedValue, idx)}
                            errorMessage={doesFieldHaveError(idx, 'unitOfMeasure') ? 'Default Unit of Measure is required' : ''}
                            required
                            label='Default Unit of Measure'
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={2}>
                        <FormControl fullWidth required error={doesFieldHaveError(idx, 'effectiveOn')}>
                            <DatePicker
                                label='Effective Date'
                                value={cost.effectiveOn}
                                onChange={date => onEffectiveOnDateChanged(date, idx)}
                                error={doesFieldHaveError(idx, 'effectiveOn')}
                                sx={{ backgroundColor: 'white', borderRadius: '4px', marginBottom: '3px' }}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1}>
                        <Tooltip title='Remove Material Cost'>
                            <IconButton
                                color='primary'
                                sx={{ marginTop: '15px' }}
                                disabled={!cost.canDelete}
                                onClick={() => {
                                    if (materialPriceCosts[idx].canDelete) {
                                        let updated = [...materialPriceCosts];
                                        updated.splice(idx, 1);
                                        handleMaterialPriceCostsChanged(updated);
                                    }
                                }}
                            >
                                <RemoveCircle />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid >
        );
    }, [currentPriceIndex, doesFieldHaveError, getErrorMessage, handleMaterialPriceCostsChanged, materialPriceCosts, onCostChanged, onEffectiveOnDateChanged, onPriceAChanged, onPriceBChanged, onPriceCChanged, onPriceDChanged, onPriceEChanged, onUnitOfMeasureChanged]);

    return (
        <Grid item container direction='column' spacing={2}>
            {materialPriceCosts.map((cost, idx) => {
                return renderMaterialPriceCostRow(cost, idx);
            })}
            <Grid item>
                <Tooltip title='Add Material Cost'>
                    <IconButton color='primary' onClick={() => {
                        handleMaterialPriceCostsChanged([{ ...DEFAULT_MATERIAL_PRICE_COST, unitOfMeasure: material?.defaultUnitOfMeasure ?? '' }, ...materialPriceCosts])
                    }}>
                        <AddCircle />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}