import { AttachMoney } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { HourlyLineItemDto, OrderDto, QuoteDto } from '../../../../../dtos';
import {
    useArchiveHourlyLineItemDeleteFromDatabaseByIdMutation,
    useGetLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItemsQuery,
} from '../../../../../store/generated/generatedApi';
import { SlimFormHeader } from '../../../../Core/SlimFormHeader';
import { IFormProps, LoadingIndicator, FormSection, FormNumberInput, IEntityAutocomplete, FormInput } from '../../../../CoreLib/library';
import { useHourlyLineItemForm } from './useHourlyLineItemForm';
import { LineItemStatus, LineItemTypes, emptyGuid, hourlyTypesList } from '../../../../../util';
import { EquipmentTypeSelect } from '../../../../CommonInputs';

export interface IHourlyLineItemFormProps extends IFormProps<HourlyLineItemDto> {
    quote?: QuoteDto;
    order?: OrderDto;
}

export const HourlyLineItemForm: FC<IHourlyLineItemFormProps> = (props) => {
    const { isLoading, quote, order } = props;
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleEquipmentTypeChange,
        handleRateChange,
        handleDriverRateChange,
        handleBrokerRateChange,
        handleHourlyTypeChange,
        handleDriverMemoChange,
        formQuote,
        formEquipmentType,
        formRate,
        formDriverRate,
        formBrokerRate,
        formIsActive,
        formHourlyType,
        formDriverMemo,
        initValues,
    } = useHourlyLineItemForm(props);

    const isLocked = useMemo(
        () => initValues?.id !== emptyGuid && !!quote?.acceptedOn && initValues?.status !== LineItemStatus.Amended,
        [initValues?.id, initValues?.status, quote?.acceptedOn]
    );

    const {
        data: hasCorrespondingMaterialLineItems,
        error: canDeleteMaterialError,
        isLoading: canDeleteMaterialLoading,
    } = useGetLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItemsQuery({ id: props.initValues?.id ?? '', lineItemType: LineItemTypes.Hourly });

    const [canDelete, setCanDelete] = useState(true);
    useEffect(() => {
        if (canDeleteMaterialError) {
            if ((canDeleteMaterialError as any).status === 404) {
                setCanDelete(true);
            }
        }

        if (hasCorrespondingMaterialLineItems && hasCorrespondingMaterialLineItems === true) {
            setCanDelete(false);
        }
    }, [canDeleteMaterialError, hasCorrespondingMaterialLineItems]);

    useEffect(() => {
        if (quote && !formQuote) {
            handleQuoteChange(quote);
        }
    }, [formQuote, handleQuoteChange, quote]);

    const currentHourlyLineItemId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    if (isLoading || canDeleteMaterialLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName='Hourly Line Item'
                permissionsTypeName={'lineItem'}
                icon={<AttachMoney />}
                title={formQuote ? `Hourly Line Item for Quote #${formQuote.quoteNumber}` : 'New Hourly Line Item'}
                breadcrumbs={
                    order
                        ? [
                            { label: 'Home', navLink: '/' },
                            { label: 'Jobs', navLink: '/jobs' },
                            { label: `Job #${quote?.quoteNumber}`, navLink: `/job/${order?.jobId}` },
                            { label: `Order #${order?.orderNumber}`, navLink: `/job/${order?.jobId}/order/${order?.id}` },
                        ]
                        : [
                            { label: 'Home', navLink: '/' },
                            { label: 'Quotes', navLink: '/quotes' },
                            { label: `${quote?.customer?.name ?? ''} (Quote #${quote?.quoteNumber ?? 'Quote'})`, navLink: `/quote/${quote?.id}` },
                            { label: `Line Items`, navLink: `/quote/${quote?.id}/lineItems` },
                        ]
                }
                isActive={formIsActive}
                id={currentHourlyLineItemId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'hourly line item'}
                canDelete={canDelete && !isLocked}
                canSave={!isLocked}
                deleteMutation={useArchiveHourlyLineItemDeleteFromDatabaseByIdMutation}
            />
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl error={!!fieldErrors.get('quoteId')} fullWidth required>
                                <FormLabel>Quote</FormLabel>
                                <IEntityAutocomplete
                                    options={[quote]}
                                    onChange={(e, value) => {
                                        handleQuoteChange(value ?? null);
                                    }}
                                    value={formQuote}
                                    getOptionLabel={(option: QuoteDto) => `${option.quoteNumber}`}
                                    disabled
                                    error={!!fieldErrors.get('quoteId')}
                                />
                                <FormHelperText>{fieldErrors.get('quoteId')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EquipmentTypeSelect
                                selectedEquipmentTypeId={formEquipmentType?.id ?? ''}
                                handleSelectedEquipmentTypeChange={handleEquipmentTypeChange}
                                required
                                errorMessage={fieldErrors.get('equipmentTypeId')}
                                disabled={!canDelete || isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormNumberInput
                                value={formRate}
                                onChange={handleRateChange}
                                label='Hourly Rate'
                                name='rate'
                                errorText={fieldErrors.get('rate')}
                                error={!!fieldErrors.get('rate')}
                                fullWidth
                                required
                                disabled={isLoading || isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl error={!!fieldErrors.get('hourlyType')} fullWidth required disabled={!canDelete || isLocked}>
                                <FormLabel>Type</FormLabel>
                                <Select value={formHourlyType ?? ''} onChange={handleHourlyTypeChange}>
                                    {hourlyTypesList.map((unit) => {
                                        return (
                                            <MenuItem key={unit} value={unit}>
                                                {unit}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText>{fieldErrors.get('hourlyType')}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item>
                            <Typography variant='h2'>Pay</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormNumberInput
                                value={formDriverRate}
                                onChange={handleDriverRateChange}
                                label='Driver Rate'
                                name='DriverRate'
                                errorText={fieldErrors.get('driverRate')}
                                error={!!fieldErrors.get('driverRate')}
                                fullWidth
                                required
                                disabled={isLoading || isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormNumberInput
                                value={formBrokerRate}
                                onChange={handleBrokerRateChange}
                                label='Broker Rate'
                                name='BrokerRate'
                                errorText={fieldErrors.get('brokerRate')}
                                error={!!fieldErrors.get('brokerRate')}
                                fullWidth
                                disabled={isLoading || isLocked}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            value={formDriverMemo ?? ''}
                            onChange={handleDriverMemoChange}
                            label='Driver Memo'
                            name='driverMemo'
                            fullWidth
                        />
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
