import { ListItem, ListItemButton, ListItemText, Divider, Box, List, SxProps, Theme, ListItemIcon, IconButton } from "@mui/material";
import { useCallback } from "react";
import { AddCircle, Error, RemoveCircle } from "@mui/icons-material";

export interface ISelectableListProps {
    items: any[];
    selectedIndex?: number;
    setSelectedIndex: (index: number) => void;
    hasError: (index: number) => boolean;
    getKey: (item: any, index: number) => string;
    getDesc: (item: any, index: number) => string;
    style?: SxProps<Theme>;
    addItem?: () => void;
    disableAdd?: () => boolean;
    removeItem?: (item: any, index: number) => void;
    disableRemove?: (item: any, index: number) => boolean;
}

export const SelectableList: React.FC<ISelectableListProps> = (props) => {
    const { items, selectedIndex, setSelectedIndex, hasError, getKey, getDesc, style, addItem, disableAdd, removeItem, disableRemove } = props;

    const renderRow = useCallback((item: any, index: number) => (
        <>
            <ListItem
                key={getKey(item, index)}
                component='div'
                disablePadding
                secondaryAction={removeItem && <IconButton disabled={disableRemove?.(item, index)} onClick={() => removeItem(item, index)} children={<RemoveCircle color={disableRemove?.(item, index) ? 'disabled' : 'primary'} />} />}
            >
                <ListItemButton
                    selected={selectedIndex === index}
                    onClick={() => setSelectedIndex(index)}
                >
                    <ListItemText primary={getDesc(item, index)} /> <Error color='error' sx={{ visibility: hasError(index) ? 'visible' : 'hidden', mr: 1 }} />
                </ListItemButton>
            </ListItem>
            <Divider key={`divider-${index}`} />
        </>
    ), [disableRemove, getDesc, getKey, hasError, removeItem, selectedIndex, setSelectedIndex]);

    return (
        <Box sx={style}>
            <List component='nav' sx={{ paddingY: '0px', height: '100%' }}>
                {items.length === 0 && <ListItemButton disabled> <ListItemText primary='No items' /> </ListItemButton>}
                {items.map((item, index) => renderRow(item, index))}
                {addItem && <ListItemButton onClick={addItem} disabled={disableAdd?.()}>
                    <ListItemIcon children={<AddCircle color={disableAdd?.() ? 'disabled' : 'primary'} />} />
                </ListItemButton>}
            </List>
        </Box>
    );
};