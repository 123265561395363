import { Button, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, Switch, Tooltip } from '@mui/material';
import { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { Delete, Cancel, Save, MoreVert, CheckCircle } from '@mui/icons-material';
import { SlimPageHeader, AuthenticatedComponent, useFailedActionSnackbar, useSuccessfulActionSnackbar, IBulkUpdateResult, ConfirmationModalWrapper } from '../../CoreLib/library';
import { ISlimFormHeaderProps } from './types';
import { useNavigate } from 'react-router-dom';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { usePageTitleSetter } from '../../../util';

export const SlimFormHeader: FC<ISlimFormHeaderProps> = ({
    additionalHeaderItems,
    additionalMenuItems = null,
    breadcrumbs,
    canDelete = true,
    canSave = true,
    deleteConfirmationDialog,
    deleteMutation,
    entityNameSingular,
    handleCancel,
    handleDelete,
    handleIsActiveChange,
    handleSave,
    hideDividers = false,
    icon,
    id,
    isActive,
    isFormDirty,
    isLoading,
    objectName,
    permissionsTypeName,
    redirectUrl: navigateUrl,
    submit,
    title,
    saveButtonTooltip,
    additionalItemsOnRight = false,
}) => {
    const placeholderMutationHook: any = () => [
        (args: any) => { },
        {
            isError: false,
            reset: () => { },
            data: undefined,
            isLoading: false,
        },
    ];

    const pageTitle = useMemo(() => title ? title : 'New ' + objectName, [title, objectName]);
    usePageTitleSetter(pageTitle);

    const navigate = useNavigate();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };
    const defaultIfNotProvided = placeholderMutationHook as UseMutation<MutationDefinition<any, any, any, IBulkUpdateResult, any>>;
    const [deleteObject, { isLoading: isDeleting, isSuccess: isDeleteSuccess, isError: isDeleteError, reset: resetDelete, error: deleteError }] = (
        deleteMutation ?? defaultIfNotProvided
    )();

    useSuccessfulActionSnackbar('deleted', entityNameSingular, isDeleteSuccess, () => {
        resetDelete();
        let url = breadcrumbs[breadcrumbs.length - 1].navLink;
        if (navigateUrl) {
            url = navigateUrl;
        }
        navigate(url);
    });
    useFailedActionSnackbar('delete', entityNameSingular, isDeleteError, resetDelete, deleteError);

    const onDelete = () => {
        if (handleDelete) {
            handleDelete();
        } else if (id) {
            deleteObject({
                id: id,
            });
        }
    };

    const renderConfirmationDialog = useCallback((isOpen: boolean, title: string, content: ReactElement, onCancel: () => void, onConfirm: () => void) => {
        return (
            <ConfirmationModalWrapper isOpen={!!isOpen} title={title} cancel={onCancel} confirm={onConfirm}>
                {content}
            </ConfirmationModalWrapper>
        );
    }, []);

    return (
        <Grid item xs={12}>
            <SlimPageHeader
                icon={icon}
                title={pageTitle}
                breadCrumbProps={{
                    links: breadcrumbs,
                    currentPageLabel: pageTitle,
                }}
                endSlot={
                    <Grid item container direction='row' alignItems='start' py={1}>
                        <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                            {!hideDividers && <Divider flexItem orientation='vertical' />}
                            {handleIsActiveChange &&
                                <FormControlLabel
                                    control={<Switch checked={isActive} onChange={handleIsActiveChange} size='small' />}
                                    label='Active'
                                    labelPlacement='start'
                                    disabled={isDeleting || !canDelete}
                                />
                            }
                            {id && (
                                <>
                                    <AuthenticatedComponent
                                        requiredPermissions={[`delete:` + permissionsTypeName]}
                                        children={
                                            <Tooltip title='Delete'>
                                                <span>
                                                    <IconButton size='large' onClick={() => {
                                                        if (deleteConfirmationDialog) {
                                                            setIsDeleteConfirmationDialogOpen(true);
                                                        } else {
                                                            onDelete();
                                                        }
                                                    }} disabled={isDeleting || !canDelete}>
                                                        <Delete fontSize='inherit' />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        }
                                    />
                                </>
                            )}
                            {!hideDividers && <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />}
                            {!additionalItemsOnRight && additionalHeaderItems && (
                                <>
                                    {additionalHeaderItems}
                                    {!hideDividers && <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />}
                                </>
                            )}
                            {isFormDirty && <Button variant='outlined' color='primary' size='small' onClick={handleCancel} startIcon={<Cancel />} sx={{ width: 90 }}>
                                {isFormDirty() ? 'Cancel' : 'Close'}
                            </Button>}
                            {handleSave &&
                                <>
                                    <Tooltip title={saveButtonTooltip}>
                                        <span>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        size='small'
                                                        onClick={handleSave}
                                                        disabled={isLoading || !canSave}
                                                        startIcon={<Save />}
                                                        sx={{ width: 90 }}>
                                                        Save
                                                    </Button>
                                                </Grid>
                                                {submit &&
                                                    <Grid item>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            size='small'
                                                            onClick={submit.handleSubmit}
                                                            disabled={isLoading || !submit.canSubmit}
                                                            startIcon={submit.icon ? submit.icon : <CheckCircle />}>
                                                            {submit.submitText ?? 'Submit'}
                                                        </Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </span>
                                    </Tooltip>
                                    {additionalItemsOnRight && additionalHeaderItems && (
                                        <>
                                            {additionalHeaderItems}
                                            {!hideDividers && <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />}
                                        </>
                                    )}
                                    <IconButton onClick={handleMenuOpen}>
                                        <MoreVert fontSize='inherit' />
                                    </IconButton>
                                </>
                            }
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={!!menuAnchorEl}
                                onClose={handleMenuClose()}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <MenuItem onClick={handleMenuClose(handleSave)} disabled={isLoading || !canSave}>Save</MenuItem>
                                {isFormDirty && handleCancel && <MenuItem onClick={handleMenuClose(handleCancel)}>{isFormDirty() ? 'Cancel' : 'Close'}</MenuItem>}
                                {additionalMenuItems && <Divider />}
                                {additionalMenuItems && additionalMenuItems.map(additionalMenuItem => <MenuItem onClick={additionalMenuItem.onClick} disabled={additionalMenuItem.disabled}>{additionalMenuItem.name}</MenuItem>)}
                                {id && (
                                    <AuthenticatedComponent
                                        requiredPermissions={[`delete:` + permissionsTypeName]}
                                        children={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(() => {
                                                    if (deleteConfirmationDialog) {
                                                        setIsDeleteConfirmationDialogOpen(true);
                                                    } else {
                                                        onDelete();
                                                    }
                                                })} disabled={!canDelete}>Delete</MenuItem>
                                            </>
                                        }
                                    />
                                )}
                            </Menu>
                        </Grid>
                    </Grid>
                }
            />
            {deleteConfirmationDialog &&
                renderConfirmationDialog(
                    isDeleteConfirmationDialogOpen,
                    deleteConfirmationDialog.title,
                    deleteConfirmationDialog.content,
                    () => {
                        setIsDeleteConfirmationDialogOpen(false);
                    },
                    () => {
                        setIsDeleteConfirmationDialogOpen(false);
                        onDelete();
                    }
                )}
        </Grid>
    );
};
