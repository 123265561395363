import { Error } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import _ from "lodash";
import { FC, useCallback, useMemo, useState } from "react";
import { DispatchReviewDto, EquipmentTypeDto, FreightBillingLineItemDto, OrderDto, OrderReviewLineItemDto } from "../../../dtos";
import { OrderByType } from "../../../dtos/generated/OrderByType";
import { useGetBrokerQuery, useGetDriverQuery, useGetEquipmentTypeQuery } from "../../../store/generated/generatedApi";
import { LineItemTypes, QuoteType } from "../../../util";
import { EquipmentTypeSelect, OrderBySelect, TimePicker, UnitOfMeasureSelect } from "../../CommonInputs";
import { PricingMethodTypeSelect } from "../../CommonInputs/PricingMethodTypeSelect";
import { SelectableList } from "../../CommonInputs/SelectableList";
import { FormInput, FormNumberInput, LoadingIndicator } from "../../CoreLib/library";
import { DispatchReviewTable } from "./DispatchReviewTable";
import { isErrorInMap } from "./useOrderReviewForm";
export interface IOrderReviewLineItemTableProps {
    disabled: boolean;
    orderLineItems: OrderReviewLineItemDto[];
    order: OrderDto;
    addDispatchReview: (lineItemIndex: number) => void;
    removeDispatchReview: (lineItemIndex: number, dispatchIndex: number) => void;
    setOrderLineItems: (orderLineItems: OrderReviewLineItemDto[]) => void;
    removeOrderReviewLineItem: (lineItemIndex: number) => void;
    addFreightBillingLineItem: (lineItemIndex: number, dispatchIndex: number) => void;
    removeFreightBillingLineItem: (lineItemIndex: number, dispatchIndex: number, index: number) => void;
    addOrderReviewLineItem?: () => void;
    lineItemErrors: Map<number, Map<keyof OrderReviewLineItemDto, string>>;
    dispatchErrors: Map<number, Map<number, Map<keyof DispatchReviewDto, string>>>;
    freightErrors: Map<number, Map<number, Map<number, Map<keyof FreightBillingLineItemDto, string>>>>;
};

export const OrderReviewLineItemTable: FC<IOrderReviewLineItemTableProps> = (props) => {
    const {
        disabled,
        orderLineItems,
        order,
        addDispatchReview,
        removeDispatchReview,
        setOrderLineItems,
        removeOrderReviewLineItem,
        addFreightBillingLineItem,
        removeFreightBillingLineItem,
        addOrderReviewLineItem,
        lineItemErrors,
        dispatchErrors,
        freightErrors
    } = props;
    const { data: equipmentTypes, isLoading: equipmentTypesLoading } = useGetEquipmentTypeQuery({ searchText: '', sortKey: 'TYPE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
    const { data: brokers } = useGetBrokerQuery({ searchText: '', sortKey: 'CODE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
    const { data: drivers } = useGetDriverQuery({ searchText: '', sortKey: 'CODE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });

    const [selectedTab, setSelectedTab] = useState('0');
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(orderLineItems.length > 0 ? 0 : undefined);

    const updateRow = useCallback((index: number, fieldName: string, value: any) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        if (fieldName === 'lineItemRate' || fieldName === 'unitOfMeasure') {
            updatedLineItem['billingRates']![fieldName] = value;
        } else {
            updatedLineItem[fieldName] = value;

            if (updatedLineItem.orderBy === OrderByType.Equipment) {
                updatedLineItem.loadOrQuantityReq = undefined;
            } else {
                updatedLineItem.equipmentRequired = undefined;
            }
        }
        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleEquipmentTypeChange = useCallback((index: number, equipmentType?: EquipmentTypeDto) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem["equipmentType"] = equipmentType;
        updatedLineItem["equipmentTypeId"] = equipmentType?.id;
        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleDispatchesUpdated = useCallback((index: number, dispatches: DispatchReviewDto[]) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem.dispatches = dispatches;
        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const checkForLineItemGeneralErrors = useCallback((lineItemIndex: number) => isErrorInMap(lineItemErrors.get(lineItemIndex)), [lineItemErrors]);

    const checkForDispatchErrors = useCallback((lineItemIndex: number) => {
        const dispatchErrorsMap = dispatchErrors.get(lineItemIndex);
        const freightErrorsMap = freightErrors.get(lineItemIndex);

        const isDispatchErrors = Array.from(dispatchErrorsMap?.values() ?? []).some(dispatchErrors => isErrorInMap(dispatchErrors));
        const isFreightErrors = Array.from(freightErrorsMap?.values() ?? []).some(freightErrors => Array.from(freightErrors.values()).some(freightError => isErrorInMap(freightError)));

        return isDispatchErrors || isFreightErrors;
    }, [dispatchErrors, freightErrors]);

    const checkLineItemForErrors = useCallback((lineItemIndex: number) => {
        const isGeneralErrors = checkForLineItemGeneralErrors(lineItemIndex);
        const isDispatchErrors = checkForDispatchErrors(lineItemIndex);

        return isGeneralErrors || isDispatchErrors;
    }, [checkForLineItemGeneralErrors, checkForDispatchErrors]);

    const isFob = useMemo(() => {
        return order?.quote?.type === QuoteType.FOB;
    }, [order?.quote?.type]);

    const isMaterialLineItem = useCallback((orderLineItem: OrderReviewLineItemDto) => {
        return orderLineItem.lineItem?.type === LineItemTypes.Material && !isFob;
    }, [isFob]);

    const generalDetails = useCallback((orderLineItem: OrderReviewLineItemDto, index: number) => {
        var errors = lineItemErrors.get(index);
        const isMaterial = isMaterialLineItem(orderLineItem);
        return (
            <Box p={2}>
                <Grid item container direction='column' spacing={1}>
                    <Grid item container direction='row' xs={12} spacing={1} wrap='nowrap'>
                        <Grid item xs={2}>
                            <FormNumberInput
                                value={orderLineItem.orderLineItemNumber}
                                name='orderLineItemNumber'
                                label='Line Item #'
                                fullWidth
                                disabled={disabled}
                                onChange={(event) => {
                                    updateRow(index, 'orderLineItemNumber', isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
                                }}
                                error={!!errors?.get('orderLineItemNumber')}
                                errorText={errors?.get('orderLineItemNumber')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={orderLineItem?.description}
                                onChange={(event) => {
                                    updateRow(index, 'description', event.target.value);
                                }}
                                label='Description'
                                fullWidth
                                disabled={!!orderLineItem.lineItemId}
                                errorText={errors?.get('description')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={orderLineItem.poNumber}
                                label='PO Number'
                                fullWidth
                                onChange={(event) => {
                                    updateRow(index, 'poNumber', event.target.value);
                                }}
                                errorText={errors?.get('poNumber')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <EquipmentTypeSelect
                                selectedEquipmentTypeId={orderLineItem?.equipmentType?.id ?? ''}
                                handleSelectedEquipmentTypeChange={(value) => {
                                    handleEquipmentTypeChange(index, value ?? undefined);
                                }}
                                disabled={disabled || equipmentTypes === undefined}
                                errorMessage={errors?.get('equipmentType')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={orderLineItem?.lineItem?.site?.name}
                                onChange={() => { }}
                                label='Site'
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={orderLineItem?.lineItem?.zone}
                                onChange={() => { }}
                                label='Zone'
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' xs={12} spacing={1} wrap='nowrap'>
                        {isMaterial && <Grid item xs={3}>
                            <PricingMethodTypeSelect
                                selectedPricingMethodType={orderLineItem.billingRates!.pricingMethodType!}
                                onChange={(value) => {
                                    updateRow(index, 'pricingMethodType', value);
                                }}
                                disabled
                            />
                        </Grid>}
                        <Grid item xs={isMaterial ? 3 : 4}>
                            <FormNumberInput
                                value={orderLineItem.billingRates?.salesPrice ?? orderLineItem.billingRates?.quoteRate ?? orderLineItem.billingRates?.lineItemRate}
                                onChange={(event) => {
                                    updateRow(index, 'lineItemRate', isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
                                }}
                                label={`Rate ${orderLineItem.billingRates?.flatRateApplied && isMaterial ? '(+ Flat)' : ''}`}
                                name='lineItemRate'
                                fullWidth
                                disabled={!!orderLineItem.lineItemId}
                                isCurrency
                            />
                        </Grid>
                        <Grid item xs={isMaterial ? 3 : 4}>
                            <FormNumberInput
                                label='Quantity'
                                name='quantity'
                                value={orderLineItem.billingRates?.quantity}
                                onChange={(e) => updateRow(index, 'billingRates.quantity', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                                fullWidth
                                inputProps={{ min: 0 }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={isMaterial ? 3 : 4}>
                            {!!orderLineItem.lineItemId ? <FormInput
                                value={orderLineItem?.billingRates?.unitOfMeasure}
                                onChange={() => { }}
                                label='Units'
                                fullWidth
                                disabled
                            /> :
                                <UnitOfMeasureSelect
                                    selectedUnit={orderLineItem?.billingRates?.unitOfMeasure ?? ''}
                                    handleSelectedUnitChange={(value) => updateRow(index, 'unitOfMeasure', value)}
                                    disabled={disabled}
                                    errorMessage={errors?.get('unitOfMeasure')}
                                />}
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={3}>
                            <TimePicker
                                value={orderLineItem.onsiteTime}
                                onChange={(date) => {
                                    if (date) {
                                        updateRow(index, 'onsiteTime', date);
                                    }
                                }}
                                label={`Onsite Time`}
                                disabled={disabled}
                                error={!!errors?.get('onsiteTime')}
                                errorText={errors?.get('onsiteTime')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimePicker
                                value={orderLineItem.yardTime}
                                onChange={(date) => {
                                    if (date) {
                                        updateRow(index, 'yardTime', date);
                                    }
                                }}
                                label={`Yard Time`}
                                disabled={disabled}
                                error={!!errors?.get('yardTime')}
                                errorText={errors?.get('yardTime')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <OrderBySelect
                                selectedOrderBy={orderLineItem.orderBy!}
                                onChange={(value) => {
                                    updateRow(index, 'orderBy', value);
                                }}
                                disabled={disabled}
                            />
                        </Grid>
                        {orderLineItem.orderBy !== OrderByType.Equipment && (
                            <Grid item xs={3}>
                                <FormNumberInput
                                    value={orderLineItem.loadOrQuantityReq}
                                    name='loadOrQuantityReq'
                                    onChange={(event) => {
                                        updateRow(index, 'loadOrQuantityReq', isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
                                    }}
                                    label='Quantity'
                                    fullWidth
                                    inputProps={{ min: 0 }}
                                    disabled={disabled}
                                />
                            </Grid>
                        )}
                        {!orderLineItem.orderBy && (
                            <Grid item xs={3}>
                                <FormNumberInput
                                    value={orderLineItem.equipmentRequired}
                                    name='equipmentRequired'
                                    onChange={(event) => {
                                        updateRow(
                                            index,
                                            'equipmentRequired',
                                            isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber
                                        );
                                    }}
                                    label='Equipment Req.'
                                    fullWidth
                                    inputProps={{ min: 0 }}
                                    disabled={disabled}
                                    error={!!errors?.get('equipmentRequired')}
                                    errorText={errors?.get('equipmentRequired')}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container direction='column' xs={12}>
                        <Grid item xs={12}>
                            <FormInput
                                value={orderLineItem.memo}
                                onChange={(event) => {
                                    updateRow(index, 'memo', event.target.value);
                                }}
                                label='Internal Memo'
                                fullWidth
                                disabled={disabled}
                                errorText={errors?.get('memo')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                value={orderLineItem.dispatchMemo}
                                onChange={(event) => {
                                    updateRow(index, 'dispatchMemo', event.target.value);
                                }}
                                label='Dispatch Memo'
                                fullWidth
                                disabled={disabled}
                                errorText={errors?.get('dispatchMemo')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }, [lineItemErrors, isMaterialLineItem, disabled, equipmentTypes, updateRow, handleEquipmentTypeChange]);

    const dispatchDetails = useCallback((orderLineItem: OrderReviewLineItemDto, index: number) => {
        return (
            <Box flexGrow={1} overflow='hidden'>
                <DispatchReviewTable
                    brokers={brokers?.pageResults ?? []}
                    disabled={disabled}
                    dispatchReviews={orderLineItem.dispatches ?? []}
                    drivers={drivers?.pageResults ?? []}
                    lineItemIndex={index}
                    orderReviewLineItem={orderLineItem}
                    order={order}
                    removeDispatchReview={removeDispatchReview}
                    setDispatches={handleDispatchesUpdated}
                    addFreightBillingLineItem={addFreightBillingLineItem}
                    removeFreightBillingLineItem={removeFreightBillingLineItem}
                    addDispatchReview={addDispatchReview ? () => {
                        addDispatchReview(index);
                    } : undefined}
                    dispatchErrors={dispatchErrors.get(index) ?? new Map()}
                    freightErrors={freightErrors.get(index) ?? new Map()}
                />
            </Box>
        );
    }, [addDispatchReview, addFreightBillingLineItem, brokers?.pageResults, disabled, drivers?.pageResults, handleDispatchesUpdated, order, removeDispatchReview, removeFreightBillingLineItem, dispatchErrors, freightErrors]);

    const details = useCallback((orderLineItem?: OrderReviewLineItemDto, index?: number) => {
        const isGeneralErrors = index !== undefined && checkForLineItemGeneralErrors(index);
        const isDispatchErrors = index !== undefined && checkForDispatchErrors(index);

        return (
            <Box key={orderLineItem?.id} sx={{ width: '100%', borderBottom: 1, borderRight: 1, borderColor: 'divider', display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}>
                <TabContext value={orderLineItem ? selectedTab : ''}>
                    <Box sx={{ width: '100%', borderTop: 1, borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant='fullWidth' sx={{ width: '100%' }} onChange={(_e, newValue) => setSelectedTab(newValue)}>
                            <Tab label='General' value='0' disabled={!orderLineItem} iconPosition='end' icon={isGeneralErrors ? <Error color='error' /> : undefined} sx={{ minHeight: '48px' }} />
                            <Tab label='Dispatches' value='1' disabled={!orderLineItem} iconPosition='end' icon={isDispatchErrors ? <Error color='error' /> : undefined} sx={{ minHeight: '48px' }} />
                        </TabList>
                    </Box>
                    {!orderLineItem && <Box textAlign='center'><Typography mt={2} color='GrayText'>No Item Selected</Typography></Box>}
                    <TabPanel value='0' sx={{ padding: '5px', overflowY: 'auto' }}>
                        {orderLineItem && index !== undefined && generalDetails(orderLineItem, index)}
                    </TabPanel>
                    <TabPanel value='1' sx={{ padding: '0px', display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}>
                        {orderLineItem && index !== undefined && dispatchDetails(orderLineItem, index)}
                    </TabPanel>
                </TabContext>
            </Box>
        );
    }, [dispatchDetails, generalDetails, selectedTab, checkForDispatchErrors, checkForLineItemGeneralErrors]);

    if (equipmentTypesLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexGrow: 1, height: '100%' }}>
            <SelectableList
                items={orderLineItems}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                hasError={checkLineItemForErrors}
                getKey={(item) => item.id}
                getDesc={(item) => `${item.orderLineItemNumber} ${item.description}`}
                style={{ border: 1, borderColor: 'divider', minWidth: 240, bgcolor: 'background.paper', overflowY: 'auto', height: '100%' }}
                addItem={!disabled ? () => { addOrderReviewLineItem?.(); } : undefined}
                disableAdd={() => disabled}
                removeItem={(_item, index) => { removeOrderReviewLineItem(index); }}
                disableRemove={(item) => item.lineItemId !== undefined || disabled}
            />
            {details(selectedIndex !== undefined ? orderLineItems[selectedIndex] : undefined, selectedIndex)}
        </Box>
    );
};