import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { EquipmentTypeDto, DumpLineItemDto, QuoteDto, DumpRateDto } from '../../../../../dtos';
import { QuoteType, UnitOfMeasure, emptyGuid, unitsOfMeasure } from '../../../../../util';
import {
    IFormProps,
    IFormFieldValidationConfig,
    isNotBlank,
    runFormValidation,
    runFieldValidation,
    isGreaterThanOrEqualTo,
    isShorterThanMaxLength,
} from '../../../../CoreLib/library';
import { SelectChangeEvent } from '@mui/material';

export interface IDumpLineItemFormValues {
    dumpRateId: string;
    quoteId: string;
    equipmentTypeId: string;
    dumpType?: string;
    cost?: number;
    rate?: number;
    quantity?: number;
    unitOfMeasure: string;
    newHaulingLineItemRate?: number;
    newHaulingLineItemDriverRate?: number;
    newHaulingLineItemBrokerRate?: number;
    driverMemo?: string;
    isActive: boolean;
}

export const DEFAULT_DUMP_LINE_ITEM: DumpLineItemDto = {
    id: emptyGuid,
    siteId: undefined,
    site: undefined,
    equipmentTypeId: emptyGuid,
    dumpRateId: emptyGuid,
    quoteId: '',
    cost: 0,
    rate: 0,
    quantity: 0,
    unitOfMeasure: unitsOfMeasure[0],
    driverMemo: '',
    isActive: true,
    createdOn: new Date(),
};

export function useDumpLineItemForm(props: IFormProps<DumpLineItemDto>) {
    const { save, cancel, initValues } = props;
    const [formQuote, setFormQuote] = useState<QuoteDto>();
    const [formEquipmentType, setFormEquipmentType] = useState<EquipmentTypeDto>();
    const [formDumpRate, setFormDumpRate] = useState<DumpRateDto>();
    const [formCost, setFormCost] = useState<number>();
    const [formRate, setFormRate] = useState<number>();
    const [formQuantity, setFormQuantity] = useState<number | undefined>();
    const [formUnitOfMeasure, setFormUnitOfMeasure] = useState<UnitOfMeasure>('Tons');
    const [formDriverMemo, setFormDriverMemo] = useState<string | undefined>(initValues?.driverMemo);
    const [formIsActive, setFormIsActive] = useState(true);
    const [formDumpType, setFormDumpType] = useState('');

    const isFobQuote = useMemo(() => {
        return formQuote?.type === QuoteType.FOB;
    }, [formQuote?.type]);

    useEffect(() => {
        setFormQuote(initValues?.quote);
        setFormEquipmentType(initValues?.equipmentType);
        setFormDumpRate(initValues?.dumpRate);
        setFormCost(initValues?.cost);
        setFormRate(initValues?.rate);
        setFormQuantity(initValues?.quantity);
        setFormUnitOfMeasure((initValues?.unitOfMeasure as UnitOfMeasure) ?? 'Tons');
        setFormDumpType(initValues?.dumpRate?.type ?? '');
        setFormDriverMemo(initValues?.driverMemo);
        setFormIsActive(initValues?.isActive ?? true);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IDumpLineItemFormValues, string>>(
        new Map([
            ['quoteId', ''],
            ['equipmentTypeId', ''],
            ['cost', ''],
            ['rate', ''],
            ['quantity', ''],
            ['dumpType', ''],
            ['unitOfMeasure', ''],
            ['driverMemo', ''],
        ])
    );

    const formFieldValidators = useCallback(
        () =>
            new Map<keyof IDumpLineItemFormValues, IFormFieldValidationConfig>([
                [
                    'quoteId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Quote',
                    },
                ],
                [
                    'equipmentTypeId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Equipment Type',
                    },
                ],
                [
                    'cost',
                    {
                        validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                        errorMessageEntityName: 'Cost',
                    },
                ],
                [
                    'rate',
                    {
                        validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                        errorMessageEntityName: 'Rate',
                    },
                ],
                [
                    'quantity',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Quantity',
                    },
                ],
                [
                    'dumpType',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Dump Type',
                    },
                ],
                [
                    'unitOfMeasure',
                    {
                        validators: [isNotBlank, isShorterThanMaxLength(20)],
                        errorMessageEntityName: 'Unit Of Measure',
                    },
                ],
            ]),
        []
    );

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IDumpLineItemFormValues>>(formValues, formFieldValidators());
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): IDumpLineItemFormValues => {
        return {
            quoteId: formQuote?.id ?? emptyGuid,
            equipmentTypeId: formEquipmentType?.id ?? emptyGuid,
            dumpRateId: formDumpRate?.id ?? emptyGuid,
            rate: formRate,
            quantity: formQuantity ?? 1,
            unitOfMeasure: formUnitOfMeasure ?? '',
            cost: formCost,
            dumpType: formDumpType,
            driverMemo: formDriverMemo,
            isActive: formIsActive,
        };
    };

    const validateField = (fieldName: keyof IDumpLineItemFormValues) => {
        const validationConfig = formFieldValidators().get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formQuote?.id !== initValues?.quote?.id;
        isDirty = isDirty || formEquipmentType?.id !== initValues?.equipmentType?.id;
        isDirty = isDirty || formRate !== initValues?.rate;
        isDirty = isDirty || formCost !== initValues?.cost;
        isDirty = isDirty || formQuantity !== initValues?.quantity;
        isDirty = isDirty || formUnitOfMeasure !== initValues?.unitOfMeasure;
        isDirty = isDirty || formDumpRate?.id !== initValues?.dumpRate?.id;
        isDirty = isDirty || formDumpType !== initValues?.dumpRate?.type;
        isDirty = isDirty || formDriverMemo !== initValues?.driverMemo;

        return isDirty;
    };

    const handleQuoteChange = (quote: QuoteDto) => {
        setFormQuote(quote);
    };

    const handleEquipmentTypeChange = (equipmentType?: EquipmentTypeDto) => {
        setFormEquipmentType(equipmentType);
    };

    const handleDumpRateChange = (dumpRate?: DumpRateDto) => {
        setFormDumpRate(dumpRate);
        setFormCost(dumpRate?.cost);
        setFormRate(dumpRate?.rate);
        setFormUnitOfMeasure((dumpRate?.unitOfMeasure as UnitOfMeasure) ?? 'Tons');
    };

    const handleCostChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormCost(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleDriverMemoChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDriverMemo(event.target.value ?? '');
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    };

    const handleDumpTypeChange = (event: SelectChangeEvent<string>) => {
        setFormDumpType(event.target.value);
        setFormDumpRate(undefined);
        setFormCost(undefined);
        setFormRate(undefined);
    };

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormQuantity(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_DUMP_LINE_ITEM),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleEquipmentTypeChange,
        handleCostChange,
        handleRateChange,
        handleQuantityChange,
        handleIsActiveChange,
        handleDumpTypeChange,
        handleDumpRateChange,
        handleDriverMemoChange,
        formQuote,
        formEquipmentType,
        formDumpRate,
        formCost,
        formRate,
        formQuantity,
        formUnitOfMeasure,
        formIsActive,
        formDumpType,
        formDriverMemo,
        validateField,
        isFobQuote,
        initValues,
    };
}
