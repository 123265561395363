import { FC, useCallback, useMemo } from 'react';
import { LineItemDto, QuoteDto } from '../../dtos';
import { Grid, IconButton, TableCell } from '@mui/material';
import { AuthenticatedComponent, DataTableColumn, StandardListViewV2 } from '../../Components/CoreLib/library';
import { formatCurrency, LineItemTypes, QuoteType } from '../../util';
import { useGetLineItemQuoteSummaryLineItemsQuery } from '../../store/generated/generatedApi';
import { AddCircle } from '@mui/icons-material';

export interface IQuoteLineItemsOrderViewProps {
    quote: QuoteDto;
    copyQuoteLineItemToOrder: (lineItem: LineItemDto) => void;
    disableCopy?: boolean;
    maxHeight?: string; // should have a value of 'px' or 'vh'
}

export const QuoteLineItemsOrderView: FC<IQuoteLineItemsOrderViewProps> = (props) => {
    const { quote, copyQuoteLineItemToOrder, disableCopy, maxHeight } = props;

    const renderEditMenuButton = useCallback(
        (row: LineItemDto) => {
            const isFOBandHaulingLineItem = row.type === LineItemTypes.SiteHauling && quote?.type === QuoteType.FOB;

            return (
                <AuthenticatedComponent
                    requiredPermissions={['delete:lineItem']}
                    children={
                        <TableCell padding='none' sx={{ border: 'none' }}>
                            <IconButton
                                size='small'
                                color='primary'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    copyQuoteLineItemToOrder(row);
                                }}
                                disabled={disableCopy || isFOBandHaulingLineItem}>
                                <AddCircle />
                            </IconButton>
                        </TableCell>
                    }
                />
            );
        },
        [copyQuoteLineItemToOrder, disableCopy, quote?.type]
    );

    const tableColumns: DataTableColumn<LineItemDto>[] = useMemo(() => {
        const getMaterialPriceValue = (row: LineItemDto) => {
            if (row.isBilledToCustomer) {
                return 'BTC';
            }
            return row.rate !== null ? formatCurrency(row.rate) : '';
        };
        let columns = [
            { key: 'description', label: 'Description', sortKey: 'DESCRIPTION', unsorted: true, fieldMapper: (row) => row.description },
            { key: 'site', label: 'Site', sortKey: 'SITE', unsorted: true, fieldMapper: (row) => row.site?.name ?? '' },
            { key: 'type', label: 'Type', sortKey: 'TYPE', unsorted: true },
            { key: 'region', label: 'Region', sortKey: 'REGION', unsorted: true },
            { key: 'equipmentType', label: 'Equipment Type', sortKey: 'EQUIPMENT_TYPE', unsorted: true, fieldMapper: (row) => row.equipmentType?.type },
            { key: 'unitOfMeasure', label: 'Units', sortKey: 'UNIT', align: 'right', unsorted: true },
            {
                key: 'driverRate',
                label: 'Driver Rate',
                sortKey: 'DRIVER_RATE',
                align: 'right',
                unsorted: true,
                fieldMapper: (row) => (row.driverRate !== null ? formatCurrency(row.driverRate) : ''),
            },
            {
                key: 'brokerRate',
                label: 'Broker Rate',
                sortKey: 'BROKER_RATE',
                align: 'right',
                unsorted: true,
                fieldMapper: (row) => (row.brokerRate !== null ? formatCurrency(row.brokerRate) : ''),
            },
            {
                key: 'rate',
                label: 'Material Rate',
                sortKey: 'MATERIAL_PRICE_VALUE',
                unsorted: true,
                align: 'right',
                fieldMapper: (row) => getMaterialPriceValue(row),
            },
            {
                key: 'additionalRate',
                label: 'Additional Rate',
                sortKey: 'ADDITIONAL_RATE',
                unsorted: true,
                align: 'right',
                fieldMapper: (row) => (row.additionalRate !== null ? formatCurrency(row.additionalRate) : ''),
            },
            {
                key: 'haulingRate',
                label: 'Hauling Rate',
                sortKey: 'HAULING_RATE',
                unsorted: true,
                align: 'right',
                fieldMapper: (row) => (row.haulingRate !== null ? formatCurrency(row.haulingRate) : ''),
            },
            {
                key: 'quoteRate',
                label: 'Quote Rate',
                sortKey: 'QUOTE_RATE',
                unsorted: true,
                align: 'right',
                fieldMapper: (row) => (row.quoteRate !== null ? formatCurrency(row.quoteRate) : ''),
            },
            {
                key: '',
                label: '',
                sortKey: '',
                unsorted: true,
                fieldMapper: (row) => (row.type !== '' ? renderEditMenuButton(row) : <div style={{ padding: '16px' }}></div>),
            },
        ] as DataTableColumn<LineItemDto>[];

        return columns;
    }, [renderEditMenuButton]);

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle={''}
                    breadCrumbs={[]}
                    isPaginationVisible={false}
                    getDataQuery={useGetLineItemQuoteSummaryLineItemsQuery}
                    additionalQueryParameters={quote ? { quoteId: quote?.id, isOrderView: true } : undefined}
                    isShowActiveToggleVisible={false}
                    tableColumns={tableColumns}
                    entityNameSingular='Quote'
                    editIcon={<AddCircle />}
                    permissionName='quote'
                    hideSearch
                    disableSelection
                    hideMenu
                    hideAddButtonDivider
                    stickyTableHeader={!!maxHeight}
                    maxTableHeight={maxHeight}
                />
            </Grid>
        </Grid>
    );
};
