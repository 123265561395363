import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { OrderDto } from '../../dtos';
import {
    useArchiveOrderBulkDeleteMutation,
    useGetOrderQuery,
    useOrderBulkArchiveMutation,
    useOrderBulkUnarchiveMutation,
} from '../../store/generated/generatedApi';
import { format } from 'date-fns';

export interface IOrderReviewOrdersListViewProps {
    date: Date;
}

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'ORDER_NUMBER',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const OrderReviewOrdersListView: FC<IOrderReviewOrdersListViewProps> = (props) => {
    const { date } = props;
    const navigate = useNavigate();

    const tableColumns: DataTableColumn<OrderDto>[] = useMemo(() => [
        { key: 'customerName', label: 'Customer', sortKey: 'CUSTOMER', fieldMapper: (row) => (row.job ? `${row.job?.customerName}` : '') },
        { key: 'orderNumber', label: 'Order #', sortKey: 'ORDER_NUMBER' },
        { key: 'jobNumber', label: 'Job #', sortKey: 'JOB_NUMBER', fieldMapper: (row) => (row.job ? `${row.job?.jobNumber}` : '') },
        { key: 'orderLineItemCount', label: 'Line Items Count', sortKey: 'LINE_ITEM_COUNT' }
    ], []);

    const handleTableEdit = useCallback((id: string, record: OrderDto) => {
        navigate(`/job/${record.jobId}/order/${id}/orderReview/create`);
    }, [navigate]);

    return (
        <StandardListViewV2
            headerIcon={<></>}
            headerTitle=''
            breadCrumbs={[]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetOrderQuery}
            isShowActiveToggleVisible
            inactivateMutation={useOrderBulkArchiveMutation}
            activateMutation={useOrderBulkUnarchiveMutation}
            deleteMutation={useArchiveOrderBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='Order'
            permissionName='review'
            handleEdit={handleTableEdit}
            additionalQueryParameters={{ inReview: false, orderDate: format(date, "yyyy-MM-dd") }}
        />
    );
};
