import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { OrderReviewDto } from '../../dtos';
import {
    useArchiveOrderReviewBulkDeleteFromDatabaseMutation,
    useCreateOrderReviewPdfsMutation,
    useGetOrderReviewQuery,
} from '../../store/generated/generatedApi';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { exportToZip } from '../../util';
import { Download } from '@mui/icons-material';

export interface IOrderReviewsListViewProps {
    date: Date;
    readyForBilling: boolean;
    sentToQuickBooks: boolean;
}

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'ORDER_NUMBER',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const OrderReviewListView: FC<IOrderReviewsListViewProps> = (props) => {
    const {
        date,
        readyForBilling,
        sentToQuickBooks
    } = props;
    const navigate = useNavigate();

    const [downloadZip, { isLoading: downloadingFile }] = useCreateOrderReviewPdfsMutation();

    const tableColumns: DataTableColumn<OrderReviewDto>[] = useMemo(() => [
        { key: 'customerName', label: 'Customer', sortKey: 'CUSTOMER', fieldMapper: (row) => (row.job ? `${row.job?.customerName}` : '') },
        { key: 'orderNumber', label: 'Order #', sortKey: 'ORDER_NUMBER' },
        { key: 'jobNumber', label: 'Job #', sortKey: 'JOB_NUMBER', fieldMapper: (row) => (row.job ? `${row.job?.jobNumber}` : '') },
        { key: 'orderLineItemCount', label: 'Line Items Count', sortKey: 'LINE_ITEM_COUNT' },
        { key: 'status', label: 'Order Status', sortKey: 'STATUS', fieldMapper: (row) => (row.statusName) }
    ], []);

    const handleTableEdit = useCallback((id: string, record: OrderReviewDto) => {
        navigate(`/job/${record.jobId}/order/${record.orderId}/orderReview/${id}`);
    }, [navigate]);

    const handleDownloadZipFile = useCallback((selectedItems: OrderReviewDto[]) => {
        if (selectedItems.length > 0) {
            const selectedIds = selectedItems.map(x => x.id);
            downloadZip(selectedIds).then((response: any) => {
                if (response.data) {
                    exportToZip(response.data, `Invoices-${selectedItems[0].statusName}-${format(new Date(), 'yyyy-MM-dd-HH-mm')}.zip`.replace(/\s+/g, ''));
                }
            })
        }
    }, [downloadZip]);

    return (
        <>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle=''
                breadCrumbs={[]}
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetOrderReviewQuery}
                isShowActiveToggleVisible={false}
                deleteMutation={useArchiveOrderReviewBulkDeleteFromDatabaseMutation}
                tableColumns={tableColumns}
                entityNameSingular='OrderReview'
                permissionName='review'
                handleEdit={handleTableEdit}
                additionalQueryParameters={{ sentToQuickBooks, readyForBilling, orderDate: format(date, "yyyy-MM-dd") }}
                disableDeleteForRecord={(record: OrderReviewDto) => record.sentToQuickBooks!}
                deleteConfirmationDialog={{ title: 'Delete Order Review?', content: <Typography>Are you sure you would like to permanently delete this record? It will be deleted immediately and cannot be recovered.</Typography> }}
                additionalActionItems={[
                    {
                        text: 'Download Invoice',
                        onClick: (selectedItems: OrderReviewDto[]) => {
                            handleDownloadZipFile(selectedItems);
                        },
                        icon: <Download />,
                        enabledCheck: (selected: OrderReviewDto[]) => selected.length > 0 && !downloadingFile,
                        clearSelectedItems: true,
                    },
                ]}
            />
        </>
    );
};
