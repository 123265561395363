import { FC, useCallback } from "react";
import { useGetContactQuery } from "../../store/generated/generatedApi";
import { FormControl, FormLabel, Grid, FormHelperText } from "@mui/material";
import { ContactDto } from "../../dtos";
import { emptyGuid } from "../../util";
import { ContactQuickAddButton } from "../QuickAdds";
import { IEntityAutocomplete } from "./IEntityAutocomplete";

export interface IContactSelectProps {
    label?: string;
    selectedContactId: string;
    handleSelectedContactChange: (selectedContact?: ContactDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    customerId?: string;
}

export const ContactSelect: FC<IContactSelectProps> = props => {
    const { label = 'Contact', customerId, selectedContactId, handleSelectedContactChange, required, errorMessage, disabled } = props;
    const { data: contacts, isLoading: isLoadingContacts } = useGetContactQuery({
        customerId,
        sortKey: 'CONTACT_NAME',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false
    });

    const handleSelectChange = useCallback((value: ContactDto | null) => {
        handleSelectedContactChange(value ?? undefined);
    }, [handleSelectedContactChange]);

    const getSelectedContact = useCallback(() => {
        if (selectedContactId === '' || selectedContactId === emptyGuid) {
            return null;
        }
        return contacts?.pageResults.find(x => x.id === selectedContactId) ?? null;
    }, [contacts?.pageResults, selectedContactId]);

    const handleNewContactAdded = useCallback(
        (newContact: ContactDto) => {
            handleSelectChange(newContact);
        },
        [handleSelectChange]
    );

    return (
        <Grid item container alignItems='center'>
            <Grid item xs={11}>
                <FormControl fullWidth required={required} disabled={disabled}>
                    <FormLabel>{label}</FormLabel>
                    <IEntityAutocomplete
                        options={contacts?.pageResults}
                        onChange={(e, value) => { handleSelectChange(value ?? null); }}
                        value={getSelectedContact()}
                        getOptionLabel={(option: ContactDto) =>
                            option.firstName +
                            (option.lastName ? ' ' + option.lastName : '') +
                            (option.title ? ' - ' + option.title : '') +
                            (option.cellPhone ? ' - ' + option.cellPhone : '')
                        }
                        disabled={disabled}
                        isLoading={isLoadingContacts}
                    />
                    <FormHelperText>{errorMessage}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={1} pt={1}>
                <ContactQuickAddButton
                    onAdd={handleNewContactAdded}
                    customerId={customerId}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    )
}