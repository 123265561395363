import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { DispatchReviewDto, FreightBillingLineItemDto, JobDto, OrderByType, OrderDto, OrderReviewDto, OrderReviewLineItemDto } from '../../../dtos';
import { AssignmentType } from '../../../dtos/generated/AssignmentType';
import { ReviewStatus } from '../../../dtos/generated/ReviewStatus';
import { useCreateBillingRatesFreightLineItemBillingRatesMutation } from '../../../store/generated/generatedApi';
import { emptyGuid, LineItemTypes } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, createValidatorConfig, isNotBlank, runFormValidation } from '../../CoreLib/library';
import { useGetOrderQuery } from '../../../store/generated/generatedApi';

export interface IOrderReviewFormValues {
    orderNumber?: number;
    orderDate: Date;
    clientId?: string;
    jobId: string;
    job?: JobDto;
    orderId?: string;
    order?: OrderDto;
    orderLineItems: OrderReviewLineItemDto[];
    isActive: boolean;
    memo: string;
    poNumber: string;
    isTaxable?: boolean;
}

export const DEFAULT_ORDER_REVIEW: OrderReviewDto = {
    id: emptyGuid,
    clientId: emptyGuid,
    jobId: emptyGuid,
    orderId: emptyGuid,
    orderNumber: undefined,
    orderDate: new Date(),
    isActive: true,
    createdOn: new Date(),
    orderLineItems: [],
    memo: '',
    status: ReviewStatus.InReview,
    statusName: 'In Review',
    readyForBilling: false,
    sentToQuickBooks: false,
    poNumber: '',
    isTaxable: undefined,
};

function buildDispatchValidationConfig(dispatch: DispatchReviewDto): Map<keyof DispatchReviewDto, IFormFieldValidationConfig> {
    var validationConfig = new Map<keyof DispatchReviewDto, IFormFieldValidationConfig>();
    if (dispatch.assignmentType === AssignmentType.Broker) {
        validationConfig.set('brokerId', createValidatorConfig([isNotBlank], 'Broker'));
    } else {
        validationConfig.set('driverId', createValidatorConfig([isNotBlank], 'Driver'));
        validationConfig.set('equipmentId', createValidatorConfig([isNotBlank], 'Equipment'));
    }
    validationConfig.set('startDate', createValidatorConfig([isNotBlank], 'Start Date'));
    validationConfig.set('endDate', createValidatorConfig([isNotBlank], 'End Date'));
    return validationConfig;
}

function buildFreightBillingValidationConfig(freightBilling: FreightBillingLineItemDto): Map<keyof FreightBillingLineItemDto, IFormFieldValidationConfig> {
    var validationConfig = new Map<keyof FreightBillingLineItemDto, IFormFieldValidationConfig>();
    validationConfig.set('freightBillNumber', createValidatorConfig([isNotBlank], 'Freight Bill #'));
    if (freightBilling.lineItemType === LineItemTypes.Material || freightBilling.lineItemType === LineItemTypes.Dump) {
        validationConfig.set('quantity', createValidatorConfig([isNotBlank], 'Quantity'));
    }
    return validationConfig;
}

export function useTicketEntryForm(props: IFormProps<OrderReviewDto>) {
    const { save, cancel } = props;

    const [getBillingRates] = useCreateBillingRatesFreightLineItemBillingRatesMutation();
    const [isChanged, setIsChanged] = useState(false);

    const [formDispatchReviews, setFormDispatchReviews] = useState<DispatchReviewDto[]>([]);

    const [dispatchErrors, setDispatchErrors] = useState<Map<number, Map<keyof DispatchReviewDto, string>>>(new Map());
    const [freightErrors, setFreightErrors] = useState<Map<number, Map<number, Map<keyof FreightBillingLineItemDto, string>>>>(new Map());

    const { isLoading: isLoadingOrders, data: orderData } = useGetOrderQuery({
        searchText: '',
        sortKey: '',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });

    const getOrderById = useCallback(
        (orderId: string): OrderDto | undefined => {
            return orderData?.pageResults.find((order) => order.id === orderId);
        },
        [orderData?.pageResults]
    );

    const getOrderReviewsFromDispatchReviews = useCallback(
        (dispatchReviews: DispatchReviewDto[]): OrderReviewDto[] => {
            const orderReviewSet = new Set<OrderReviewDto>();

            dispatchReviews.forEach((dispatchReview) => {
                const orderId = dispatchReview?.orderLineItem?.orderReview?.orderId;
                const order = getOrderById(orderId ?? '');

                if (order && order.orderReview) {
                    const orderReview = order.orderReview;

                    const lineItemIndex = orderReview.orderLineItems?.findIndex((item) => item.lineItemId === dispatchReview.orderLineItem?.lineItemId);
                    if (lineItemIndex !== undefined && lineItemIndex !== -1) {
                        const lineItem = orderReview.orderLineItems[lineItemIndex];

                        const updatedLineItem = {
                            ...lineItem,
                            dispatches: (() => {
                                const dispatches = lineItem.dispatches || [];
                                const updatedDispatches = dispatches.map((dispatch) => (dispatch.id === dispatchReview.id ? dispatchReview : dispatch));

                                return updatedDispatches;
                            })(),
                        };

                        const updatedOrderLineItems = [
                            ...orderReview.orderLineItems.slice(0, lineItemIndex),
                            updatedLineItem,
                            ...orderReview.orderLineItems.slice(lineItemIndex + 1),
                        ];

                        const updatedOrderReview = {
                            ...orderReview,
                            orderLineItems: updatedOrderLineItems,
                        };

                        orderReviewSet.add(updatedOrderReview);
                    }
                }
            });

            return Array.from(orderReviewSet);
        },
        [getOrderById]
    );

    const validateForm = useCallback(() => {
        var isValid = true;
        var dispatchValidationErrors: Map<number, Map<keyof DispatchReviewDto, string>> = new Map();
        var freightBillingValidationErrors: Map<number, Map<number, Map<keyof FreightBillingLineItemDto, string>>> = new Map();

        formDispatchReviews.forEach((dispatch, dispatchIdx) => {
            const dispatchValidationConfig = buildDispatchValidationConfig(dispatch);
            const { isValid: isDispatchValid, errorMessages: dispatchErrorMessages } = runFormValidation<DispatchReviewDto>(dispatch, dispatchValidationConfig);
            !isDispatchValid && (isValid = false);

            var lineItemDispatchErrors: Map<keyof DispatchReviewDto, string> = new Map(dispatchErrorMessages);
            var dispatchFreightBillingValidationErrors: Map<number, Map<keyof FreightBillingLineItemDto, string>> = new Map();

            dispatch.freightBillingLineItems?.forEach((freightBillingLineItem, freightBillingIdx) => {
                const freightBillingValidationConfig = buildFreightBillingValidationConfig(freightBillingLineItem);
                const { isValid: isFreightBillingValid, errorMessages: freightBillingErrorMessages } = runFormValidation<FreightBillingLineItemDto>(
                    freightBillingLineItem,
                    freightBillingValidationConfig
                );
                !isFreightBillingValid && (isValid = false);

                const freightBillingErrorsMap: Map<keyof FreightBillingLineItemDto, string> = new Map(freightBillingErrorMessages);
                dispatchFreightBillingValidationErrors.set(freightBillingIdx, freightBillingErrorsMap);
            });

            dispatchValidationErrors.set(dispatchIdx, lineItemDispatchErrors);
            freightBillingValidationErrors.set(dispatchIdx, dispatchFreightBillingValidationErrors);
        });

        setDispatchErrors(dispatchValidationErrors);
        setFreightErrors(freightBillingValidationErrors);

        return isValid;
    }, [formDispatchReviews, setDispatchErrors, setFreightErrors]);

    const calculateBillingRates = useCallback(
        (billingRates: FreightBillingLineItemDto): Promise<FreightBillingLineItemDto> => {
            return getBillingRates(billingRates).then((response) => {
                return (response as any).data as FreightBillingLineItemDto;
            });
        },
        [getBillingRates]
    );

    const handleDispatchesChange = useCallback((dispatches: DispatchReviewDto[]) => {
        setFormDispatchReviews(dispatches);
        setIsChanged(true);
    }, []);

    const handleAddNewFreightBillingLineItem = useCallback(
        async (dispatchIndex: number) => {
            var updatedDispatches = _.cloneDeep(formDispatchReviews);
            var lineItem = updatedDispatches[dispatchIndex].orderLineItem;
            let freightBillingLineItem = {
                ...lineItem?.billingRates,
                id: emptyGuid,
                isActive: true,
                createdOn: new Date(),
            };

            if (lineItem?.orderBy === OrderByType.Quantity) {
                freightBillingLineItem.quantity = lineItem?.loadOrQuantityReq;
            }

            freightBillingLineItem = await calculateBillingRates(freightBillingLineItem);

            updatedDispatches[dispatchIndex].freightBillingLineItems!.push(freightBillingLineItem);
            setFormDispatchReviews(updatedDispatches);
            setIsChanged(true);
        },
        [calculateBillingRates, formDispatchReviews]
    );

    const handleRemoveFreightBillingLineItem = useCallback(
        (dispatchIndex: number, freightBillingLineItemIndex: number) => {
            var updatedDispatches = _.cloneDeep(formDispatchReviews);
            updatedDispatches[dispatchIndex].freightBillingLineItems!.splice(freightBillingLineItemIndex, 1);
            setFormDispatchReviews(updatedDispatches);
            setIsChanged(true);
        },
        [formDispatchReviews]
    );

    const isFormDirty = useCallback(() => {
        return isChanged;
    }, [isChanged]);

    const isFormValid = useMemo(() => {
        return validateForm();
    }, [validateForm]);

    const handleSave = useCallback(() => {
        if (isFormValid) {
            const updatedOrderReviews = getOrderReviewsFromDispatchReviews(formDispatchReviews);
            for (const orderReview of updatedOrderReviews) {
                save({ ...orderReview, sentToQuickBooks: undefined });
            }
            setIsChanged(false);
        }
    }, [isFormValid, getOrderReviewsFromDispatchReviews, formDispatchReviews, save]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        isFormValid,
        handleSave,
        handleCancel,
        dispatchErrors,
        freightErrors,
        handleAddNewFreightBillingLineItem,
        handleRemoveFreightBillingLineItem,
        handleDispatchesChange,
        setFormDispatchReviews,
        formDispatchReviews,
        getOrderById,
        isLoadingOrders,
    };
}

export function isErrorInMap(errors: Map<string, string> | undefined): boolean {
    if (!errors) {
        return false;
    }
    return Array.from(errors.values()).some((x) => x !== '');
}
