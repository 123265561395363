import { AttachMoney } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { OrderDto, QuoteDto, SiteHaulingLineItemDto } from '../../../../../dtos';
import {
    useArchiveSiteHaulingLineItemDeleteFromDatabaseByIdMutation,
    useGetLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItemsQuery
} from '../../../../../store/generated/generatedApi';
import { LineItemStatus, LineItemTypes, emptyGuid } from '../../../../../util';
import { EquipmentTypeSelect, SiteSelect, UnitOfMeasureSelect } from '../../../../CommonInputs';
import { SlimFormHeader } from '../../../../Core/SlimFormHeader';
import { FormInput, FormSection, IEntityAutocomplete, IFormProps, LoadingIndicator } from '../../../../CoreLib/library';
import { RateInputForm } from '../RateInputForm';
import { useSiteHaulingLineItemForm } from './useSiteHaulingLineItemForm';

export interface ISiteHaulingLineItemFormProps extends IFormProps<SiteHaulingLineItemDto> {
    quote?: QuoteDto;
    order?: OrderDto;
}

export const SiteHaulingLineItemForm: FC<ISiteHaulingLineItemFormProps> = (props) => {
    const { isLoading, quote, order } = props;
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleSiteChange,
        handleEquipmentTypeChange,
        handleRateChange,
        handleDriverRateChange,
        handleBrokerRateChange,
        handleUnitOfMeasureChange,
        handleMinimumFlatRateChange,
        handleDriverMinimumFlatRateChange,
        handleBrokerMinimumFlatRateChange,
        handleRateToggleChange,
        handleZoneChange,
        formQuote,
        formSite,
        formEquipmentType,
        formHaulingRate,
        formDriverRate,
        formBrokerRate,
        formUnitOfMeasure,
        formIsActive,
        formMinimumFlatRate,
        formDriverMinimumFlatRate,
        formBrokerMinimumFlatRate,
        formRateToggle,
        formZone,
        validateField,
        initValues,
    } = useSiteHaulingLineItemForm(props);

    const isLocked = useMemo(
        () => initValues?.id !== emptyGuid && !!quote?.acceptedOn && initValues?.status !== LineItemStatus.Amended,
        [initValues?.id, initValues?.status, quote?.acceptedOn]
    );

    const {
        data: canDeleteData,
        error: canDeleteError,
        isLoading: canDeleteLoading,
    } = useGetLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItemsQuery({ id: props.initValues?.id ?? '', lineItemType: LineItemTypes.SiteHauling });

    const [canDelete, setCanDelete] = useState(true);
    useEffect(() => {
        if (canDeleteError) {
            if ((canDeleteError as any).status === 404) {
                setCanDelete(true);
            }
        }
        if (canDeleteData && canDeleteData === true) {
            setCanDelete(false);
        }
    }, [canDeleteData, canDeleteError]);

    useEffect(() => {
        if (quote && !formQuote) {
            handleQuoteChange(quote);
        }
    }, [formQuote, handleQuoteChange, quote]);

    const currentSiteHaulingLineItemId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    const rateInputs = useCallback(() => {
        return (
            <RateInputForm
                haulingRate={formHaulingRate}
                setHaulingRate={handleRateChange}
                haulingRateFieldName='haulingRate'
                minimumFlatRate={formMinimumFlatRate}
                setMinimumFlatRate={handleMinimumFlatRateChange}
                minimumFlatRateFieldName='haulingFlatRate'
                driverRate={formDriverRate}
                setDriverRate={handleDriverRateChange}
                driverRateFieldName='driverRate'
                driverFlatRate={formDriverMinimumFlatRate}
                setDriverFlatRate={handleDriverMinimumFlatRateChange}
                driverFlatRateFieldName='driverFlatRate'
                brokerRate={formBrokerRate}
                setBrokerRate={handleBrokerRateChange}
                brokerRateFieldName='brokerRate'
                brokerFlatRate={formBrokerMinimumFlatRate}
                setBrokerFlatRate={handleBrokerMinimumFlatRateChange}
                brokerFlatRateFieldName='brokerFlatRate'
                rateToggle={formRateToggle}
                setRateToggle={handleRateToggleChange}
                fieldErrors={fieldErrors}
                disabled={isLocked}
            />
        );
    }, [
        fieldErrors,
        formDriverMinimumFlatRate,
        formDriverRate,
        formHaulingRate,
        formMinimumFlatRate,
        formRateToggle,
        formBrokerMinimumFlatRate,
        formBrokerRate,
        handleDriverMinimumFlatRateChange,
        handleDriverRateChange,
        handleMinimumFlatRateChange,
        handleRateChange,
        handleRateToggleChange,
        handleBrokerMinimumFlatRateChange,
        handleBrokerRateChange,
        isLocked,
    ]);

    if (isLoading || canDeleteLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName='Haul Rate Line Item'
                permissionsTypeName={'lineItem'}
                icon={<AttachMoney />}
                title={formSite ? `Hauling to site ${formSite.code}` : 'New Haul Rate Line Item'}
                breadcrumbs={
                    order
                        ? [
                            { label: 'Home', navLink: '/' },
                            { label: 'Jobs', navLink: '/jobs' },
                            { label: `Job #${quote?.quoteNumber}`, navLink: `/job/${order?.jobId}` },
                            { label: `Order #${order?.orderNumber}`, navLink: `/job/${order?.jobId}/order/${order?.id}` },
                        ]
                        : [
                            { label: 'Home', navLink: '/' },
                            { label: 'Quotes', navLink: '/quotes' },
                            { label: `${quote?.customer?.name ?? ''} (Quote #${quote?.quoteNumber ?? 'Quote'})`, navLink: `/quote/${quote?.id}` },
                            { label: `Line Items`, navLink: `/quote/${quote?.id}/lineItems` },
                        ]
                }
                isActive={formIsActive}
                id={currentSiteHaulingLineItemId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'haul rate line item'}
                canDelete={canDelete && !isLocked}
                canSave={!isLocked}
                deleteMutation={useArchiveSiteHaulingLineItemDeleteFromDatabaseByIdMutation}
            />
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl error={!!fieldErrors.get('quoteId')} fullWidth required>
                                <FormLabel>Quote</FormLabel>
                                <IEntityAutocomplete
                                    options={[quote]}
                                    onChange={(e, value) => {
                                        handleQuoteChange(value ?? null);
                                    }}
                                    value={formQuote}
                                    getOptionLabel={(option: QuoteDto) => `${option.quoteNumber}`}
                                    disabled
                                    error={!!fieldErrors.get('quoteId')}
                                />
                                <FormHelperText>{fieldErrors.get('quoteId')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EquipmentTypeSelect
                                selectedEquipmentTypeId={formEquipmentType?.id ?? ''}
                                handleSelectedEquipmentTypeChange={handleEquipmentTypeChange}
                                required
                                errorMessage={fieldErrors.get('equipmentTypeId')}
                                disabled={!canDelete || isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <SiteSelect
                                selectedSiteId={formSite?.id ?? ''}
                                handleSelectedSiteChange={handleSiteChange}
                                required
                                errorMessage={fieldErrors.get('siteId')}
                                disabled={!canDelete || isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <UnitOfMeasureSelect
                                selectedUnit={formUnitOfMeasure ?? ''}
                                handleSelectedUnitChange={handleUnitOfMeasureChange}
                                required
                                errorMessage={fieldErrors.get('unitOfMeasure')}
                                onBlur={() => validateField('unitOfMeasure')}
                                disabled={!canDelete || isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormInput value={formZone} onChange={(event) => handleZoneChange(event.target.value)} label='Zone' fullWidth disabled={isLocked} />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item>
                            <Typography variant='h2'>Rates</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item sm={12} md={6}>
                            {rateInputs()}
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
