import { CheckCircle, DownloadForOffline, RateReview, Send } from '@mui/icons-material';
import {
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Link,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrompt } from '../../../Views';
import { ContactDto, OrderReviewDto } from '../../../dtos';
import { ReviewStatus } from '../../../dtos/generated/ReviewStatus';
import { useArchiveOrderReviewDeleteFromDatabaseByIdMutation, useCreateOrderReviewPdfMutation } from '../../../store/generated/generatedApi';
import { DatePicker } from '../../CommonInputs';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import {
    FormInput,
    IEntityAutocomplete,
    IFormProps,
    UserPermissionContext,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
    userHasPermissions,
} from '../../CoreLib/library';
import { OrderReviewLineItemTable } from './OrderReviewLineItemTable';
import { useOrderReviewForm } from './useOrderReviewForm';
import { InvoiceHistoryButton } from '../../../Views/OrderReview/InvoiceHistoryButton';

export interface IOrderReviewFormProps extends IFormProps<OrderReviewDto> {
    jobId: string;
    orderId: string;
}

export const OrderReviewForm: FC<IOrderReviewFormProps> = (props) => {
    const { isLoading, initValues, jobId, orderId } = props;
    const navigate = useNavigate();
    const { permissions } = useContext(UserPermissionContext);
    const isUserAllowedToReview = userHasPermissions(['create:review', 'edit:review'], permissions, 'or');
    const isUserAllowedToBill = userHasPermissions(['create:billing', 'edit:billing'], permissions, 'or');
    const {
        isFormDirty,
        isFormValid,
        handleSave,
        handleSubmit,
        handleCancel,
        baseFormErrors,
        lineItemErrors,
        dispatchErrors,
        freightErrors,
        handleOrderDateChange,
        handleOrderLineItemsChange,
        handleAddNewDispatchReview,
        handleAddNewOrderReviewLineItem,
        handleAddNewFreightBillingLineItem,
        handleRemoveDispatchReview,
        handleRemoveOrderReviewLineItem,
        handleRemoveFreightBillingLineItem,
        handleMemoChange,
        handleIsTaxableChange,
        setFormPoNumber,
        formIsActive,
        formOrderNumber,
        formOrderDate,
        formOrderLineItems,
        formMemo,
        formStatus,
        formPoNumber,
        formIsTaxable,
        order,
    } = useOrderReviewForm(props, jobId, orderId);

    const [getInvoicePdf, { isSuccess, isError, error: pdfGenerationError, isLoading: pdfLoading, reset: resetCreatePDf }] = useCreateOrderReviewPdfMutation();
    useSuccessfulActionSnackbar('created', 'quote PDF', isSuccess, resetCreatePDf);
    useFailedActionSnackbar('create', 'quote PDF', isError, resetCreatePDf, pdfGenerationError);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const currentOrderReviewId = useMemo(() => {
        return initValues?.id;
    }, [initValues?.id]);

    const generatePdf = useCallback(() => {
        if (!pdfLoading && currentOrderReviewId) {
            getInvoicePdf({ id: currentOrderReviewId }).then((response: any) => {
                if (response?.data) {
                    window.open(response?.data.url, '_blank');
                }
            });
        }
    }, [currentOrderReviewId, getInvoicePdf, pdfLoading]);

    const editDisabled = useMemo(() => {
        if (pdfLoading || isLoading) {
            return true;
        }

        if (formStatus === ReviewStatus.Completed) {
            return true;
        }
        return false;
    }, [formStatus, isLoading, pdfLoading]);

    const saveDisabled = useMemo(() => {
        if (pdfLoading || isLoading) {
            return true;
        }
        if (formStatus === ReviewStatus.Completed && !isUserAllowedToBill) {
            return true;
        }

        return false;
    }, [formStatus, isLoading, isUserAllowedToBill, pdfLoading]);

    const canSubmit = useMemo(() => {
        if (!isFormValid || isLoading) {
            return false;
        }
        if (isUserAllowedToBill) {
            if (!initValues?.readyForBilling) {
                return true;
            }
            const freightBillingLineItems = formOrderLineItems.flatMap((lineItem) =>
                lineItem.dispatches.flatMap((dispatch) => dispatch.freightBillingLineItems)
            );

            if (
                initValues?.readyForBilling &&
                (initValues.invoiceStatus === 'Awaiting Review' || freightBillingLineItems.some((lineItem) => !lineItem!.invoiceId))
            ) {
                return true;
            } else {
                return false;
            }
        }
        if (isUserAllowedToReview) {
            if (!initValues?.readyForBilling && formStatus === ReviewStatus.InReview) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }, [
        isFormValid,
        isLoading,
        isUserAllowedToBill,
        isUserAllowedToReview,
        initValues?.readyForBilling,
        initValues?.invoiceStatus,
        formOrderLineItems,
        formStatus,
    ]);

    const submit = useCallback(() => {
        handleSubmit(initValues?.readyForBilling ?? false);
    }, [handleSubmit, initValues?.readyForBilling]);

    const currentQuote = useMemo(() => {
        return order?.quote ?? initValues?.job?.currentQuote;
    }, [initValues?.job?.currentQuote, order?.quote]);

    return (
        <Box display='flex' flexDirection='column' height='100%' overflow='hidden'>
            <SlimFormHeader
                objectName='Order'
                permissionsTypeName='order'
                icon={<RateReview />}
                title={`Reviewing Order #${formOrderNumber ?? ' ###'}`} // TODO: update core lib to allow title to be distinct from the last breadcrumb.
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    {
                        label: 'Review',
                        navLink: `/orderReview?date=${format(initValues?.orderDate ? new Date(initValues?.orderDate) : new Date(), 'yyyy-MM-dd')} `,
                    },
                ]}
                isActive={formIsActive}
                id={currentOrderReviewId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                canSave={!saveDisabled && isFormValid}
                canDelete={!editDisabled}
                entityNameSingular={'order'}
                hideDividers
                deleteConfirmationDialog={{
                    title: 'Delete Order Review?',
                    content: (
                        <Typography>
                            Are you sure you would like to permanently delete this record? It will be deleted immediately and cannot be recovered.
                        </Typography>
                    ),
                }}
                deleteMutation={useArchiveOrderReviewDeleteFromDatabaseByIdMutation}
                submit={{
                    handleSubmit: () => submit(),
                    canSubmit: canSubmit,
                    submitText: !initValues?.readyForBilling ? 'Send to Billing' : 'Send to QuickBooks',
                    icon: !initValues?.readyForBilling ? <CheckCircle /> : <Send />,
                }}
                additionalHeaderItems={
                    <>
                        <Tooltip title='Download PDF'>
                            {!pdfLoading ? (
                                <Box>
                                    <IconButton onClick={generatePdf} disabled={!initValues || pdfLoading}>
                                        <DownloadForOffline />
                                    </IconButton>
                                </Box>
                            ) : (
                                <CircularProgress />
                            )}
                        </Tooltip>
                        <InvoiceHistoryButton orderReviewId={initValues?.id} size='small' />
                    </>
                }
            />
            <Paper sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', flexDirection: 'column', p: 2, m: 2 }}>
                <Grid container direction='row' spacing={2}>
                    <Grid item container direction='row' xs={12} sm={6} md={4} alignItems='center' justifyContent='space-evenly'>
                        <Grid item>
                            <Typography>
                                Quote{' '}
                                <Link
                                    href={`/quote/${currentQuote?.id} `}
                                    variant='body2'
                                    onClick={() => {
                                        navigate(`/quote/${currentQuote?.id} `);
                                    }}>
                                    #{currentQuote?.quoteNumber}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Job{' '}
                                <Link
                                    href={`/job/${initValues?.jobId ?? jobId} `}
                                    variant='body2'
                                    onClick={() => {
                                        navigate(`/job/${initValues?.jobId ?? jobId} `);
                                    }}>
                                    #{initValues?.job?.jobNumber ?? order?.job?.jobNumber}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Order{' '}
                                <Link
                                    href={`/job/${initValues?.jobId ?? jobId}/order/${initValues?.orderId ?? orderId} `}
                                    variant='body2'
                                    onClick={() => {
                                        navigate(`/job/${initValues?.jobId ?? jobId}/order/${initValues?.orderId ?? orderId} `);
                                    }}>
                                    #{initValues?.orderNumber ?? order?.orderNumber}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Chip color='secondary' label={initValues?.statusName ?? 'Awaiting Review'} />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={6} md={5} spacing={2}>
                        <Grid item xs={4}>
                            <DatePicker
                                label='Order Date'
                                value={formOrderDate}
                                onChange={handleOrderDateChange}
                                disabled
                                error={!!baseFormErrors.get('orderDate')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth disabled>
                                <FormLabel>Ordered By</FormLabel>
                                <IEntityAutocomplete
                                    options={[]}
                                    disabled
                                    value={order?.orderedByContact}
                                    getOptionLabel={(option: ContactDto) =>
                                        option.firstName +
                                        (option.lastName ? ' ' + option.lastName : '') +
                                        (option.title ? ' - ' + option.title : '') +
                                        (option.cellPhone ? ' - ' + option.cellPhone : '')
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth disabled>
                                <FormLabel>Contact</FormLabel>
                                <IEntityAutocomplete
                                    options={[]}
                                    disabled
                                    value={order?.contact}
                                    getOptionLabel={(option: ContactDto) =>
                                        option.firstName +
                                        (option.lastName ? ' ' + option.lastName : '') +
                                        (option.title ? ' - ' + option.title : '') +
                                        (option.cellPhone ? ' - ' + option.cellPhone : '')
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <FormInput value={formPoNumber} onChange={(event) => setFormPoNumber(event.target.value)} label='PO Number' fullWidth />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} alignItems='center'>
                        <Grid item xs={12} sm={3} md={1}>
                            <FormControl fullWidth disabled={editDisabled}>
                                <FormControlLabel
                                    control={<Checkbox disabled={editDisabled} checked={formIsTaxable} onChange={handleIsTaxableChange} />}
                                    label='Is Taxable'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={9} md={11}>
                            <FormInput value={formMemo} onChange={handleMemoChange} label='Memo' fullWidth maxLength={300} disabled={editDisabled} />
                        </Grid>
                    </Grid>
                </Grid>
                <Typography variant='h2' mt={1}>
                    Order Line Items
                </Typography>
                <Box flexGrow={1} overflow='hidden'>
                    {order && (
                        <OrderReviewLineItemTable
                            disabled={editDisabled}
                            orderLineItems={formOrderLineItems}
                            order={order}
                            addDispatchReview={handleAddNewDispatchReview}
                            removeDispatchReview={handleRemoveDispatchReview}
                            setOrderLineItems={handleOrderLineItemsChange}
                            removeOrderReviewLineItem={handleRemoveOrderReviewLineItem}
                            addFreightBillingLineItem={handleAddNewFreightBillingLineItem}
                            removeFreightBillingLineItem={handleRemoveFreightBillingLineItem}
                            addOrderReviewLineItem={
                                !editDisabled
                                    ? () => {
                                        handleAddNewOrderReviewLineItem();
                                    }
                                    : undefined
                            }
                            lineItemErrors={lineItemErrors}
                            dispatchErrors={dispatchErrors}
                            freightErrors={freightErrors}
                        />
                    )}
                </Box>
            </Paper>
        </Box>
    );
};
