import { Place } from '@mui/icons-material';
import { FormControl, FormLabel, Grid, debounce } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { BrokerDto, EquipmentDto } from '../../../dtos';
import { useArchiveEquipmentDeleteByIdMutation, useGetBrokerQuery } from '../../../store/generated/generatedApi';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { IFormProps, LoadingIndicator, FormSection, FormInput, IEntityAutocomplete } from '../../CoreLib/library';
import { useEquipmentForm } from './useEquipmentForm';
import { EquipmentTypeSelect } from '../../CommonInputs';
import { usePrompt } from '../../../Views';

export const EquipmentForm: FC<IFormProps<EquipmentDto>> = (props) => {
	const { isLoading } = props;
	const {
		isFormDirty,
		handleSave,
		handleCancel,
		fieldErrors,
		handleNumberChange,
		handleEquipmentTypeChange,
		handleIsActiveChange,
		formNumber,
		formEquipmentType,
		formIsActive,
		formBroker,
		setFormBroker,
	} = useEquipmentForm(props);

	usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

	const { data: brokers } = useGetBrokerQuery({ searchText: '', sortKey: 'CODE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });

	const currentNumber = useMemo(() => {
		return props.initValues?.number;
	}, [props.initValues?.number]);

	const debouncedHandleNumberChange = useCallback(
		() => debounce((event: React.ChangeEvent<HTMLInputElement>) => {
			handleNumberChange(event);
		}, 300)
		, [handleNumberChange]);

	if (isLoading) {
		return <LoadingIndicator />;
	}

	return (
		<Grid container direction='column' spacing={3}>
			<SlimFormHeader
				objectName='Equipment'
				permissionsTypeName={'equipment'}
				icon={<Place />}
				title={formNumber ? formNumber : 'New Equipment'}
				breadcrumbs={[
					{ label: 'Home', navLink: '/' },
					{ label: 'Data Management', navLink: '/dataManagement' },
					{ label: 'Equipment', navLink: '/dataManagement/equipment' },
				]}
				isActive={formIsActive}
				handleIsActiveChange={handleIsActiveChange}
				id={currentNumber}
				isFormDirty={isFormDirty}
				handleCancel={handleCancel}
				handleSave={handleSave}
				entityNameSingular={'equipment'}
				deleteMutation={useArchiveEquipmentDeleteByIdMutation}
			/>
			<Grid item mx={2}>
				<FormSection>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormInput
								value={formNumber}
								onChange={(event) => {
									debouncedHandleNumberChange()(event);
								}}
								label='Number'
								name='number'
								errorText={fieldErrors.get('number')}
								fullWidth
								required
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<EquipmentTypeSelect
								selectedEquipmentTypeId={formEquipmentType?.id ?? ''}
								handleSelectedEquipmentTypeChange={handleEquipmentTypeChange}
								required
								errorMessage={fieldErrors.get('equipmentTypeId')}
							/>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<FormLabel>Owned by Broker</FormLabel>
								<IEntityAutocomplete
									options={brokers?.pageResults ?? []}
									onChange={(e, value) => {
										setFormBroker(value);
									}}
									value={formBroker ?? null}
									getOptionLabel={(option: BrokerDto) => `${option.code} - ${option.name}`}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</FormSection>
			</Grid>
		</Grid>
	);
};
