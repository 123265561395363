import { FormControl, FormLabel, Grid, OutlinedInput, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmDialog } from "../../Components/Core/ConfirmDialog";
import { ControlPointDuplicate, Info } from "@mui/icons-material";
import { DatePicker } from "../../Components/CommonInputs";
import { add } from "date-fns";

export interface ICloneQuoteDialog {
    onClose: () => void;
    onConfirm: (effectiveOn: Date, priceIncreasePercentage?: number) => void;
    open: boolean;
    quoteEffectiveOn: Date;
}

export const CloneQuoteDialog: FC<ICloneQuoteDialog> = (props) => {
    const { onClose, onConfirm, open, quoteEffectiveOn } = props;
    const [effectiveOn, setEffectiveOn] = useState<Date | null | undefined>();
    const [priceIncreasePercentage, setPriceIncreasePercentage] = useState<number>();

    const handleOnConfirm = useCallback(() => {
        if (effectiveOn && !isNaN(Date.parse(effectiveOn.toString()))) {
            onConfirm(effectiveOn, priceIncreasePercentage);
            setEffectiveOn(undefined);
            setPriceIncreasePercentage(undefined);
        }
    }, [effectiveOn, onConfirm, priceIncreasePercentage]);

    const minEffectiveDate = useMemo(() => {
        if (quoteEffectiveOn) {
            const effectiveOn = new Date(quoteEffectiveOn);
            return add(effectiveOn, { days: 1 });
        }

        return undefined;

    }, [quoteEffectiveOn]);

    useEffect(() => {
        if (!effectiveOn) {
            setEffectiveOn(minEffectiveDate);
        }
    }, [effectiveOn, minEffectiveDate]);

    return (
        <ConfirmDialog
            open={open}
            onClose={() => {
                setEffectiveOn(undefined);
                setPriceIncreasePercentage(undefined);
                onClose();
            }}
            content={
                <Grid item container direction='column' spacing={2}>
                    <Grid item>
                        <Typography>Please enter the following data to initialize the new Quote.</Typography>
                    </Grid>
                    <Grid item container direction='row' spacing={2} justifyContent='space-between'>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormLabel required>Effective On</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='The date the new quote will become effective on.'><Info /></Tooltip>
                                    </Grid>
                                </Grid>
                                <DatePicker
                                    value={effectiveOn ?? minEffectiveDate ?? null}
                                    onChange={(date) => setEffectiveOn(date)}
                                    minDate={minEffectiveDate}
                                    error={!(effectiveOn || minEffectiveDate)}
                                    errorText={!(effectiveOn || minEffectiveDate) ? 'Effective On Date is required.' : ''}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormLabel>Price Increase Percentage (%)</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='All Line Item rates (except Material Line Items using standard pricing) will be increased using this percentage.'><Info /></Tooltip>
                                    </Grid>
                                </Grid>
                                <OutlinedInput
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setPriceIncreasePercentage(event.target.valueAsNumber);
                                        }
                                    }
                                    type={'number'}
                                    required
                                    placeholder="10"
                                    value={priceIncreasePercentage ?? ''}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            }
            title={'Clone Quote'}
            titleIcon={<ControlPointDuplicate />}
            onConfirm={handleOnConfirm}
            confirmText='Confirm'
            requireConfirmation={false}
        />
    );
}