import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent } from 'react';
import { unitsOfMeasure } from '../../util';

export interface IUnitOfMeasureSelectProps {
    selectedUnit: string;
    handleSelectedUnitChange: (selectedUnit?: string) => void;
    required?: boolean;
    errorMessage?: string;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    disabled?: boolean;
    label?: string;

}

export const UnitOfMeasureSelect: FC<IUnitOfMeasureSelectProps> = props => {
    const { selectedUnit, handleSelectedUnitChange, required, errorMessage, onBlur, disabled, label = 'Unit of Measure' } = props;

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: string | null) => {
        handleSelectedUnitChange(value ?? '');
    }

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                value={selectedUnit}
                onChange={handleSelectChange}
                options={unitsOfMeasure}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
                sx={{ backgroundColor: 'white', borderRadius: '4px' }}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}