import { FC, useCallback, useMemo } from "react";
import { FreightBillingLineItemDto, LineItemBillingRatesDto, OrderLineItemDto, OrderReviewLineItemDto, RateToggle } from "../../../../dtos";
import { Edit, CheckCircleOutline } from "@mui/icons-material";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, Button, DialogActions, FormControl, OutlinedInput, FormLabel } from "@mui/material";
import { formatCurrency, LineItemTypes } from "../../../../util";
import { RateInputForm } from "../../QuoteForm/LineItemForms";
import { IBillingRateFormProps, useBillingRateForm } from "./useBillingRateForm";
import { FormInput, FormNumberInput } from "../../../CoreLib/library";
import { PricingMethodTypeSelect } from "../../../CommonInputs";

export interface IBillingRateFormDialog extends IBillingRateFormProps {
    onClose: () => void;
    onConfirm: (freightBillingLineItem: FreightBillingLineItemDto) => void;
    open: boolean;
    billingRates: FreightBillingLineItemDto | LineItemBillingRatesDto;
    lineItem: OrderReviewLineItemDto | OrderLineItemDto;
    isFob: boolean;
    disabled: boolean;
    isOrder: boolean;
}

export const BillingRateFormDialog: FC<IBillingRateFormDialog> = (props) => {
    const { onClose, onConfirm, open, lineItem, isFob, isOrder, disabled } = props;
    const {
        freightBillNumber,
        lineItemRate,
        additionalRate,
        haulingRate,
        flatHaulingRate,
        driverRate,
        flatDriverRate,
        brokerRate,
        flatBrokerRate,
        rateToggle,
        quoteRate,
        salesPrice,
        flatRateApplied,
        quantity,
        getCurrentFormValues,
        handleFormValueChange,
        fieldErrors,
        isValid,
        haulingTotalFlatRate,
        haulingTotalRate,
        isMaterialLineItemType,
        unitOfMeasure,
        pricingMethodType
    } = useBillingRateForm(props);

    const onCloseHandler = useCallback(() => {
        onClose();
    }, [onClose]);

    const onConfirmHandler = useCallback(() => {
        onConfirm(getCurrentFormValues());
        onClose();
    }, [getCurrentFormValues, onClose, onConfirm]);

    const haulingFormInputs = useMemo(() => {
        return (
            <RateInputForm
                haulingRate={haulingRate}
                setHaulingRate={(value) => handleFormValueChange('haulingRate', value)}
                haulingRateFieldName='haulingRate'
                minimumFlatRate={flatHaulingRate}
                setMinimumFlatRate={(value) => handleFormValueChange('flatHaulingRate', value)}
                minimumFlatRateFieldName='flatHaulingRate'
                driverRate={driverRate}
                setDriverRate={(value) => handleFormValueChange('driverRate', value)}
                driverRateFieldName='driverRate'
                driverFlatRate={flatDriverRate}
                setDriverFlatRate={(value) => handleFormValueChange('flatDriverRate', value)}
                driverFlatRateFieldName='flatDriverRate'
                brokerRate={brokerRate}
                setBrokerRate={(value) => handleFormValueChange('brokerRate', value)}
                brokerRateFieldName='brokerRate'
                brokerFlatRate={flatBrokerRate}
                setBrokerFlatRate={(value) => handleFormValueChange('flatBrokerRate', value)}
                brokerFlatRateFieldName='flatBrokerRate'
                rateToggle={rateToggle ?? RateToggle.Standard}
                setRateToggle={(value) => handleFormValueChange('rateToggle', value)}
                fieldErrors={fieldErrors}
                disabled={disabled}
            />
        );
    }, [disabled, driverRate, fieldErrors, flatDriverRate, flatHaulingRate, handleFormValueChange, haulingRate, rateToggle, brokerRate, flatBrokerRate]);

    const materialLineItemForm = useMemo(() => {
        return (
            <>
                <Grid item container spacing={1}>
                    <Grid item container direction='column' xs={6}>
                        <Grid item>
                            <Typography variant='h3'>Material</Typography>
                        </Grid>
                        <Grid item>
                            <FormNumberInput
                                value={lineItemRate}
                                onChange={(e) => handleFormValueChange('lineItemRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                                label='Material Rate'
                                name='lineItemRate'
                                errorText={fieldErrors.get('lineItemRate')}
                                error={!!fieldErrors.get('lineItemRate')}
                                fullWidth
                                required
                                inputProps={{ min: 0 }}
                                isCurrency
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item>
                            <FormNumberInput
                                value={additionalRate}
                                onChange={(e) => handleFormValueChange('additionalRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                                label='Additional Rate'
                                name='additionalRate'
                                errorText={fieldErrors.get('additionalRate')}
                                error={!!fieldErrors.get('additionalRate')}
                                fullWidth
                                inputProps={{ min: 0 }}
                                isCurrency
                                required
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='column' spacing={2} xs={6}>
                        <Grid item>
                            <Typography variant='h3'>Hauling</Typography>
                        </Grid>
                        <Grid item>{haulingFormInputs}</Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={1}>
                    <Grid item xs={6}>
                        <FormInput
                            value={formatCurrency((additionalRate ?? 0) + (lineItemRate ?? 0))}
                            onChange={() => { }}
                            label='Material Total Rate'
                            name='totalRate'
                            fullWidth
                            disabled
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                    {rateToggle !== RateToggle.Flat && (
                        <Grid item xs={rateToggle === RateToggle.Both ? 3 : 6}>
                            <FormControl fullWidth disabled>
                                <FormLabel>Hauling Total Rate</FormLabel>
                                <OutlinedInput
                                    value={formatCurrency(haulingTotalRate)}
                                    disabled
                                    inputProps={{ min: 0 }}
                                    endAdornment={!flatRateApplied ? <CheckCircleOutline color='error' /> : undefined}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {rateToggle !== RateToggle.Standard && (
                        <Grid item xs={rateToggle === RateToggle.Both ? 3 : 6}>
                            <FormControl fullWidth disabled>
                                <FormLabel>Hauling Total Rate (Flat)</FormLabel>
                                <OutlinedInput
                                    value={formatCurrency(haulingTotalFlatRate)}
                                    disabled
                                    inputProps={{ min: 0 }}
                                    endAdornment={flatRateApplied ? <CheckCircleOutline color='error' /> : undefined}
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    }, [
        lineItemRate,
        fieldErrors,
        disabled,
        additionalRate,
        haulingFormInputs,
        rateToggle,
        haulingTotalRate,
        flatRateApplied,
        haulingTotalFlatRate,
        handleFormValueChange,
    ]);

    const materialFOBLineItemForm = useMemo(() => {
        return (
            <Grid item container direction='column'>
                <Grid item>
                    <Typography variant='h3'>Material FOB</Typography>
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={lineItemRate}
                        onChange={(e) => handleFormValueChange('lineItemRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                        label='Material Rate'
                        name='lineItemRate'
                        errorText={fieldErrors.get('lineItemRate')}
                        error={!!fieldErrors.get('lineItemRate')}
                        fullWidth
                        required
                        inputProps={{ min: 0 }}
                        isCurrency
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={additionalRate}
                        onChange={(e) => handleFormValueChange('additionalRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                        label='Additional Rate'
                        name='additionalRate'
                        errorText={fieldErrors.get('additionalRate')}
                        error={!!fieldErrors.get('additionalRate')}
                        fullWidth
                        inputProps={{ min: 0 }}
                        isCurrency
                        required
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={(additionalRate ?? 0) + (lineItemRate ?? 0)}
                        onChange={() => { }}
                        label='Material Total Rate'
                        name='totalRate'
                        fullWidth
                        disabled
                        inputProps={{ min: 0 }}
                        isCurrency
                    />
                </Grid>
            </Grid>
        );
    }, [additionalRate, disabled, fieldErrors, handleFormValueChange, lineItemRate]);

    const hourlyLineItemForm = useMemo(() => {
        return (
            <Grid item container direction='column' spacing={2}>
                <Grid item>
                    <Typography variant='h3'>Hourly</Typography>
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={lineItemRate}
                        onChange={(e) => handleFormValueChange('lineItemRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                        label='Hourly Rate'
                        name='lineItemRate'
                        errorText={fieldErrors.get('lineItemRate')}
                        error={!!fieldErrors.get('lineItemRate')}
                        fullWidth
                        required
                        inputProps={{ min: 0 }}
                        isCurrency
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={driverRate}
                        onChange={(e) => handleFormValueChange('driverRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                        label='Driver Rate'
                        name='driverRate'
                        errorText={fieldErrors.get('driverRate')}
                        error={!!fieldErrors.get('driverRate')}
                        fullWidth
                        inputProps={{ min: 0 }}
                        isCurrency
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={brokerRate}
                        onChange={(e) => handleFormValueChange('brokerRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                        label='Broker Rate'
                        name='brokerRate'
                        errorText={fieldErrors.get('brokerRate')}
                        error={!!fieldErrors.get('brokerRate')}
                        fullWidth
                        inputProps={{ min: 0 }}
                        isCurrency
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        );
    }, [lineItemRate, fieldErrors, disabled, driverRate, brokerRate, handleFormValueChange]);

    const dumpLineItemForm = useMemo(() => {
        return (
            <Grid item container direction='column' spacing={2}>
                <Grid item>
                    <Typography variant='h3'>Dump</Typography>
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={lineItemRate}
                        onChange={(e) => handleFormValueChange('lineItemRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                        label='Dump Rate'
                        name='lineItemRate'
                        errorText={fieldErrors.get('lineItemRate')}
                        error={!!fieldErrors.get('lineItemRate')}
                        fullWidth
                        required
                        inputProps={{ min: 0 }}
                        isCurrency
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        );
    }, [disabled, fieldErrors, handleFormValueChange, lineItemRate]);

    const miscLineItemForm = useMemo(() => {
        return (
            <Grid item container direction='column' spacing={2}>
                <Grid item>
                    <Typography variant='h3'>Misc.</Typography>
                </Grid>
                <Grid item>
                    <FormNumberInput
                        value={lineItemRate}
                        onChange={(e) => handleFormValueChange('lineItemRate', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                        label='Misc Rate'
                        name='lineItemRate'
                        errorText={fieldErrors.get('lineItemRate')}
                        error={!!fieldErrors.get('lineItemRate')}
                        fullWidth
                        required
                        inputProps={{ min: 0 }}
                        isCurrency
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        );
    }, [disabled, fieldErrors, handleFormValueChange, lineItemRate]);

    const isStandardMaterialLineItem = useMemo(() => {
        return lineItem.lineItem?.type === LineItemTypes.Material && !isFob;
    }, [isFob, lineItem.lineItem?.type]);

    const isMaterialOrDumpLineItem = useMemo(() => {
        return lineItem.lineItem?.type === LineItemTypes.Material || lineItem.lineItem?.type === LineItemTypes.Dump;
    }, [lineItem.lineItem?.type]);

    const form = useMemo(() => {
        switch (lineItem.lineItem?.type) {
            case LineItemTypes.Material:
                if (isFob) {
                    return materialFOBLineItemForm;
                } else {
                    return materialLineItemForm;
                }
            case LineItemTypes.Hourly:
                return hourlyLineItemForm;
            case LineItemTypes.Dump:
                return dumpLineItemForm;
            case LineItemTypes.Misc:
            default:
                return miscLineItemForm;
        }
    }, [dumpLineItemForm, hourlyLineItemForm, isFob, lineItem.lineItem?.type, materialFOBLineItemForm, materialLineItemForm, miscLineItemForm]);

    const fieldSizeIfOrder = useMemo(() => {
        return !isOrder ? 2 : 3;
    }, [isOrder]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg' PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography variant='h2'>{<Edit />} Billing Rates</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column'>
                    <Grid item container spacing={2} pt={2}>
                        {form}
                    </Grid>
                    <Grid item container spacing={1} xs={12} wrap="nowrap" alignItems='center'>
                        {isStandardMaterialLineItem && <Grid item xs={2}>
                            <PricingMethodTypeSelect
                                selectedPricingMethodType={pricingMethodType}
                                onChange={(value) => handleFormValueChange('pricingMethodType', value)}
                                required
                                disabled={disabled}
                                errorMessage={fieldErrors.get('pricingMethodType')}
                            />
                        </Grid>}
                        {!isOrder && <Grid item xs={isStandardMaterialLineItem ? 2 : isMaterialOrDumpLineItem ? 3 : 4}>
                            <FormInput
                                label='Freight Bill #'
                                value={freightBillNumber}
                                onChange={(e) => handleFormValueChange('freightBillNumber', e.target.value)}
                                fullWidth
                                required
                                errorText={fieldErrors.get('freightBillNumber')}
                                disabled={disabled}
                            />
                        </Grid>}
                        <Grid item xs={isStandardMaterialLineItem ? fieldSizeIfOrder : isMaterialOrDumpLineItem ? 3 : 4}>
                            <FormNumberInput
                                label='Quantity'
                                name='quantity'
                                value={quantity}
                                onChange={(e) => handleFormValueChange('quantity', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                                fullWidth
                                required={isMaterialOrDumpLineItem}
                                inputProps={{ min: 0 }}
                                error={!!fieldErrors.get('quantity')}
                                errorText={fieldErrors.get('quantity')}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={isMaterialOrDumpLineItem ? fieldSizeIfOrder : 4}>
                            <FormInput
                                value={unitOfMeasure ?? ''}
                                onChange={() => { }}
                                label='Units'
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={isMaterialOrDumpLineItem ? fieldSizeIfOrder : 4}>
                            <FormInput
                                label={`Rate ${flatRateApplied && isMaterialLineItemType ? '(+ Flat)' : ''}`}
                                name='quoteRate'
                                value={formatCurrency(quoteRate)}
                                onChange={() => { }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={isMaterialOrDumpLineItem ? fieldSizeIfOrder : 4}>
                            <FormInput
                                label='Amount'
                                name='salesPrice'
                                value={formatCurrency(salesPrice)}
                                onChange={() => { }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'end'}>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={onCloseHandler}>
                            {'Cancel'}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            size='medium'
                            disabled={disabled}
                            onClick={() => {
                                if (isValid()) {
                                    onConfirmHandler();
                                }
                            }}
                            sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
