import { serverApi } from '../api';
import _ from 'lodash';
import {
	LineItemBillingRatesDto,
	FreightBillingLineItemDto,
	LineItemDto,
	BrokerDto,
	CanceledDispatchDto,
	ContactDto,
	CustomerDto,
	DispatchDto,
	BulkCancelItemDto,
	DocumentDto,
	DriverDto,
	DumpLineItemDto,
	DumpRateDto,
	EquipmentDto,
	EquipmentTypeDto,
	HourlyLineItemDto,
	IncomeCodeDto,
	JobDto,
	CloneQuoteDto,
	JobNoteDto,
	MaterialDto,
	MaterialLineItemDto,
	MaterialPriceDto,
	MiscLineItemDto,
	MiscSettingsDto,
	OrderDto,
	CancelItemDto,
	OrderLineItemDto,
	OrderReviewDto,
	PriceListDto,
	QuoteDto,
	RegionDto,
	SiteDto,
	SiteHaulingLineItemDto,
	SurchargeCodeDto,
	TermsCodeDto,
	TrashDto,
	UserDto,
	QueryResultsDto,
	BulkUpdateResultDto,
	OrderManagementViewDto,
	HaulerManagementViewDto,
	PersonalDispatchDto,
	InvoiceHistoryDto,
	PdfDto,
	PriceListLineItemDto,
	UserRoleDto
} from '../../dtos';

import {
	GetBrokerQueryParameters,
	GetBrokerByIdQueryParameters,
	ArchiveBrokerByIdQueryParameters,
	PatchBrokerByIdQueryParameters,
	GetBrokerCodeByIdQueryParameters,
	ArchiveBrokerDeleteByIdQueryParameters,
	ArchiveBrokerDeleteFromDatabaseByIdQueryParameters,
	ArchiveCanceledDispatchByIdQueryParameters,
	GetCanceledDispatchByIdQueryParameters,
	PatchCanceledDispatchByIdQueryParameters,
	GetCanceledDispatchQueryParameters,
	ArchiveCanceledDispatchDeleteByIdQueryParameters,
	ArchiveCanceledDispatchDeleteFromDatabaseByIdQueryParameters,
	GetContactQueryParameters,
	GetContactByIdQueryParameters,
	ArchiveContactByIdQueryParameters,
	PatchContactByIdQueryParameters,
	ArchiveContactDeleteByIdQueryParameters,
	ArchiveContactDeleteFromDatabaseByIdQueryParameters,
	GetCustomerQueryParameters,
	GetCustomerByIdQueryParameters,
	ArchiveCustomerByIdQueryParameters,
	PatchCustomerByIdQueryParameters,
	ArchiveCustomerDeleteByIdQueryParameters,
	ArchiveCustomerDeleteFromDatabaseByIdQueryParameters,
	GetDispatchQueryParameters,
	GetDispatchOrderManagementQueryParameters,
	GetDispatchOrderManagementCurrentMonthQueryParameters,
	GetDispatchHaulerQueryParameters,
	GetDispatchPersonalQueryParameters,
	PatchDispatchStartQueryParameters,
	PatchDispatchArriveQueryParameters,
	PatchDispatchDepartQueryParameters,
	PatchDispatchStopQueryParameters,
	GetDispatchByIdQueryParameters,
	ArchiveDispatchByIdQueryParameters,
	PatchDispatchByIdQueryParameters,
	ArchiveDispatchDeleteByIdQueryParameters,
	ArchiveDispatchDeleteFromDatabaseByIdQueryParameters,
	GetDocumentQueryParameters,
	GetDocumentByIdQueryParameters,
	ArchiveDocumentByIdQueryParameters,
	PatchDocumentByIdQueryParameters,
	ArchiveDocumentDeleteFromDatabaseByIdQueryParameters,
	GetDocumentFileQueryParameters,
	ArchiveDocumentDeleteByIdQueryParameters,
	GetDriverQueryParameters,
	GetDriverByIdQueryParameters,
	ArchiveDriverByIdQueryParameters,
	PatchDriverByIdQueryParameters,
	GetDriverCodeByIdQueryParameters,
	ArchiveDriverDeleteByIdQueryParameters,
	ArchiveDriverDeleteFromDatabaseByIdQueryParameters,
	GetDumpLineItemQueryParameters,
	GetDumpLineItemByIdQueryParameters,
	ArchiveDumpLineItemByIdQueryParameters,
	PatchDumpLineItemByIdQueryParameters,
	ArchiveDumpLineItemDeleteByIdQueryParameters,
	ArchiveDumpLineItemDeleteFromDatabaseByIdQueryParameters,
	GetDumpRateQueryParameters,
	GetDumpRateByIdQueryParameters,
	ArchiveDumpRateByIdQueryParameters,
	PatchDumpRateByIdQueryParameters,
	ArchiveDumpRateDeleteByIdQueryParameters,
	ArchiveDumpRateDeleteFromDatabaseByIdQueryParameters,
	GetEquipmentQueryParameters,
	GetEquipmentByIdQueryParameters,
	ArchiveEquipmentByIdQueryParameters,
	PatchEquipmentByIdQueryParameters,
	GetEquipmentNumberByIdQueryParameters,
	ArchiveEquipmentDeleteByIdQueryParameters,
	ArchiveEquipmentDeleteFromDatabaseByIdQueryParameters,
	GetEquipmentTypeQueryParameters,
	GetEquipmentTypeByIdQueryParameters,
	ArchiveEquipmentTypeByIdQueryParameters,
	PatchEquipmentTypeByIdQueryParameters,
	ArchiveEquipmentTypeDeleteByIdQueryParameters,
	ArchiveEquipmentTypeDeleteFromDatabaseByIdQueryParameters,
	GetHourlyLineItemQueryParameters,
	GetHourlyLineItemByIdQueryParameters,
	ArchiveHourlyLineItemByIdQueryParameters,
	PatchHourlyLineItemByIdQueryParameters,
	ArchiveHourlyLineItemDeleteByIdQueryParameters,
	ArchiveHourlyLineItemDeleteFromDatabaseByIdQueryParameters,
	GetIncomeCodeQueryParameters,
	GetIncomeCodeByIdQueryParameters,
	ArchiveIncomeCodeByIdQueryParameters,
	PatchIncomeCodeByIdQueryParameters,
	ArchiveIncomeCodeDeleteByIdQueryParameters,
	ArchiveIncomeCodeDeleteFromDatabaseByIdQueryParameters,
	GetInvoiceQueryParameters,
	GetInvoiceByIdQueryParameters,
	GetJobQueryParameters,
	GetJobByIdQueryParameters,
	ArchiveJobByIdQueryParameters,
	PatchJobByIdQueryParameters,
	CreateJobUpdateCurrentQuoteQueryParameters,
	ArchiveJobDeleteByIdQueryParameters,
	ArchiveJobDeleteFromDatabaseByIdQueryParameters,
	GetJobJobNoteQueryParameters,
	CreateJobJobNoteQueryParameters,
	UpdateJobJobNoteQueryParameters,
	GetJobJobNoteByIdQueryParameters,
	ArchiveJobJobNoteByIdQueryParameters,
	PatchJobJobNoteByIdQueryParameters,
	JobJobNoteBulkArchiveQueryParameters,
	JobJobNoteBulkUnarchiveQueryParameters,
	ArchiveJobJobNoteDeleteByIdQueryParameters,
	ArchiveJobJobNoteBulkDeleteQueryParameters,
	ArchiveJobJobNoteDeleteFromDatabaseByIdQueryParameters,
	ArchiveJobJobNoteBulkDeleteFromDatabaseQueryParameters,
	GetLineItemQueryParameters,
	GetLineItemByIdQueryParameters,
	ArchiveLineItemByIdQueryParameters,
	PatchLineItemByIdQueryParameters,
	GetLineItemQuoteSummaryLineItemsQueryParameters,
	GetLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItemsQueryParameters,
	ArchiveLineItemDeleteByIdQueryParameters,
	ArchiveLineItemDeleteFromDatabaseByIdQueryParameters,
	GetMaterialQueryParameters,
	GetMaterialByIdQueryParameters,
	ArchiveMaterialByIdQueryParameters,
	PatchMaterialByIdQueryParameters,
	ArchiveMaterialDeleteByIdQueryParameters,
	ArchiveMaterialDeleteFromDatabaseByIdQueryParameters,
	GetMaterialLineItemQueryParameters,
	GetMaterialLineItemByIdQueryParameters,
	ArchiveMaterialLineItemByIdQueryParameters,
	PatchMaterialLineItemByIdQueryParameters,
	ArchiveMaterialLineItemDeleteByIdQueryParameters,
	ArchiveMaterialLineItemDeleteFromDatabaseByIdQueryParameters,
	GetMaterialPriceQueryParameters,
	GetMaterialPriceByIdQueryParameters,
	ArchiveMaterialPriceByIdQueryParameters,
	PatchMaterialPriceByIdQueryParameters,
	ArchiveMaterialPriceDeleteByIdQueryParameters,
	ArchiveMaterialPriceDeleteFromDatabaseByIdQueryParameters,
	GetMiscLineItemQueryParameters,
	GetMiscLineItemByIdQueryParameters,
	ArchiveMiscLineItemByIdQueryParameters,
	PatchMiscLineItemByIdQueryParameters,
	ArchiveMiscLineItemDeleteByIdQueryParameters,
	ArchiveMiscLineItemDeleteFromDatabaseByIdQueryParameters,
	GetMiscSettingsQueryParameters,
	GetMiscSettingsByIdQueryParameters,
	ArchiveMiscSettingsByIdQueryParameters,
	PatchMiscSettingsByIdQueryParameters,
	ArchiveMiscSettingsDeleteByIdQueryParameters,
	ArchiveMiscSettingsDeleteFromDatabaseByIdQueryParameters,
	GetOrderQueryParameters,
	GetOrderByIdQueryParameters,
	ArchiveOrderByIdQueryParameters,
	PatchOrderByIdQueryParameters,
	GetOrderOrderReviewQueryParameters,
	CreateOrderQuickCreateByIdQueryParameters,
	PatchOrderRestoreByIdQueryParameters,
	ArchiveOrderDeleteByIdQueryParameters,
	ArchiveOrderDeleteFromDatabaseByIdQueryParameters,
	ArchiveOrderLineItemByIdQueryParameters,
	GetOrderLineItemByIdQueryParameters,
	PatchOrderLineItemByIdQueryParameters,
	PatchOrderLineItemRestoreByIdQueryParameters,
	ArchiveOrderLineItemDeleteByIdQueryParameters,
	ArchiveOrderLineItemDeleteFromDatabaseByIdQueryParameters,
	GetOrderReviewQueryParameters,
	GetOrderReviewByIdQueryParameters,
	ArchiveOrderReviewByIdQueryParameters,
	PatchOrderReviewByIdQueryParameters,
	GetOrderReviewCurrentMonthOrdersQueryParameters,
	CreateOrderReviewPdfQueryParameters,
	ArchiveOrderReviewDeleteByIdQueryParameters,
	ArchiveOrderReviewDeleteFromDatabaseByIdQueryParameters,
	GetPriceListSiteByIdQueryParameters,
	GetQuickBooksCallbackQueryParameters,
	GetQuoteQueryParameters,
	GetQuoteAffectedQueryParameters,
	GetQuoteByIdQueryParameters,
	ArchiveQuoteByIdQueryParameters,
	PatchQuoteByIdQueryParameters,
	CreateQuotePdfQueryParameters,
	UpdateQuoteAcceptQueryParameters,
	ArchiveQuoteDeleteByIdQueryParameters,
	ArchiveQuoteDeleteFromDatabaseByIdQueryParameters,
	GetRegionQueryParameters,
	GetRegionByIdQueryParameters,
	ArchiveRegionByIdQueryParameters,
	PatchRegionByIdQueryParameters,
	ArchiveRegionDeleteByIdQueryParameters,
	ArchiveRegionDeleteFromDatabaseByIdQueryParameters,
	GetSiteQueryParameters,
	GetSiteByIdQueryParameters,
	ArchiveSiteByIdQueryParameters,
	PatchSiteByIdQueryParameters,
	ArchiveSiteDeleteByIdQueryParameters,
	ArchiveSiteDeleteFromDatabaseByIdQueryParameters,
	GetSiteHaulingLineItemQueryParameters,
	GetSiteHaulingLineItemByIdQueryParameters,
	ArchiveSiteHaulingLineItemByIdQueryParameters,
	PatchSiteHaulingLineItemByIdQueryParameters,
	ArchiveSiteHaulingLineItemDeleteByIdQueryParameters,
	ArchiveSiteHaulingLineItemDeleteFromDatabaseByIdQueryParameters,
	GetSurchargeCodeQueryParameters,
	GetSurchargeCodeByIdQueryParameters,
	ArchiveSurchargeCodeByIdQueryParameters,
	PatchSurchargeCodeByIdQueryParameters,
	ArchiveSurchargeCodeDeleteByIdQueryParameters,
	ArchiveSurchargeCodeDeleteFromDatabaseByIdQueryParameters,
	GetTermsCodeQueryParameters,
	GetTermsCodeByIdQueryParameters,
	ArchiveTermsCodeByIdQueryParameters,
	PatchTermsCodeByIdQueryParameters,
	ArchiveTermsCodeDeleteByIdQueryParameters,
	ArchiveTermsCodeDeleteFromDatabaseByIdQueryParameters,
	GetTrashQueryParameters,
	GetTrashByIdQueryParameters,
	PatchTrashByIdQueryParameters,
	ArchiveTrashByIdQueryParameters,
	ArchiveTrashDeleteByIdQueryParameters,
	ArchiveTrashDeleteFromDatabaseByIdQueryParameters,
	GetUserQueryParameters,
	GetUserByIdQueryParameters,
	ArchiveUserByIdQueryParameters,
	PatchUserByIdQueryParameters,
	ArchiveUserDeleteFromDatabaseByIdQueryParameters,
	GetUserAuth0UserIdByIdQueryParameters,
	ArchiveUserDeleteByIdQueryParameters
} from './generatedParameterTypes';

const buildQueryString = (obj: any, routeParams: string[] = []) => {
	var args = _.omitBy(_.clone(obj), _.isNil);
	routeParams.forEach(rp => delete args[rp]);
	return new URLSearchParams(args).toString();
}

const generatedApi = serverApi.injectEndpoints({
	endpoints: (builder) => ({
		createBillingRatesLineItemBillingRates: builder.mutation<void, LineItemBillingRatesDto>({
			query: (args) => ({
				url: `/api/BillingRates/lineItemBillingRates`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['BillingRates']
		}),
		createBillingRatesFreightLineItemBillingRates: builder.mutation<void, FreightBillingLineItemDto>({
			query: (args) => ({
				url: `/api/BillingRates/freightLineItemBillingRates`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['BillingRates']
		}),
		createBillingRatesLineItem: builder.mutation<void, LineItemDto>({
			query: (args) => ({
				url: `/api/BillingRates/lineItem`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['BillingRates']
		}),
		getBroker: builder.query<QueryResultsDto<BrokerDto>, GetBrokerQueryParameters>({
			query: (args) => ({
				url: `/api/Broker?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Broker']
		}),
		createBroker: builder.mutation<BrokerDto, BrokerDto>({
			query: (args) => ({
				url: `/api/Broker`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		updateBroker: builder.mutation<BrokerDto, BrokerDto>({
			query: (args) => ({
				url: `/api/Broker`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		getBrokerById: builder.query<BrokerDto, GetBrokerByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Broker/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Broker']
		}),
		archiveBrokerById: builder.mutation<void, ArchiveBrokerByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Broker/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		patchBrokerById: builder.mutation<void, PatchBrokerByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Broker/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		getBrokerCodeById: builder.query<boolean, GetBrokerCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Broker/code/${args.code}`,
				method: 'GET',
			}),
			providesTags: ['Broker']
		}),
		brokerBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Broker/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		brokerBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Broker/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		archiveBrokerDeleteById: builder.mutation<void, ArchiveBrokerDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Broker/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		archiveBrokerBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Broker/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		archiveBrokerDeleteFromDatabaseById: builder.mutation<void, ArchiveBrokerDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Broker/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		archiveBrokerBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Broker/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Broker','Dispatch']
		}),
		archiveCanceledDispatchById: builder.mutation<void, ArchiveCanceledDispatchByIdQueryParameters>({
			query: (args) => ({
				url: `/api/CanceledDispatch/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		getCanceledDispatchById: builder.query<void, GetCanceledDispatchByIdQueryParameters>({
			query: (args) => ({
				url: `/api/CanceledDispatch/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['CanceledDispatch']
		}),
		patchCanceledDispatchById: builder.mutation<void, PatchCanceledDispatchByIdQueryParameters>({
			query: (args) => ({
				url: `/api/CanceledDispatch/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		canceledDispatchBulkArchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/CanceledDispatch/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		archiveCanceledDispatchBulkDelete: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/CanceledDispatch/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		archiveCanceledDispatchBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/CanceledDispatch/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		canceledDispatchBulkUnarchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/CanceledDispatch/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		createCanceledDispatch: builder.mutation<void, CanceledDispatchDto>({
			query: (args) => ({
				url: `/api/CanceledDispatch`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		getCanceledDispatch: builder.query<QueryResultsDto<CanceledDispatchDto>, GetCanceledDispatchQueryParameters>({
			query: (args) => ({
				url: `/api/CanceledDispatch?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['CanceledDispatch']
		}),
		updateCanceledDispatch: builder.mutation<void, CanceledDispatchDto>({
			query: (args) => ({
				url: `/api/CanceledDispatch`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		archiveCanceledDispatchDeleteById: builder.mutation<void, ArchiveCanceledDispatchDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/CanceledDispatch/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		archiveCanceledDispatchDeleteFromDatabaseById: builder.mutation<void, ArchiveCanceledDispatchDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/CanceledDispatch/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['CanceledDispatch']
		}),
		getCloudStorage: builder.query<void, void>({
			query: () => ({
				url: `/api/CloudStorage`,
				method: 'GET',
			}),
			providesTags: ['CloudStorage']
		}),
		getContact: builder.query<QueryResultsDto<ContactDto>, GetContactQueryParameters>({
			query: (args) => ({
				url: `/api/Contact?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Contact']
		}),
		createContact: builder.mutation<ContactDto, ContactDto>({
			query: (args) => ({
				url: `/api/Contact`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Contact']
		}),
		updateContact: builder.mutation<void, ContactDto>({
			query: (args) => ({
				url: `/api/Contact`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Contact']
		}),
		getContactById: builder.query<ContactDto, GetContactByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Contact/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Contact']
		}),
		archiveContactById: builder.mutation<void, ArchiveContactByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Contact/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Contact']
		}),
		patchContactById: builder.mutation<void, PatchContactByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Contact/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Contact']
		}),
		contactBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Contact/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Contact']
		}),
		contactBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Contact/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Contact']
		}),
		archiveContactDeleteById: builder.mutation<void, ArchiveContactDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Contact/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Contact']
		}),
		archiveContactBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Contact/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Contact']
		}),
		archiveContactDeleteFromDatabaseById: builder.mutation<void, ArchiveContactDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Contact/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Contact']
		}),
		archiveContactBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Contact/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Contact']
		}),
		getCustomer: builder.query<QueryResultsDto<CustomerDto>, GetCustomerQueryParameters>({
			query: (args) => ({
				url: `/api/Customer?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Customer']
		}),
		createCustomer: builder.mutation<CustomerDto, CustomerDto>({
			query: (args) => ({
				url: `/api/Customer`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Customer']
		}),
		updateCustomer: builder.mutation<void, CustomerDto>({
			query: (args) => ({
				url: `/api/Customer`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Customer']
		}),
		getCustomerById: builder.query<CustomerDto, GetCustomerByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Customer/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Customer']
		}),
		archiveCustomerById: builder.mutation<void, ArchiveCustomerByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Customer/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Customer']
		}),
		patchCustomerById: builder.mutation<void, PatchCustomerByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Customer/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Customer']
		}),
		customerBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Customer/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Customer']
		}),
		customerBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Customer/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Customer']
		}),
		archiveCustomerDeleteById: builder.mutation<void, ArchiveCustomerDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Customer/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Customer']
		}),
		archiveCustomerBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Customer/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Customer']
		}),
		archiveCustomerDeleteFromDatabaseById: builder.mutation<void, ArchiveCustomerDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Customer/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Customer']
		}),
		archiveCustomerBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Customer/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Customer']
		}),
		getDispatch: builder.query<QueryResultsDto<DispatchDto>, GetDispatchQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Dispatch']
		}),
		createDispatch: builder.mutation<DispatchDto, DispatchDto>({
			query: (args) => ({
				url: `/api/Dispatch`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		updateDispatch: builder.mutation<DispatchDto, DispatchDto>({
			query: (args) => ({
				url: `/api/Dispatch`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		getDispatchOrderManagement: builder.query<OrderManagementViewDto, GetDispatchOrderManagementQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/orderManagement?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Dispatch']
		}),
		getDispatchOrderManagementCurrentMonth: builder.query<string[], GetDispatchOrderManagementCurrentMonthQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/orderManagement/currentMonth?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Dispatch']
		}),
		getDispatchHauler: builder.query<HaulerManagementViewDto, GetDispatchHaulerQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/hauler?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Dispatch']
		}),
		getDispatchPersonal: builder.query<QueryResultsDto<PersonalDispatchDto>, GetDispatchPersonalQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/personal?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Dispatch']
		}),
		getDispatchActiveDispatch: builder.query<PersonalDispatchDto, void>({
			query: () => ({
				url: `/api/Dispatch/activeDispatch`,
				method: 'GET',
			}),
			providesTags: ['Dispatch']
		}),
		patchDispatchCancel: builder.mutation<BulkUpdateResultDto, BulkCancelItemDto>({
			query: (args) => ({
				url: `/api/Dispatch/cancel`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		patchDispatchRestore: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Dispatch/restore`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		patchDispatchStart: builder.mutation<void, PatchDispatchStartQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/${args.dispatchId}/Start`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		patchDispatchArrive: builder.mutation<void, PatchDispatchArriveQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/${args.dispatchId}/Arrive`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		patchDispatchDepart: builder.mutation<void, PatchDispatchDepartQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/${args.dispatchId}/Depart`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		patchDispatchStop: builder.mutation<void, PatchDispatchStopQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/${args.dispatchId}/Stop`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		getDispatchById: builder.query<DispatchDto, GetDispatchByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Dispatch']
		}),
		archiveDispatchById: builder.mutation<void, ArchiveDispatchByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		patchDispatchById: builder.mutation<void, PatchDispatchByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		dispatchBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Dispatch/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		dispatchBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Dispatch/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		archiveDispatchDeleteById: builder.mutation<void, ArchiveDispatchDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		archiveDispatchBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Dispatch/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		archiveDispatchDeleteFromDatabaseById: builder.mutation<void, ArchiveDispatchDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Dispatch/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		archiveDispatchBulkDeleteFromDatabase: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Dispatch/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		updateDispatchBulkRelease: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Dispatch/bulkRelease`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		updateDispatchBulkRecall: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Dispatch/bulkRecall`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Dispatch','Order','Driver','Broker','CanceledDispatch']
		}),
		getDocument: builder.query<void, GetDocumentQueryParameters>({
			query: (args) => ({
				url: `/api/Document?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Document']
		}),
		createDocument: builder.mutation<void, DocumentDto>({
			query: (args) => ({
				url: `/api/Document`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Document']
		}),
		updateDocument: builder.mutation<void, DocumentDto>({
			query: (args) => ({
				url: `/api/Document`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Document']
		}),
		getDocumentById: builder.query<void, GetDocumentByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Document/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Document']
		}),
		archiveDocumentById: builder.mutation<void, ArchiveDocumentByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Document/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Document']
		}),
		patchDocumentById: builder.mutation<void, PatchDocumentByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Document/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Document']
		}),
		documentBulkArchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Document/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Document']
		}),
		documentBulkUnarchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Document/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Document']
		}),
		archiveDocumentDeleteFromDatabaseById: builder.mutation<void, ArchiveDocumentDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Document/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Document']
		}),
		archiveDocumentBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Document/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Document']
		}),
		getDocumentFile: builder.query<void, GetDocumentFileQueryParameters>({
			query: (args) => ({
				url: `/api/Document/${args.id}/file`,
				method: 'GET',
			}),
			providesTags: ['Document']
		}),
		archiveDocumentDeleteById: builder.mutation<void, ArchiveDocumentDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Document/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Document']
		}),
		archiveDocumentBulkDelete: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Document/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Document']
		}),
		getDriver: builder.query<QueryResultsDto<DriverDto>, GetDriverQueryParameters>({
			query: (args) => ({
				url: `/api/Driver?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Driver']
		}),
		createDriver: builder.mutation<DriverDto, DriverDto>({
			query: (args) => ({
				url: `/api/Driver`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		updateDriver: builder.mutation<DriverDto, DriverDto>({
			query: (args) => ({
				url: `/api/Driver`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		getDriverById: builder.query<DriverDto, GetDriverByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Driver/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Driver']
		}),
		archiveDriverById: builder.mutation<void, ArchiveDriverByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Driver/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		patchDriverById: builder.mutation<void, PatchDriverByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Driver/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		getDriverCodeById: builder.query<boolean, GetDriverCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Driver/code/${args.code}`,
				method: 'GET',
			}),
			providesTags: ['Driver']
		}),
		driverBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Driver/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		driverBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Driver/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		archiveDriverDeleteById: builder.mutation<void, ArchiveDriverDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Driver/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		archiveDriverBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Driver/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		archiveDriverDeleteFromDatabaseById: builder.mutation<void, ArchiveDriverDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Driver/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		archiveDriverBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Driver/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Driver','Equipment','Dispatch']
		}),
		getDumpLineItem: builder.query<QueryResultsDto<DumpLineItemDto>, GetDumpLineItemQueryParameters>({
			query: (args) => ({
				url: `/api/DumpLineItem?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['DumpLineItem']
		}),
		createDumpLineItem: builder.mutation<DumpLineItemDto, DumpLineItemDto>({
			query: (args) => ({
				url: `/api/DumpLineItem`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		updateDumpLineItem: builder.mutation<void, DumpLineItemDto>({
			query: (args) => ({
				url: `/api/DumpLineItem`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		getDumpLineItemById: builder.query<DumpLineItemDto, GetDumpLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpLineItem/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['DumpLineItem']
		}),
		archiveDumpLineItemById: builder.mutation<void, ArchiveDumpLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpLineItem/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		patchDumpLineItemById: builder.mutation<void, PatchDumpLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpLineItem/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		dumpLineItemBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/DumpLineItem/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		dumpLineItemBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/DumpLineItem/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		archiveDumpLineItemDeleteById: builder.mutation<void, ArchiveDumpLineItemDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpLineItem/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		archiveDumpLineItemBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/DumpLineItem/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		archiveDumpLineItemDeleteFromDatabaseById: builder.mutation<void, ArchiveDumpLineItemDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpLineItem/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		archiveDumpLineItemBulkDeleteFromDatabase: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/DumpLineItem/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['DumpLineItem']
		}),
		getDumpRate: builder.query<QueryResultsDto<DumpRateDto>, GetDumpRateQueryParameters>({
			query: (args) => ({
				url: `/api/DumpRate?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['DumpRate']
		}),
		createDumpRate: builder.mutation<DumpRateDto, DumpRateDto>({
			query: (args) => ({
				url: `/api/DumpRate`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['DumpRate']
		}),
		updateDumpRate: builder.mutation<DumpRateDto, DumpRateDto>({
			query: (args) => ({
				url: `/api/DumpRate`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['DumpRate']
		}),
		getDumpRateById: builder.query<DumpRateDto, GetDumpRateByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpRate/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['DumpRate']
		}),
		archiveDumpRateById: builder.mutation<void, ArchiveDumpRateByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpRate/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DumpRate']
		}),
		patchDumpRateById: builder.mutation<void, PatchDumpRateByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpRate/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['DumpRate']
		}),
		dumpRateBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/DumpRate/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['DumpRate']
		}),
		dumpRateBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/DumpRate/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['DumpRate']
		}),
		archiveDumpRateDeleteById: builder.mutation<void, ArchiveDumpRateDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpRate/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DumpRate']
		}),
		archiveDumpRateBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/DumpRate/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['DumpRate']
		}),
		archiveDumpRateDeleteFromDatabaseById: builder.mutation<void, ArchiveDumpRateDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/DumpRate/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['DumpRate']
		}),
		archiveDumpRateBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/DumpRate/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['DumpRate']
		}),
		getEquipment: builder.query<QueryResultsDto<EquipmentDto>, GetEquipmentQueryParameters>({
			query: (args) => ({
				url: `/api/Equipment?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Equipment']
		}),
		createEquipment: builder.mutation<EquipmentDto, EquipmentDto>({
			query: (args) => ({
				url: `/api/Equipment`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Equipment']
		}),
		updateEquipment: builder.mutation<EquipmentDto, EquipmentDto>({
			query: (args) => ({
				url: `/api/Equipment`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Equipment']
		}),
		getEquipmentById: builder.query<EquipmentDto, GetEquipmentByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Equipment/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Equipment']
		}),
		archiveEquipmentById: builder.mutation<void, ArchiveEquipmentByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Equipment/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Equipment']
		}),
		patchEquipmentById: builder.mutation<void, PatchEquipmentByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Equipment/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Equipment']
		}),
		getEquipmentNumberById: builder.query<boolean, GetEquipmentNumberByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Equipment/number/${args.number}`,
				method: 'GET',
			}),
			providesTags: ['Equipment']
		}),
		equipmentBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Equipment/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Equipment']
		}),
		equipmentBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Equipment/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Equipment']
		}),
		archiveEquipmentDeleteById: builder.mutation<void, ArchiveEquipmentDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Equipment/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Equipment']
		}),
		archiveEquipmentBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Equipment/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Equipment']
		}),
		archiveEquipmentDeleteFromDatabaseById: builder.mutation<void, ArchiveEquipmentDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Equipment/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Equipment']
		}),
		archiveEquipmentBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Equipment/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Equipment']
		}),
		getEquipmentType: builder.query<QueryResultsDto<EquipmentTypeDto>, GetEquipmentTypeQueryParameters>({
			query: (args) => ({
				url: `/api/EquipmentType?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['EquipmentType']
		}),
		createEquipmentType: builder.mutation<EquipmentTypeDto, EquipmentTypeDto>({
			query: (args) => ({
				url: `/api/EquipmentType`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['EquipmentType']
		}),
		updateEquipmentType: builder.mutation<void, EquipmentTypeDto>({
			query: (args) => ({
				url: `/api/EquipmentType`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['EquipmentType']
		}),
		getEquipmentTypeById: builder.query<EquipmentTypeDto, GetEquipmentTypeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/EquipmentType/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['EquipmentType']
		}),
		archiveEquipmentTypeById: builder.mutation<void, ArchiveEquipmentTypeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/EquipmentType/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['EquipmentType']
		}),
		patchEquipmentTypeById: builder.mutation<void, PatchEquipmentTypeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/EquipmentType/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['EquipmentType']
		}),
		equipmentTypeBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/EquipmentType/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['EquipmentType']
		}),
		equipmentTypeBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/EquipmentType/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['EquipmentType']
		}),
		archiveEquipmentTypeDeleteById: builder.mutation<void, ArchiveEquipmentTypeDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/EquipmentType/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['EquipmentType']
		}),
		archiveEquipmentTypeBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/EquipmentType/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['EquipmentType']
		}),
		archiveEquipmentTypeDeleteFromDatabaseById: builder.mutation<void, ArchiveEquipmentTypeDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/EquipmentType/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['EquipmentType']
		}),
		archiveEquipmentTypeBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/EquipmentType/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['EquipmentType']
		}),
		getHourlyLineItem: builder.query<QueryResultsDto<HourlyLineItemDto>, GetHourlyLineItemQueryParameters>({
			query: (args) => ({
				url: `/api/HourlyLineItem?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['HourlyLineItem']
		}),
		createHourlyLineItem: builder.mutation<HourlyLineItemDto, HourlyLineItemDto>({
			query: (args) => ({
				url: `/api/HourlyLineItem`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		updateHourlyLineItem: builder.mutation<void, HourlyLineItemDto>({
			query: (args) => ({
				url: `/api/HourlyLineItem`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		getHourlyLineItemById: builder.query<HourlyLineItemDto, GetHourlyLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/HourlyLineItem/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['HourlyLineItem']
		}),
		archiveHourlyLineItemById: builder.mutation<void, ArchiveHourlyLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/HourlyLineItem/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		patchHourlyLineItemById: builder.mutation<void, PatchHourlyLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/HourlyLineItem/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		hourlyLineItemBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/HourlyLineItem/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		hourlyLineItemBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/HourlyLineItem/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		archiveHourlyLineItemDeleteById: builder.mutation<void, ArchiveHourlyLineItemDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/HourlyLineItem/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		archiveHourlyLineItemBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/HourlyLineItem/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		archiveHourlyLineItemDeleteFromDatabaseById: builder.mutation<void, ArchiveHourlyLineItemDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/HourlyLineItem/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		archiveHourlyLineItemBulkDeleteFromDatabase: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/HourlyLineItem/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['HourlyLineItem']
		}),
		getIncomeCode: builder.query<QueryResultsDto<IncomeCodeDto>, GetIncomeCodeQueryParameters>({
			query: (args) => ({
				url: `/api/IncomeCode?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['IncomeCode']
		}),
		createIncomeCode: builder.mutation<IncomeCodeDto, IncomeCodeDto>({
			query: (args) => ({
				url: `/api/IncomeCode`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['IncomeCode']
		}),
		updateIncomeCode: builder.mutation<IncomeCodeDto, IncomeCodeDto>({
			query: (args) => ({
				url: `/api/IncomeCode`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['IncomeCode']
		}),
		getIncomeCodeById: builder.query<IncomeCodeDto, GetIncomeCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/IncomeCode/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['IncomeCode']
		}),
		archiveIncomeCodeById: builder.mutation<void, ArchiveIncomeCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/IncomeCode/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['IncomeCode']
		}),
		patchIncomeCodeById: builder.mutation<void, PatchIncomeCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/IncomeCode/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['IncomeCode']
		}),
		incomeCodeBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/IncomeCode/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['IncomeCode']
		}),
		incomeCodeBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/IncomeCode/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['IncomeCode']
		}),
		archiveIncomeCodeDeleteById: builder.mutation<void, ArchiveIncomeCodeDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/IncomeCode/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['IncomeCode']
		}),
		archiveIncomeCodeBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/IncomeCode/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['IncomeCode']
		}),
		archiveIncomeCodeDeleteFromDatabaseById: builder.mutation<void, ArchiveIncomeCodeDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/IncomeCode/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['IncomeCode']
		}),
		archiveIncomeCodeBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/IncomeCode/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['IncomeCode']
		}),
		getInvoice: builder.query<QueryResultsDto<InvoiceHistoryDto>, GetInvoiceQueryParameters>({
			query: (args) => ({
				url: `/api/Invoice?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Invoice']
		}),
		getInvoiceById: builder.query<InvoiceHistoryDto, GetInvoiceByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Invoice/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Invoice']
		}),
		getJob: builder.query<QueryResultsDto<JobDto>, GetJobQueryParameters>({
			query: (args) => ({
				url: `/api/Job?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Job']
		}),
		createJob: builder.mutation<JobDto, JobDto>({
			query: (args) => ({
				url: `/api/Job`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Job','Quote']
		}),
		updateJob: builder.mutation<void, JobDto>({
			query: (args) => ({
				url: `/api/Job`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Job','Quote']
		}),
		getJobById: builder.query<JobDto, GetJobByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Job/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Job']
		}),
		archiveJobById: builder.mutation<void, ArchiveJobByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Job/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Job','Quote']
		}),
		patchJobById: builder.mutation<void, PatchJobByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Job/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Job','Quote']
		}),
		createJobUpdateCurrentQuote: builder.mutation<string, { params: CreateJobUpdateCurrentQuoteQueryParameters, payload: CloneQuoteDto }>({
			query: (args) => ({
				url: `/api/Job/${args.params.id}/updateCurrentQuote`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['Job','Quote']
		}),
		jobBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Job/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Job','Quote']
		}),
		jobBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Job/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Job','Quote']
		}),
		archiveJobDeleteById: builder.mutation<void, ArchiveJobDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Job/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Job','Quote']
		}),
		archiveJobBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Job/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Job','Quote']
		}),
		archiveJobDeleteFromDatabaseById: builder.mutation<void, ArchiveJobDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Job/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Job','Quote']
		}),
		archiveJobBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Job/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Job','Quote']
		}),
		getJobJobNote: builder.query<QueryResultsDto<JobNoteDto>, GetJobJobNoteQueryParameters>({
			query: (args) => ({
				url: `/api/job/${args.jobId}/JobNote?${buildQueryString(args, ["jobId"])}`,
				method: 'GET',
			}),
			providesTags: ['JobNote']
		}),
		createJobJobNote: builder.mutation<JobNoteDto, { params: CreateJobJobNoteQueryParameters, payload: JobNoteDto }>({
			query: (args) => ({
				url: `/api/job/${args.params.jobId}/JobNote`,
				method: 'POST',
				body: args.payload
			}),
			invalidatesTags: ['JobNote']
		}),
		updateJobJobNote: builder.mutation<void, { params: UpdateJobJobNoteQueryParameters, payload: JobNoteDto }>({
			query: (args) => ({
				url: `/api/job/${args.params.jobId}/JobNote`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['JobNote']
		}),
		getJobJobNoteById: builder.query<JobNoteDto, GetJobJobNoteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/job/${args.jobId}/JobNote/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['JobNote']
		}),
		archiveJobJobNoteById: builder.mutation<void, ArchiveJobJobNoteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/job/${args.jobId}/JobNote/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['JobNote']
		}),
		patchJobJobNoteById: builder.mutation<void, PatchJobJobNoteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/job/${args.jobId}/JobNote/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['JobNote']
		}),
		jobJobNoteBulkArchive: builder.mutation<BulkUpdateResultDto, { params: JobJobNoteBulkArchiveQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/job/${args.params.jobId}/JobNote/bulkArchive`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['JobNote']
		}),
		jobJobNoteBulkUnarchive: builder.mutation<BulkUpdateResultDto, { params: JobJobNoteBulkUnarchiveQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/job/${args.params.jobId}/JobNote/bulkUnarchive`,
				method: 'PATCH',
				body: args.payload
			}),
			invalidatesTags: ['JobNote']
		}),
		archiveJobJobNoteDeleteById: builder.mutation<void, ArchiveJobJobNoteDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/job/${args.jobId}/JobNote/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['JobNote']
		}),
		archiveJobJobNoteBulkDelete: builder.mutation<BulkUpdateResultDto, { params: ArchiveJobJobNoteBulkDeleteQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/job/${args.params.jobId}/JobNote/bulkDelete`,
				method: 'DELETE',
				body: args.payload
			}),
			invalidatesTags: ['JobNote']
		}),
		archiveJobJobNoteDeleteFromDatabaseById: builder.mutation<void, ArchiveJobJobNoteDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/job/${args.jobId}/JobNote/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['JobNote']
		}),
		archiveJobJobNoteBulkDeleteFromDatabase: builder.mutation<void, { params: ArchiveJobJobNoteBulkDeleteFromDatabaseQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/job/${args.params.jobId}/JobNote/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args.payload
			}),
			invalidatesTags: ['JobNote']
		}),
		getLineItem: builder.query<QueryResultsDto<LineItemDto>, GetLineItemQueryParameters>({
			query: (args) => ({
				url: `/api/LineItem?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		createLineItem: builder.mutation<LineItemDto, LineItemDto>({
			query: (args) => ({
				url: `/api/LineItem`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['LineItem']
		}),
		updateLineItem: builder.mutation<void, LineItemDto>({
			query: (args) => ({
				url: `/api/LineItem`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['LineItem']
		}),
		getLineItemById: builder.query<LineItemDto, GetLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/LineItem/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		archiveLineItemById: builder.mutation<void, ArchiveLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/LineItem/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['LineItem']
		}),
		patchLineItemById: builder.mutation<void, PatchLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/LineItem/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['LineItem']
		}),
		getLineItemQuoteSummaryLineItems: builder.query<QueryResultsDto<LineItemDto>, GetLineItemQuoteSummaryLineItemsQueryParameters>({
			query: (args) => ({
				url: `/api/LineItem/quoteSummary/lineItems?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		getLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItems: builder.query<boolean, GetLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItemsQueryParameters>({
			query: (args) => ({
				url: `/api/LineItem/doesHaulingLineItemHaveCorrespondingMaterialLineItems?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		lineItemBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/LineItem/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['LineItem']
		}),
		lineItemBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/LineItem/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['LineItem']
		}),
		archiveLineItemDeleteById: builder.mutation<void, ArchiveLineItemDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/LineItem/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['LineItem']
		}),
		archiveLineItemBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/LineItem/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['LineItem']
		}),
		archiveLineItemDeleteFromDatabaseById: builder.mutation<void, ArchiveLineItemDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/LineItem/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['LineItem']
		}),
		archiveLineItemBulkDeleteFromDatabase: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/LineItem/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['LineItem']
		}),
		getMaterial: builder.query<QueryResultsDto<MaterialDto>, GetMaterialQueryParameters>({
			query: (args) => ({
				url: `/api/Material?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Material']
		}),
		createMaterial: builder.mutation<MaterialDto, MaterialDto>({
			query: (args) => ({
				url: `/api/Material`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Material']
		}),
		updateMaterial: builder.mutation<void, MaterialDto>({
			query: (args) => ({
				url: `/api/Material`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Material']
		}),
		getMaterialById: builder.query<MaterialDto, GetMaterialByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Material/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Material']
		}),
		archiveMaterialById: builder.mutation<void, ArchiveMaterialByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Material/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Material']
		}),
		patchMaterialById: builder.mutation<void, PatchMaterialByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Material/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Material']
		}),
		materialBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Material/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Material']
		}),
		materialBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Material/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Material']
		}),
		archiveMaterialDeleteById: builder.mutation<void, ArchiveMaterialDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Material/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Material']
		}),
		archiveMaterialBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Material/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Material']
		}),
		archiveMaterialDeleteFromDatabaseById: builder.mutation<void, ArchiveMaterialDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Material/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Material']
		}),
		archiveMaterialBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Material/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Material']
		}),
		getMaterialLineItem: builder.query<QueryResultsDto<MaterialLineItemDto>, GetMaterialLineItemQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialLineItem?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['MaterialLineItem']
		}),
		createMaterialLineItem: builder.mutation<MaterialLineItemDto, MaterialLineItemDto>({
			query: (args) => ({
				url: `/api/MaterialLineItem`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		updateMaterialLineItem: builder.mutation<void, MaterialLineItemDto>({
			query: (args) => ({
				url: `/api/MaterialLineItem`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		getMaterialLineItemById: builder.query<MaterialLineItemDto, GetMaterialLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialLineItem/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['MaterialLineItem']
		}),
		archiveMaterialLineItemById: builder.mutation<void, ArchiveMaterialLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialLineItem/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		patchMaterialLineItemById: builder.mutation<void, PatchMaterialLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialLineItem/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		materialLineItemBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MaterialLineItem/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		materialLineItemBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MaterialLineItem/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		archiveMaterialLineItemDeleteById: builder.mutation<void, ArchiveMaterialLineItemDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialLineItem/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		archiveMaterialLineItemBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MaterialLineItem/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		archiveMaterialLineItemDeleteFromDatabaseById: builder.mutation<void, ArchiveMaterialLineItemDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialLineItem/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		archiveMaterialLineItemBulkDeleteFromDatabase: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MaterialLineItem/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['MaterialLineItem']
		}),
		getMaterialPrice: builder.query<QueryResultsDto<MaterialPriceDto>, GetMaterialPriceQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialPrice?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['MaterialPrice']
		}),
		createMaterialPrice: builder.mutation<MaterialPriceDto, MaterialPriceDto>({
			query: (args) => ({
				url: `/api/MaterialPrice`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		updateMaterialPrice: builder.mutation<void, MaterialPriceDto>({
			query: (args) => ({
				url: `/api/MaterialPrice`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		getMaterialPriceById: builder.query<MaterialPriceDto, GetMaterialPriceByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialPrice/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['MaterialPrice']
		}),
		archiveMaterialPriceById: builder.mutation<void, ArchiveMaterialPriceByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialPrice/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		patchMaterialPriceById: builder.mutation<void, PatchMaterialPriceByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialPrice/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		materialPriceBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MaterialPrice/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		materialPriceBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MaterialPrice/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		archiveMaterialPriceDeleteById: builder.mutation<void, ArchiveMaterialPriceDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialPrice/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		archiveMaterialPriceBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MaterialPrice/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		archiveMaterialPriceDeleteFromDatabaseById: builder.mutation<void, ArchiveMaterialPriceDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MaterialPrice/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		archiveMaterialPriceBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/MaterialPrice/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['MaterialPrice']
		}),
		getMiscLineItem: builder.query<QueryResultsDto<MiscLineItemDto>, GetMiscLineItemQueryParameters>({
			query: (args) => ({
				url: `/api/MiscLineItem?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['MiscLineItem']
		}),
		createMiscLineItem: builder.mutation<MiscLineItemDto, MiscLineItemDto>({
			query: (args) => ({
				url: `/api/MiscLineItem`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		updateMiscLineItem: builder.mutation<void, MiscLineItemDto>({
			query: (args) => ({
				url: `/api/MiscLineItem`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		getMiscLineItemById: builder.query<MiscLineItemDto, GetMiscLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscLineItem/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['MiscLineItem']
		}),
		archiveMiscLineItemById: builder.mutation<void, ArchiveMiscLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscLineItem/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		patchMiscLineItemById: builder.mutation<void, PatchMiscLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscLineItem/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		miscLineItemBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MiscLineItem/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		miscLineItemBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MiscLineItem/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		archiveMiscLineItemDeleteById: builder.mutation<void, ArchiveMiscLineItemDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscLineItem/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		archiveMiscLineItemBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MiscLineItem/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		archiveMiscLineItemDeleteFromDatabaseById: builder.mutation<void, ArchiveMiscLineItemDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscLineItem/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		archiveMiscLineItemBulkDeleteFromDatabase: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MiscLineItem/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['MiscLineItem']
		}),
		getMiscSettings: builder.query<QueryResultsDto<MiscSettingsDto>, GetMiscSettingsQueryParameters>({
			query: (args) => ({
				url: `/api/MiscSettings?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['MiscSettings']
		}),
		createMiscSettings: builder.mutation<MiscSettingsDto, MiscSettingsDto>({
			query: (args) => ({
				url: `/api/MiscSettings`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['MiscSettings']
		}),
		updateMiscSettings: builder.mutation<void, MiscSettingsDto>({
			query: (args) => ({
				url: `/api/MiscSettings`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['MiscSettings']
		}),
		getMiscSettingsById: builder.query<MiscSettingsDto, GetMiscSettingsByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscSettings/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['MiscSettings']
		}),
		archiveMiscSettingsById: builder.mutation<void, ArchiveMiscSettingsByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscSettings/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MiscSettings']
		}),
		patchMiscSettingsById: builder.mutation<void, PatchMiscSettingsByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscSettings/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['MiscSettings']
		}),
		miscSettingsBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MiscSettings/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['MiscSettings']
		}),
		miscSettingsBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MiscSettings/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['MiscSettings']
		}),
		archiveMiscSettingsDeleteById: builder.mutation<void, ArchiveMiscSettingsDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscSettings/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MiscSettings']
		}),
		archiveMiscSettingsBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/MiscSettings/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['MiscSettings']
		}),
		archiveMiscSettingsDeleteFromDatabaseById: builder.mutation<void, ArchiveMiscSettingsDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/MiscSettings/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MiscSettings']
		}),
		archiveMiscSettingsBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/MiscSettings/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['MiscSettings']
		}),
		getOrder: builder.query<QueryResultsDto<OrderDto>, GetOrderQueryParameters>({
			query: (args) => ({
				url: `/api/Order?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Order']
		}),
		createOrder: builder.mutation<OrderDto, OrderDto>({
			query: (args) => ({
				url: `/api/Order`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		updateOrder: builder.mutation<void, OrderDto>({
			query: (args) => ({
				url: `/api/Order`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		getOrderById: builder.query<OrderDto, GetOrderByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Order/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Order']
		}),
		archiveOrderById: builder.mutation<void, ArchiveOrderByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Order/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		patchOrderById: builder.mutation<void, PatchOrderByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Order/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		getOrderOrderReview: builder.query<OrderDto, GetOrderOrderReviewQueryParameters>({
			query: (args) => ({
				url: `/api/Order/${args.id}/orderReview`,
				method: 'GET',
			}),
			providesTags: ['Order']
		}),
		createOrderQuickCreateById: builder.mutation<OrderDto, CreateOrderQuickCreateByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Order/quickCreate/${args.jobId}`,
				method: 'POST',
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		patchOrderCancel: builder.mutation<void, CancelItemDto>({
			query: (args) => ({
				url: `/api/Order/cancel`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		patchOrderRestoreById: builder.mutation<void, PatchOrderRestoreByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Order/restore/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		orderBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Order/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		orderBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Order/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		archiveOrderDeleteById: builder.mutation<void, ArchiveOrderDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Order/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		archiveOrderBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Order/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		archiveOrderDeleteFromDatabaseById: builder.mutation<void, ArchiveOrderDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Order/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		archiveOrderBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Order/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Order','Dispatch','OrderReview','Invoice']
		}),
		archiveOrderLineItemById: builder.mutation<void, ArchiveOrderLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderLineItem/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		getOrderLineItemById: builder.query<void, GetOrderLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderLineItem/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['OrderLineItem']
		}),
		patchOrderLineItemById: builder.mutation<void, PatchOrderLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderLineItem/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		orderLineItemBulkArchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/OrderLineItem/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		archiveOrderLineItemBulkDelete: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/OrderLineItem/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		archiveOrderLineItemBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/OrderLineItem/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		orderLineItemBulkUnarchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/OrderLineItem/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		patchOrderLineItemCancel: builder.mutation<OrderLineItemDto, CancelItemDto>({
			query: (args) => ({
				url: `/api/OrderLineItem/cancel`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		patchOrderLineItemRestoreById: builder.mutation<OrderLineItemDto, PatchOrderLineItemRestoreByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderLineItem/restore/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		createOrderLineItem: builder.mutation<void, OrderLineItemDto>({
			query: (args) => ({
				url: `/api/OrderLineItem`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		updateOrderLineItem: builder.mutation<void, OrderLineItemDto>({
			query: (args) => ({
				url: `/api/OrderLineItem`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		archiveOrderLineItemDeleteById: builder.mutation<void, ArchiveOrderLineItemDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderLineItem/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		archiveOrderLineItemDeleteFromDatabaseById: builder.mutation<void, ArchiveOrderLineItemDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderLineItem/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OrderLineItem']
		}),
		getOrderReview: builder.query<QueryResultsDto<OrderReviewDto>, GetOrderReviewQueryParameters>({
			query: (args) => ({
				url: `/api/OrderReview?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['OrderReview']
		}),
		createOrderReview: builder.mutation<OrderReviewDto, OrderReviewDto>({
			query: (args) => ({
				url: `/api/OrderReview`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		updateOrderReview: builder.mutation<void, OrderReviewDto>({
			query: (args) => ({
				url: `/api/OrderReview`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		getOrderReviewById: builder.query<OrderReviewDto, GetOrderReviewByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderReview/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['OrderReview']
		}),
		archiveOrderReviewById: builder.mutation<void, ArchiveOrderReviewByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderReview/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		patchOrderReviewById: builder.mutation<void, PatchOrderReviewByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderReview/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		getOrderReviewCurrentMonthOrders: builder.query<string[], GetOrderReviewCurrentMonthOrdersQueryParameters>({
			query: (args) => ({
				url: `/api/OrderReview/currentMonthOrders?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['OrderReview']
		}),
		createOrderReviewPdf: builder.mutation<PdfDto, CreateOrderReviewPdfQueryParameters>({
			query: (args) => ({
				url: `/api/OrderReview/${args.id}/pdf`,
				method: 'POST',
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		createOrderReviewPdfs: builder.mutation<string, string[]>({
			query: (args) => ({
				url: `/api/OrderReview/pdfs`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		orderReviewBulkArchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/OrderReview/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		orderReviewBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/OrderReview/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		archiveOrderReviewDeleteById: builder.mutation<void, ArchiveOrderReviewDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderReview/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		archiveOrderReviewBulkDelete: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/OrderReview/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		archiveOrderReviewDeleteFromDatabaseById: builder.mutation<void, ArchiveOrderReviewDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/OrderReview/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		archiveOrderReviewBulkDeleteFromDatabase: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/OrderReview/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['OrderReview','Order','Invoice']
		}),
		getPriceListSiteById: builder.query<PriceListLineItemDto[], GetPriceListSiteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/PriceList/site/${args.siteId}`,
				method: 'GET',
			}),
			providesTags: ['PriceList']
		}),
		createPriceList: builder.mutation<BulkUpdateResultDto, PriceListDto>({
			query: (args) => ({
				url: `/api/PriceList`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['PriceList']
		}),
		getQuickBooksAuthorize: builder.query<void, void>({
			query: () => ({
				url: `/api/QuickBooks/authorize`,
				method: 'GET',
			}),
			providesTags: ['QuickBooks']
		}),
		getQuickBooksCallback: builder.query<void, GetQuickBooksCallbackQueryParameters>({
			query: (args) => ({
				url: `/api/QuickBooks/callback?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['QuickBooks']
		}),
		getQuote: builder.query<QueryResultsDto<QuoteDto>, GetQuoteQueryParameters>({
			query: (args) => ({
				url: `/api/Quote?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Quote']
		}),
		createQuote: builder.mutation<QuoteDto, QuoteDto>({
			query: (args) => ({
				url: `/api/Quote`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		updateQuote: builder.mutation<void, QuoteDto>({
			query: (args) => ({
				url: `/api/Quote`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		getQuoteAffected: builder.query<QueryResultsDto<QuoteDto>, GetQuoteAffectedQueryParameters>({
			query: (args) => ({
				url: `/api/Quote/affected?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Quote']
		}),
		getQuoteById: builder.query<QuoteDto, GetQuoteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Quote/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Quote']
		}),
		archiveQuoteById: builder.mutation<void, ArchiveQuoteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Quote/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		patchQuoteById: builder.mutation<void, PatchQuoteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Quote/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		createQuotePdf: builder.mutation<PdfDto, CreateQuotePdfQueryParameters>({
			query: (args) => ({
				url: `/api/Quote/${args.id}/pdf`,
				method: 'POST',
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		updateQuoteAccept: builder.mutation<void, { params: UpdateQuoteAcceptQueryParameters, payload: string[] }>({
			query: (args) => ({
				url: `/api/Quote/${args.params.id}/accept`,
				method: 'PUT',
				body: args.payload
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		createQuoteClone: builder.mutation<QuoteDto, CloneQuoteDto>({
			query: (args) => ({
				url: `/api/Quote/clone`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		quoteBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Quote/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		quoteBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Quote/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		archiveQuoteDeleteById: builder.mutation<void, ArchiveQuoteDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Quote/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		archiveQuoteBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Quote/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		archiveQuoteDeleteFromDatabaseById: builder.mutation<void, ArchiveQuoteDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Quote/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		archiveQuoteBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Quote/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Quote','Job','LineItem','DumpLineItem','HourlyLineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem']
		}),
		getRegion: builder.query<QueryResultsDto<RegionDto>, GetRegionQueryParameters>({
			query: (args) => ({
				url: `/api/Region?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Region']
		}),
		createRegion: builder.mutation<RegionDto, RegionDto>({
			query: (args) => ({
				url: `/api/Region`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Region']
		}),
		updateRegion: builder.mutation<void, RegionDto>({
			query: (args) => ({
				url: `/api/Region`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Region']
		}),
		getRegionById: builder.query<RegionDto, GetRegionByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Region/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Region']
		}),
		archiveRegionById: builder.mutation<void, ArchiveRegionByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Region/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Region']
		}),
		patchRegionById: builder.mutation<void, PatchRegionByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Region/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Region']
		}),
		regionBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Region/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Region']
		}),
		regionBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Region/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Region']
		}),
		archiveRegionDeleteById: builder.mutation<void, ArchiveRegionDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Region/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Region']
		}),
		archiveRegionBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Region/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Region']
		}),
		archiveRegionDeleteFromDatabaseById: builder.mutation<void, ArchiveRegionDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Region/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Region']
		}),
		archiveRegionBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Region/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Region']
		}),
		getSite: builder.query<QueryResultsDto<SiteDto>, GetSiteQueryParameters>({
			query: (args) => ({
				url: `/api/Site?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Site']
		}),
		createSite: builder.mutation<SiteDto, SiteDto>({
			query: (args) => ({
				url: `/api/Site`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Site']
		}),
		updateSite: builder.mutation<SiteDto, SiteDto>({
			query: (args) => ({
				url: `/api/Site`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Site']
		}),
		getSiteById: builder.query<SiteDto, GetSiteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Site/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Site']
		}),
		archiveSiteById: builder.mutation<void, ArchiveSiteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Site/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Site']
		}),
		patchSiteById: builder.mutation<void, PatchSiteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Site/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Site']
		}),
		siteBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Site/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Site']
		}),
		siteBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Site/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Site']
		}),
		archiveSiteDeleteById: builder.mutation<void, ArchiveSiteDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Site/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Site']
		}),
		archiveSiteBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Site/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Site']
		}),
		archiveSiteDeleteFromDatabaseById: builder.mutation<void, ArchiveSiteDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Site/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Site']
		}),
		archiveSiteBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Site/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Site']
		}),
		getSiteHaulingLineItem: builder.query<QueryResultsDto<SiteHaulingLineItemDto>, GetSiteHaulingLineItemQueryParameters>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['SiteHaulingLineItem']
		}),
		createSiteHaulingLineItem: builder.mutation<SiteHaulingLineItemDto, SiteHaulingLineItemDto>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		updateSiteHaulingLineItem: builder.mutation<void, SiteHaulingLineItemDto>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		getSiteHaulingLineItemById: builder.query<SiteHaulingLineItemDto, GetSiteHaulingLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['SiteHaulingLineItem']
		}),
		archiveSiteHaulingLineItemById: builder.mutation<void, ArchiveSiteHaulingLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		patchSiteHaulingLineItemById: builder.mutation<void, PatchSiteHaulingLineItemByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		siteHaulingLineItemBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		siteHaulingLineItemBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		archiveSiteHaulingLineItemDeleteById: builder.mutation<void, ArchiveSiteHaulingLineItemDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		archiveSiteHaulingLineItemBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		archiveSiteHaulingLineItemDeleteFromDatabaseById: builder.mutation<void, ArchiveSiteHaulingLineItemDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		archiveSiteHaulingLineItemBulkDeleteFromDatabase: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/SiteHaulingLineItem/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['SiteHaulingLineItem']
		}),
		getSurchargeCode: builder.query<QueryResultsDto<SurchargeCodeDto>, GetSurchargeCodeQueryParameters>({
			query: (args) => ({
				url: `/api/SurchargeCode?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['SurchargeCode']
		}),
		createSurchargeCode: builder.mutation<SurchargeCodeDto, SurchargeCodeDto>({
			query: (args) => ({
				url: `/api/SurchargeCode`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		updateSurchargeCode: builder.mutation<SurchargeCodeDto, SurchargeCodeDto>({
			query: (args) => ({
				url: `/api/SurchargeCode`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		getSurchargeCodeById: builder.query<SurchargeCodeDto, GetSurchargeCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SurchargeCode/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['SurchargeCode']
		}),
		archiveSurchargeCodeById: builder.mutation<void, ArchiveSurchargeCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SurchargeCode/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		patchSurchargeCodeById: builder.mutation<void, PatchSurchargeCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SurchargeCode/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		surchargeCodeBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/SurchargeCode/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		surchargeCodeBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/SurchargeCode/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		archiveSurchargeCodeDeleteById: builder.mutation<void, ArchiveSurchargeCodeDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SurchargeCode/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		archiveSurchargeCodeBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/SurchargeCode/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		archiveSurchargeCodeDeleteFromDatabaseById: builder.mutation<void, ArchiveSurchargeCodeDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/SurchargeCode/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		archiveSurchargeCodeBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/SurchargeCode/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['SurchargeCode']
		}),
		getTermsCode: builder.query<QueryResultsDto<TermsCodeDto>, GetTermsCodeQueryParameters>({
			query: (args) => ({
				url: `/api/TermsCode?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['TermsCode']
		}),
		createTermsCode: builder.mutation<TermsCodeDto, TermsCodeDto>({
			query: (args) => ({
				url: `/api/TermsCode`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['TermsCode']
		}),
		updateTermsCode: builder.mutation<void, TermsCodeDto>({
			query: (args) => ({
				url: `/api/TermsCode`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['TermsCode']
		}),
		getTermsCodeById: builder.query<TermsCodeDto, GetTermsCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/TermsCode/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['TermsCode']
		}),
		archiveTermsCodeById: builder.mutation<void, ArchiveTermsCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/TermsCode/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['TermsCode']
		}),
		patchTermsCodeById: builder.mutation<void, PatchTermsCodeByIdQueryParameters>({
			query: (args) => ({
				url: `/api/TermsCode/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['TermsCode']
		}),
		termsCodeBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/TermsCode/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['TermsCode']
		}),
		termsCodeBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/TermsCode/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['TermsCode']
		}),
		archiveTermsCodeDeleteById: builder.mutation<void, ArchiveTermsCodeDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/TermsCode/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['TermsCode']
		}),
		archiveTermsCodeBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/TermsCode/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['TermsCode']
		}),
		archiveTermsCodeDeleteFromDatabaseById: builder.mutation<void, ArchiveTermsCodeDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/TermsCode/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['TermsCode']
		}),
		archiveTermsCodeBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/TermsCode/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['TermsCode']
		}),
		getTrash: builder.query<QueryResultsDto<TrashDto>, GetTrashQueryParameters>({
			query: (args) => ({
				url: `/api/Trash?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		updateTrash: builder.mutation<void, TrashDto>({
			query: (args) => ({
				url: `/api/Trash`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		createTrash: builder.mutation<void, TrashDto>({
			query: (args) => ({
				url: `/api/Trash`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		getTrashById: builder.query<TrashDto, GetTrashByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Trash/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		patchTrashById: builder.mutation<void, PatchTrashByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Trash/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		archiveTrashById: builder.mutation<void, ArchiveTrashByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Trash/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		archiveTrashBulkPermanentDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Trash/bulkPermanentDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		patchTrashBulkRestore: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/Trash/bulkRestore`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		trashBulkArchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Trash/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		trashBulkUnarchive: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Trash/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		archiveTrashDeleteById: builder.mutation<void, ArchiveTrashDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Trash/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		archiveTrashBulkDelete: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Trash/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		archiveTrashDeleteFromDatabaseById: builder.mutation<void, ArchiveTrashDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/Trash/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		archiveTrashBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/Trash/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['Trash','CloudStorage','Contact','Customer','Document','DumpLineItem','DumpRate','EquipmentType','HourlyLineItem','IncomeCode','LineItem','MaterialLineItem','MiscLineItem','SiteHaulingLineItem','Material','MaterialPrice','MiscSettings','PriceList','Quote','Site','SurchargeCode','TaxCode','TermsCode','User','Job','Order','Broker','Equipment','OrderReview']
		}),
		getUser: builder.query<QueryResultsDto<UserDto>, GetUserQueryParameters>({
			query: (args) => ({
				url: `/api/User?${buildQueryString(args)}`,
				method: 'GET',
			}),
			providesTags: ['User','Dispatch']
		}),
		createUser: builder.mutation<UserDto, UserDto>({
			query: (args) => ({
				url: `/api/User`,
				method: 'POST',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		updateUser: builder.mutation<void, UserDto>({
			query: (args) => ({
				url: `/api/User`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserById: builder.query<UserDto, GetUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['User','Dispatch']
		}),
		archiveUserById: builder.mutation<void, ArchiveUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
		patchUserById: builder.mutation<void, PatchUserByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/${args.id}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['User']
		}),
		userBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkArchive`,
				method: 'PUT',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		userBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkUnarchive`,
				method: 'PATCH',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		archiveUserDeleteFromDatabaseById: builder.mutation<void, ArchiveUserDeleteFromDatabaseByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/deleteFromDatabase/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
		archiveUserBulkDeleteFromDatabase: builder.mutation<void, string[]>({
			query: (args) => ({
				url: `/api/User/bulkDeleteFromDatabase`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['User']
		}),
		getUserMe: builder.query<UserDto, void>({
			query: () => ({
				url: `/api/User/me`,
				method: 'GET',
			}),
			providesTags: ['User','Dispatch']
		}),
		getUserAuth0UserIdById: builder.query<UserDto, GetUserAuth0UserIdByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/auth0UserId/${args.id}`,
				method: 'GET',
			}),
			providesTags: ['User','Dispatch']
		}),
		getUserPermissions: builder.query<string[], void>({
			query: () => ({
				url: `/api/User/permissions`,
				method: 'GET',
			}),
			providesTags: ['User','Dispatch']
		}),
		getUserRoles: builder.query<UserRoleDto[], void>({
			query: () => ({
				url: `/api/User/roles`,
				method: 'GET',
			}),
			providesTags: ['User','Dispatch']
		}),
		archiveUserDeleteById: builder.mutation<void, ArchiveUserDeleteByIdQueryParameters>({
			query: (args) => ({
				url: `/api/User/delete/${args.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['User']
		}),
		archiveUserBulkDelete: builder.mutation<BulkUpdateResultDto, string[]>({
			query: (args) => ({
				url: `/api/User/bulkDelete`,
				method: 'DELETE',
				body: args
			}),
			invalidatesTags: ['User']
		}),
	})
});

export const {
	useCreateBillingRatesLineItemBillingRatesMutation,
	useCreateBillingRatesFreightLineItemBillingRatesMutation,
	useCreateBillingRatesLineItemMutation,
	useGetBrokerQuery,
	useLazyGetBrokerQuery,
	useCreateBrokerMutation,
	useUpdateBrokerMutation,
	useGetBrokerByIdQuery,
	useLazyGetBrokerByIdQuery,
	useArchiveBrokerByIdMutation,
	usePatchBrokerByIdMutation,
	useGetBrokerCodeByIdQuery,
	useLazyGetBrokerCodeByIdQuery,
	useBrokerBulkArchiveMutation,
	useBrokerBulkUnarchiveMutation,
	useArchiveBrokerDeleteByIdMutation,
	useArchiveBrokerBulkDeleteMutation,
	useArchiveBrokerDeleteFromDatabaseByIdMutation,
	useArchiveBrokerBulkDeleteFromDatabaseMutation,
	useArchiveCanceledDispatchByIdMutation,
	useGetCanceledDispatchByIdQuery,
	useLazyGetCanceledDispatchByIdQuery,
	usePatchCanceledDispatchByIdMutation,
	useCanceledDispatchBulkArchiveMutation,
	useArchiveCanceledDispatchBulkDeleteMutation,
	useArchiveCanceledDispatchBulkDeleteFromDatabaseMutation,
	useCanceledDispatchBulkUnarchiveMutation,
	useCreateCanceledDispatchMutation,
	useGetCanceledDispatchQuery,
	useLazyGetCanceledDispatchQuery,
	useUpdateCanceledDispatchMutation,
	useArchiveCanceledDispatchDeleteByIdMutation,
	useArchiveCanceledDispatchDeleteFromDatabaseByIdMutation,
	useGetCloudStorageQuery,
	useLazyGetCloudStorageQuery,
	useGetContactQuery,
	useLazyGetContactQuery,
	useCreateContactMutation,
	useUpdateContactMutation,
	useGetContactByIdQuery,
	useLazyGetContactByIdQuery,
	useArchiveContactByIdMutation,
	usePatchContactByIdMutation,
	useContactBulkArchiveMutation,
	useContactBulkUnarchiveMutation,
	useArchiveContactDeleteByIdMutation,
	useArchiveContactBulkDeleteMutation,
	useArchiveContactDeleteFromDatabaseByIdMutation,
	useArchiveContactBulkDeleteFromDatabaseMutation,
	useGetCustomerQuery,
	useLazyGetCustomerQuery,
	useCreateCustomerMutation,
	useUpdateCustomerMutation,
	useGetCustomerByIdQuery,
	useLazyGetCustomerByIdQuery,
	useArchiveCustomerByIdMutation,
	usePatchCustomerByIdMutation,
	useCustomerBulkArchiveMutation,
	useCustomerBulkUnarchiveMutation,
	useArchiveCustomerDeleteByIdMutation,
	useArchiveCustomerBulkDeleteMutation,
	useArchiveCustomerDeleteFromDatabaseByIdMutation,
	useArchiveCustomerBulkDeleteFromDatabaseMutation,
	useGetDispatchQuery,
	useLazyGetDispatchQuery,
	useCreateDispatchMutation,
	useUpdateDispatchMutation,
	useGetDispatchOrderManagementQuery,
	useLazyGetDispatchOrderManagementQuery,
	useGetDispatchOrderManagementCurrentMonthQuery,
	useLazyGetDispatchOrderManagementCurrentMonthQuery,
	useGetDispatchHaulerQuery,
	useLazyGetDispatchHaulerQuery,
	useGetDispatchPersonalQuery,
	useLazyGetDispatchPersonalQuery,
	useGetDispatchActiveDispatchQuery,
	useLazyGetDispatchActiveDispatchQuery,
	usePatchDispatchCancelMutation,
	usePatchDispatchRestoreMutation,
	usePatchDispatchStartMutation,
	usePatchDispatchArriveMutation,
	usePatchDispatchDepartMutation,
	usePatchDispatchStopMutation,
	useGetDispatchByIdQuery,
	useLazyGetDispatchByIdQuery,
	useArchiveDispatchByIdMutation,
	usePatchDispatchByIdMutation,
	useDispatchBulkArchiveMutation,
	useDispatchBulkUnarchiveMutation,
	useArchiveDispatchDeleteByIdMutation,
	useArchiveDispatchBulkDeleteMutation,
	useArchiveDispatchDeleteFromDatabaseByIdMutation,
	useArchiveDispatchBulkDeleteFromDatabaseMutation,
	useUpdateDispatchBulkReleaseMutation,
	useUpdateDispatchBulkRecallMutation,
	useGetDocumentQuery,
	useLazyGetDocumentQuery,
	useCreateDocumentMutation,
	useUpdateDocumentMutation,
	useGetDocumentByIdQuery,
	useLazyGetDocumentByIdQuery,
	useArchiveDocumentByIdMutation,
	usePatchDocumentByIdMutation,
	useDocumentBulkArchiveMutation,
	useDocumentBulkUnarchiveMutation,
	useArchiveDocumentDeleteFromDatabaseByIdMutation,
	useArchiveDocumentBulkDeleteFromDatabaseMutation,
	useGetDocumentFileQuery,
	useLazyGetDocumentFileQuery,
	useArchiveDocumentDeleteByIdMutation,
	useArchiveDocumentBulkDeleteMutation,
	useGetDriverQuery,
	useLazyGetDriverQuery,
	useCreateDriverMutation,
	useUpdateDriverMutation,
	useGetDriverByIdQuery,
	useLazyGetDriverByIdQuery,
	useArchiveDriverByIdMutation,
	usePatchDriverByIdMutation,
	useGetDriverCodeByIdQuery,
	useLazyGetDriverCodeByIdQuery,
	useDriverBulkArchiveMutation,
	useDriverBulkUnarchiveMutation,
	useArchiveDriverDeleteByIdMutation,
	useArchiveDriverBulkDeleteMutation,
	useArchiveDriverDeleteFromDatabaseByIdMutation,
	useArchiveDriverBulkDeleteFromDatabaseMutation,
	useGetDumpLineItemQuery,
	useLazyGetDumpLineItemQuery,
	useCreateDumpLineItemMutation,
	useUpdateDumpLineItemMutation,
	useGetDumpLineItemByIdQuery,
	useLazyGetDumpLineItemByIdQuery,
	useArchiveDumpLineItemByIdMutation,
	usePatchDumpLineItemByIdMutation,
	useDumpLineItemBulkArchiveMutation,
	useDumpLineItemBulkUnarchiveMutation,
	useArchiveDumpLineItemDeleteByIdMutation,
	useArchiveDumpLineItemBulkDeleteMutation,
	useArchiveDumpLineItemDeleteFromDatabaseByIdMutation,
	useArchiveDumpLineItemBulkDeleteFromDatabaseMutation,
	useGetDumpRateQuery,
	useLazyGetDumpRateQuery,
	useCreateDumpRateMutation,
	useUpdateDumpRateMutation,
	useGetDumpRateByIdQuery,
	useLazyGetDumpRateByIdQuery,
	useArchiveDumpRateByIdMutation,
	usePatchDumpRateByIdMutation,
	useDumpRateBulkArchiveMutation,
	useDumpRateBulkUnarchiveMutation,
	useArchiveDumpRateDeleteByIdMutation,
	useArchiveDumpRateBulkDeleteMutation,
	useArchiveDumpRateDeleteFromDatabaseByIdMutation,
	useArchiveDumpRateBulkDeleteFromDatabaseMutation,
	useGetEquipmentQuery,
	useLazyGetEquipmentQuery,
	useCreateEquipmentMutation,
	useUpdateEquipmentMutation,
	useGetEquipmentByIdQuery,
	useLazyGetEquipmentByIdQuery,
	useArchiveEquipmentByIdMutation,
	usePatchEquipmentByIdMutation,
	useGetEquipmentNumberByIdQuery,
	useLazyGetEquipmentNumberByIdQuery,
	useEquipmentBulkArchiveMutation,
	useEquipmentBulkUnarchiveMutation,
	useArchiveEquipmentDeleteByIdMutation,
	useArchiveEquipmentBulkDeleteMutation,
	useArchiveEquipmentDeleteFromDatabaseByIdMutation,
	useArchiveEquipmentBulkDeleteFromDatabaseMutation,
	useGetEquipmentTypeQuery,
	useLazyGetEquipmentTypeQuery,
	useCreateEquipmentTypeMutation,
	useUpdateEquipmentTypeMutation,
	useGetEquipmentTypeByIdQuery,
	useLazyGetEquipmentTypeByIdQuery,
	useArchiveEquipmentTypeByIdMutation,
	usePatchEquipmentTypeByIdMutation,
	useEquipmentTypeBulkArchiveMutation,
	useEquipmentTypeBulkUnarchiveMutation,
	useArchiveEquipmentTypeDeleteByIdMutation,
	useArchiveEquipmentTypeBulkDeleteMutation,
	useArchiveEquipmentTypeDeleteFromDatabaseByIdMutation,
	useArchiveEquipmentTypeBulkDeleteFromDatabaseMutation,
	useGetHourlyLineItemQuery,
	useLazyGetHourlyLineItemQuery,
	useCreateHourlyLineItemMutation,
	useUpdateHourlyLineItemMutation,
	useGetHourlyLineItemByIdQuery,
	useLazyGetHourlyLineItemByIdQuery,
	useArchiveHourlyLineItemByIdMutation,
	usePatchHourlyLineItemByIdMutation,
	useHourlyLineItemBulkArchiveMutation,
	useHourlyLineItemBulkUnarchiveMutation,
	useArchiveHourlyLineItemDeleteByIdMutation,
	useArchiveHourlyLineItemBulkDeleteMutation,
	useArchiveHourlyLineItemDeleteFromDatabaseByIdMutation,
	useArchiveHourlyLineItemBulkDeleteFromDatabaseMutation,
	useGetIncomeCodeQuery,
	useLazyGetIncomeCodeQuery,
	useCreateIncomeCodeMutation,
	useUpdateIncomeCodeMutation,
	useGetIncomeCodeByIdQuery,
	useLazyGetIncomeCodeByIdQuery,
	useArchiveIncomeCodeByIdMutation,
	usePatchIncomeCodeByIdMutation,
	useIncomeCodeBulkArchiveMutation,
	useIncomeCodeBulkUnarchiveMutation,
	useArchiveIncomeCodeDeleteByIdMutation,
	useArchiveIncomeCodeBulkDeleteMutation,
	useArchiveIncomeCodeDeleteFromDatabaseByIdMutation,
	useArchiveIncomeCodeBulkDeleteFromDatabaseMutation,
	useGetInvoiceQuery,
	useLazyGetInvoiceQuery,
	useGetInvoiceByIdQuery,
	useLazyGetInvoiceByIdQuery,
	useGetJobQuery,
	useLazyGetJobQuery,
	useCreateJobMutation,
	useUpdateJobMutation,
	useGetJobByIdQuery,
	useLazyGetJobByIdQuery,
	useArchiveJobByIdMutation,
	usePatchJobByIdMutation,
	useCreateJobUpdateCurrentQuoteMutation,
	useJobBulkArchiveMutation,
	useJobBulkUnarchiveMutation,
	useArchiveJobDeleteByIdMutation,
	useArchiveJobBulkDeleteMutation,
	useArchiveJobDeleteFromDatabaseByIdMutation,
	useArchiveJobBulkDeleteFromDatabaseMutation,
	useGetJobJobNoteQuery,
	useLazyGetJobJobNoteQuery,
	useCreateJobJobNoteMutation,
	useUpdateJobJobNoteMutation,
	useGetJobJobNoteByIdQuery,
	useLazyGetJobJobNoteByIdQuery,
	useArchiveJobJobNoteByIdMutation,
	usePatchJobJobNoteByIdMutation,
	useJobJobNoteBulkArchiveMutation,
	useJobJobNoteBulkUnarchiveMutation,
	useArchiveJobJobNoteDeleteByIdMutation,
	useArchiveJobJobNoteBulkDeleteMutation,
	useArchiveJobJobNoteDeleteFromDatabaseByIdMutation,
	useArchiveJobJobNoteBulkDeleteFromDatabaseMutation,
	useGetLineItemQuery,
	useLazyGetLineItemQuery,
	useCreateLineItemMutation,
	useUpdateLineItemMutation,
	useGetLineItemByIdQuery,
	useLazyGetLineItemByIdQuery,
	useArchiveLineItemByIdMutation,
	usePatchLineItemByIdMutation,
	useGetLineItemQuoteSummaryLineItemsQuery,
	useLazyGetLineItemQuoteSummaryLineItemsQuery,
	useGetLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItemsQuery,
	useLazyGetLineItemDoesHaulingLineItemHaveCorrespondingMaterialLineItemsQuery,
	useLineItemBulkArchiveMutation,
	useLineItemBulkUnarchiveMutation,
	useArchiveLineItemDeleteByIdMutation,
	useArchiveLineItemBulkDeleteMutation,
	useArchiveLineItemDeleteFromDatabaseByIdMutation,
	useArchiveLineItemBulkDeleteFromDatabaseMutation,
	useGetMaterialQuery,
	useLazyGetMaterialQuery,
	useCreateMaterialMutation,
	useUpdateMaterialMutation,
	useGetMaterialByIdQuery,
	useLazyGetMaterialByIdQuery,
	useArchiveMaterialByIdMutation,
	usePatchMaterialByIdMutation,
	useMaterialBulkArchiveMutation,
	useMaterialBulkUnarchiveMutation,
	useArchiveMaterialDeleteByIdMutation,
	useArchiveMaterialBulkDeleteMutation,
	useArchiveMaterialDeleteFromDatabaseByIdMutation,
	useArchiveMaterialBulkDeleteFromDatabaseMutation,
	useGetMaterialLineItemQuery,
	useLazyGetMaterialLineItemQuery,
	useCreateMaterialLineItemMutation,
	useUpdateMaterialLineItemMutation,
	useGetMaterialLineItemByIdQuery,
	useLazyGetMaterialLineItemByIdQuery,
	useArchiveMaterialLineItemByIdMutation,
	usePatchMaterialLineItemByIdMutation,
	useMaterialLineItemBulkArchiveMutation,
	useMaterialLineItemBulkUnarchiveMutation,
	useArchiveMaterialLineItemDeleteByIdMutation,
	useArchiveMaterialLineItemBulkDeleteMutation,
	useArchiveMaterialLineItemDeleteFromDatabaseByIdMutation,
	useArchiveMaterialLineItemBulkDeleteFromDatabaseMutation,
	useGetMaterialPriceQuery,
	useLazyGetMaterialPriceQuery,
	useCreateMaterialPriceMutation,
	useUpdateMaterialPriceMutation,
	useGetMaterialPriceByIdQuery,
	useLazyGetMaterialPriceByIdQuery,
	useArchiveMaterialPriceByIdMutation,
	usePatchMaterialPriceByIdMutation,
	useMaterialPriceBulkArchiveMutation,
	useMaterialPriceBulkUnarchiveMutation,
	useArchiveMaterialPriceDeleteByIdMutation,
	useArchiveMaterialPriceBulkDeleteMutation,
	useArchiveMaterialPriceDeleteFromDatabaseByIdMutation,
	useArchiveMaterialPriceBulkDeleteFromDatabaseMutation,
	useGetMiscLineItemQuery,
	useLazyGetMiscLineItemQuery,
	useCreateMiscLineItemMutation,
	useUpdateMiscLineItemMutation,
	useGetMiscLineItemByIdQuery,
	useLazyGetMiscLineItemByIdQuery,
	useArchiveMiscLineItemByIdMutation,
	usePatchMiscLineItemByIdMutation,
	useMiscLineItemBulkArchiveMutation,
	useMiscLineItemBulkUnarchiveMutation,
	useArchiveMiscLineItemDeleteByIdMutation,
	useArchiveMiscLineItemBulkDeleteMutation,
	useArchiveMiscLineItemDeleteFromDatabaseByIdMutation,
	useArchiveMiscLineItemBulkDeleteFromDatabaseMutation,
	useGetMiscSettingsQuery,
	useLazyGetMiscSettingsQuery,
	useCreateMiscSettingsMutation,
	useUpdateMiscSettingsMutation,
	useGetMiscSettingsByIdQuery,
	useLazyGetMiscSettingsByIdQuery,
	useArchiveMiscSettingsByIdMutation,
	usePatchMiscSettingsByIdMutation,
	useMiscSettingsBulkArchiveMutation,
	useMiscSettingsBulkUnarchiveMutation,
	useArchiveMiscSettingsDeleteByIdMutation,
	useArchiveMiscSettingsBulkDeleteMutation,
	useArchiveMiscSettingsDeleteFromDatabaseByIdMutation,
	useArchiveMiscSettingsBulkDeleteFromDatabaseMutation,
	useGetOrderQuery,
	useLazyGetOrderQuery,
	useCreateOrderMutation,
	useUpdateOrderMutation,
	useGetOrderByIdQuery,
	useLazyGetOrderByIdQuery,
	useArchiveOrderByIdMutation,
	usePatchOrderByIdMutation,
	useGetOrderOrderReviewQuery,
	useLazyGetOrderOrderReviewQuery,
	useCreateOrderQuickCreateByIdMutation,
	usePatchOrderCancelMutation,
	usePatchOrderRestoreByIdMutation,
	useOrderBulkArchiveMutation,
	useOrderBulkUnarchiveMutation,
	useArchiveOrderDeleteByIdMutation,
	useArchiveOrderBulkDeleteMutation,
	useArchiveOrderDeleteFromDatabaseByIdMutation,
	useArchiveOrderBulkDeleteFromDatabaseMutation,
	useArchiveOrderLineItemByIdMutation,
	useGetOrderLineItemByIdQuery,
	useLazyGetOrderLineItemByIdQuery,
	usePatchOrderLineItemByIdMutation,
	useOrderLineItemBulkArchiveMutation,
	useArchiveOrderLineItemBulkDeleteMutation,
	useArchiveOrderLineItemBulkDeleteFromDatabaseMutation,
	useOrderLineItemBulkUnarchiveMutation,
	usePatchOrderLineItemCancelMutation,
	usePatchOrderLineItemRestoreByIdMutation,
	useCreateOrderLineItemMutation,
	useUpdateOrderLineItemMutation,
	useArchiveOrderLineItemDeleteByIdMutation,
	useArchiveOrderLineItemDeleteFromDatabaseByIdMutation,
	useGetOrderReviewQuery,
	useLazyGetOrderReviewQuery,
	useCreateOrderReviewMutation,
	useUpdateOrderReviewMutation,
	useGetOrderReviewByIdQuery,
	useLazyGetOrderReviewByIdQuery,
	useArchiveOrderReviewByIdMutation,
	usePatchOrderReviewByIdMutation,
	useGetOrderReviewCurrentMonthOrdersQuery,
	useLazyGetOrderReviewCurrentMonthOrdersQuery,
	useCreateOrderReviewPdfMutation,
	useCreateOrderReviewPdfsMutation,
	useOrderReviewBulkArchiveMutation,
	useOrderReviewBulkUnarchiveMutation,
	useArchiveOrderReviewDeleteByIdMutation,
	useArchiveOrderReviewBulkDeleteMutation,
	useArchiveOrderReviewDeleteFromDatabaseByIdMutation,
	useArchiveOrderReviewBulkDeleteFromDatabaseMutation,
	useGetPriceListSiteByIdQuery,
	useLazyGetPriceListSiteByIdQuery,
	useCreatePriceListMutation,
	useGetQuickBooksAuthorizeQuery,
	useLazyGetQuickBooksAuthorizeQuery,
	useGetQuickBooksCallbackQuery,
	useLazyGetQuickBooksCallbackQuery,
	useGetQuoteQuery,
	useLazyGetQuoteQuery,
	useCreateQuoteMutation,
	useUpdateQuoteMutation,
	useGetQuoteAffectedQuery,
	useLazyGetQuoteAffectedQuery,
	useGetQuoteByIdQuery,
	useLazyGetQuoteByIdQuery,
	useArchiveQuoteByIdMutation,
	usePatchQuoteByIdMutation,
	useCreateQuotePdfMutation,
	useUpdateQuoteAcceptMutation,
	useCreateQuoteCloneMutation,
	useQuoteBulkArchiveMutation,
	useQuoteBulkUnarchiveMutation,
	useArchiveQuoteDeleteByIdMutation,
	useArchiveQuoteBulkDeleteMutation,
	useArchiveQuoteDeleteFromDatabaseByIdMutation,
	useArchiveQuoteBulkDeleteFromDatabaseMutation,
	useGetRegionQuery,
	useLazyGetRegionQuery,
	useCreateRegionMutation,
	useUpdateRegionMutation,
	useGetRegionByIdQuery,
	useLazyGetRegionByIdQuery,
	useArchiveRegionByIdMutation,
	usePatchRegionByIdMutation,
	useRegionBulkArchiveMutation,
	useRegionBulkUnarchiveMutation,
	useArchiveRegionDeleteByIdMutation,
	useArchiveRegionBulkDeleteMutation,
	useArchiveRegionDeleteFromDatabaseByIdMutation,
	useArchiveRegionBulkDeleteFromDatabaseMutation,
	useGetSiteQuery,
	useLazyGetSiteQuery,
	useCreateSiteMutation,
	useUpdateSiteMutation,
	useGetSiteByIdQuery,
	useLazyGetSiteByIdQuery,
	useArchiveSiteByIdMutation,
	usePatchSiteByIdMutation,
	useSiteBulkArchiveMutation,
	useSiteBulkUnarchiveMutation,
	useArchiveSiteDeleteByIdMutation,
	useArchiveSiteBulkDeleteMutation,
	useArchiveSiteDeleteFromDatabaseByIdMutation,
	useArchiveSiteBulkDeleteFromDatabaseMutation,
	useGetSiteHaulingLineItemQuery,
	useLazyGetSiteHaulingLineItemQuery,
	useCreateSiteHaulingLineItemMutation,
	useUpdateSiteHaulingLineItemMutation,
	useGetSiteHaulingLineItemByIdQuery,
	useLazyGetSiteHaulingLineItemByIdQuery,
	useArchiveSiteHaulingLineItemByIdMutation,
	usePatchSiteHaulingLineItemByIdMutation,
	useSiteHaulingLineItemBulkArchiveMutation,
	useSiteHaulingLineItemBulkUnarchiveMutation,
	useArchiveSiteHaulingLineItemDeleteByIdMutation,
	useArchiveSiteHaulingLineItemBulkDeleteMutation,
	useArchiveSiteHaulingLineItemDeleteFromDatabaseByIdMutation,
	useArchiveSiteHaulingLineItemBulkDeleteFromDatabaseMutation,
	useGetSurchargeCodeQuery,
	useLazyGetSurchargeCodeQuery,
	useCreateSurchargeCodeMutation,
	useUpdateSurchargeCodeMutation,
	useGetSurchargeCodeByIdQuery,
	useLazyGetSurchargeCodeByIdQuery,
	useArchiveSurchargeCodeByIdMutation,
	usePatchSurchargeCodeByIdMutation,
	useSurchargeCodeBulkArchiveMutation,
	useSurchargeCodeBulkUnarchiveMutation,
	useArchiveSurchargeCodeDeleteByIdMutation,
	useArchiveSurchargeCodeBulkDeleteMutation,
	useArchiveSurchargeCodeDeleteFromDatabaseByIdMutation,
	useArchiveSurchargeCodeBulkDeleteFromDatabaseMutation,
	useGetTermsCodeQuery,
	useLazyGetTermsCodeQuery,
	useCreateTermsCodeMutation,
	useUpdateTermsCodeMutation,
	useGetTermsCodeByIdQuery,
	useLazyGetTermsCodeByIdQuery,
	useArchiveTermsCodeByIdMutation,
	usePatchTermsCodeByIdMutation,
	useTermsCodeBulkArchiveMutation,
	useTermsCodeBulkUnarchiveMutation,
	useArchiveTermsCodeDeleteByIdMutation,
	useArchiveTermsCodeBulkDeleteMutation,
	useArchiveTermsCodeDeleteFromDatabaseByIdMutation,
	useArchiveTermsCodeBulkDeleteFromDatabaseMutation,
	useGetTrashQuery,
	useLazyGetTrashQuery,
	useUpdateTrashMutation,
	useCreateTrashMutation,
	useGetTrashByIdQuery,
	useLazyGetTrashByIdQuery,
	usePatchTrashByIdMutation,
	useArchiveTrashByIdMutation,
	useArchiveTrashBulkPermanentDeleteMutation,
	usePatchTrashBulkRestoreMutation,
	useTrashBulkArchiveMutation,
	useTrashBulkUnarchiveMutation,
	useArchiveTrashDeleteByIdMutation,
	useArchiveTrashBulkDeleteMutation,
	useArchiveTrashDeleteFromDatabaseByIdMutation,
	useArchiveTrashBulkDeleteFromDatabaseMutation,
	useGetUserQuery,
	useLazyGetUserQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useGetUserByIdQuery,
	useLazyGetUserByIdQuery,
	useArchiveUserByIdMutation,
	usePatchUserByIdMutation,
	useUserBulkArchiveMutation,
	useUserBulkUnarchiveMutation,
	useArchiveUserDeleteFromDatabaseByIdMutation,
	useArchiveUserBulkDeleteFromDatabaseMutation,
	useGetUserMeQuery,
	useLazyGetUserMeQuery,
	useGetUserAuth0UserIdByIdQuery,
	useLazyGetUserAuth0UserIdByIdQuery,
	useGetUserPermissionsQuery,
	useLazyGetUserPermissionsQuery,
	useGetUserRolesQuery,
	useLazyGetUserRolesQuery,
	useArchiveUserDeleteByIdMutation,
	useArchiveUserBulkDeleteMutation
} = generatedApi;